.products-page {
    padding-top: 56px;

    .main-products-content {
        margin-left: 100px;

        .category-header {
            height: 400px;
            background-size: cover;
            background-position: center center;
        }
    }
    
    .product-filter-category-list {
        position: relative;
        margin-bottom: 30px;
        width: 100%;
        max-width: 300px;

        .mobile-category-toggle {
            position: fixed;
            bottom: 20px;
            right: 0px;
            z-index: 100;
            display: none;
            transform: translateX(100%);
            transition: $transitionFast;
        }

        .category-list  {
            padding: 0 50px;
        }

        .category-container {
            padding: 15px 20px;
            opacity: .6;
            transition: $transition;
            cursor: pointer;
            // border-top: 1px solid $medGray;

            .category-title {
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .sub-category-options {
                padding: 0 20px;
                transition: $transitionFast;
                max-height: 0px;
                overflow: hidden;

                .sub-option {
                    padding-top: 10px;
                    
                    span {
                        &:hover {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        padding-top: 20px;

        .page-container {
            padding-top: 0px;
        }

        .main-products-content {
            margin-left: 0;
            
            .category-header {
                height: 300px;
                background-size: cover;
                background-position: center center;
            }
        }
        
        .product-filter-category-list {
            max-width: 100%;
            
            .mobile-category-toggle {
                display: block;
                transform: translateX(-20px);
            }

            .category-nav-list {
                position: fixed;
                width: 300px;
                height: 100%;
                left: 0;
                top: 0;
                bottom: 0;
                z-index: 99999;
                background: #eee;
                padding: 30px;
                overflow: auto;
                transition: $transitionFast;
                transform: translateX(-100%);

                &.in {
                    transform: translateX(0%);
                }
            }
        }
    }

    @media (max-width: 767px) {

        .main-products-content {

            .category-header {
                height: 200px;
            }
        }
    }
}