.product-review-container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 15px 60px;
    .btn {
        max-width: 200px;
        margin: auto;
    }
    .user-image-container {
        .user-image {
            width: 75px;
            height: 75px;
            @include commonBackground();
            border-radius: 100%;
        }
    }
    .product-review-content {
        .product-review-form {
            .rating-buttons {
                padding-bottom: 20px;
            }
            .form-group {
                margin-bottom: 30px;
            }
        }
    }
    h1 {
        font-size: 1.5rem;
        padding-top: 20px;
        margin: 0;
        font-family: $montRegular;
        grid-column: 1/3;
        color: $green;
    }
    .product-review-list {
        grid-column: 1/3;
        li {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 15px 60px;
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: solid 1px $lightGray;
            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
            .review-header {
                display: grid;
                grid-template-columns: 1fr 1fr;
                .name {
                    font-family: $montLightItalic;
                }
                .date {
                    font-size: 0.625rem;
                    color: $lightGray;
                    text-align: right;
                }
            }
            .review-description {
                font-size: 0.8125rem;
                padding-top: 15px;
            }
        }
    }
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        h1, .product-review-list {
            grid-column: unset;
        }
        .product-review-list {
            li {
                grid-template-columns: 1fr;
            }
        }
    }
}