// Variables will be place here

$dirtyWhite: #c9c9ca;
$blue: #212d69;
$lightBlue: #2a3676;
$darkBlue: #22314e;
$latoRegular: 'Lato-Regular';
$latoBold: 'Lato-Bold';
$merriItalic: 'Merriweather-italic';
$montBold: 'librefranklin-bold';
$montSemiBold: 'librefranklin-semibold';
$montLight: 'librefranklin-light';
$montExtraBold: 'librefranklin-extrabold';
$montMedium: 'librefranklin-medium';
$montRegular: 'librefranklin-regular';
$montLightItalic: 'librefranklin-lightitalic';
$montSemiBoldItalic: 'librefranklin-semibolditalic';
$bebasBold: 'BebasNeue-Bold';
$placeholderBg: #ced4da;

$green: #477625;
$black: #282828;
$gray: #747474;
$medGray: #c2c2c2;
$lightGray: #f3f3f3;
$orange: #F47920;
$red: #ff3333;
$transition: .6s;
$transitionFast: .3s;
$navTopSpacing: 120px;
$navTopMobileSpacing: 140px;
$defaultGradient: linear-gradient(90deg, #E85523, #F47A21, #F19F19);

$oversized: 60px;
$h1: 40px;
$h2: 30px;
$small: 12px;
$medium: 14px;
$large: 18px;
$xlarge: 20px;
$xxlarge: 25px;

//bootstrap variables
$theme-colors: (
  "primary": $green,
  "secondary": $orange
);