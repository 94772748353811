.modal-login-button {
    cursor: pointer;

    i {
        transition: $transitionFast;
    }

    &:hover {

        i {
            transform: scale(1.3);
        }
    }
}