.login {
    max-width: 500px;
    width: 100%;
    margin: auto;

    .icon-badge {
        text-align: center;

        i {
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 5px solid $orange;
            background: $orange;
            color: #fff;
            transform: translateY(-50%);
            border-radius: 100%;
            font-size: 40px;
            margin:auto;
            box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.3);
        }
    }

    .login-header {
        font-weight: bold;
    }

    .login-form {
        padding: 20px;

        .form-group {
            margin-bottom: 20px;
        }
    }

    .submit-btn {
        margin-top: 20px;
    }

    .registration-link {
        margin-top: 20px;
    }

    @media (max-width: 767px) {

        .icon-badge {
    
            i {
                width: 60px;
                height: 60px;
                font-size: 30px;
            }
        }
    }

    @media (max-width: 450px) {

        .login-options-container {
            display: block;
    
            .forgot-password {
                text-align: center !important;
                border: 1px solid $medGray;
                padding: 5px;
                margin-top: 20px;
            }
        }
    }
}