.main-navbar {
    .nav-fixed {
        // position:fixed;
        width: 100%;
        z-index: 99999;
    }

    .nav-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        cursor: pointer;
        z-index: 1;
        &.open {
            display: block;
        }
    }

    .mobile-search-container {
        display: none;
        padding: 10px;
        background: $orange;

        > .search-container {
            background: #fff;
        }
    }

    .nav-disclaimer {
        background: $defaultGradient;
        color: white;
        padding: 30px;
        position: relative;

        img {
            position: absolute;
            width: 400px;
            height: 100%;
            object-fit: contain;
            object-position: right bottom;
            right: 82px;
            top: 0;
        }
    }

    .navbar {
        background-color: #fff;
        padding: 10px 0 0;
        transition: $transitionFast;

        .user-indicator {
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(20%) translateY(-20%);
            background: #fff;
            color: $red !important;
            pointer-events: none;
        }

        .hamburger {
            width: 22px;
            height: 15px;
            position: relative;
            transform: rotate(0deg);
            transition: .5s ease-in-out;
            cursor: pointer;
            z-index: 1;
            margin-right: 30px;
            // margin-left: auto;
            // display: none;

            span {
                display: block;
                background-color: $orange;
                border-radius: 50px;
                position: absolute;
                height: 3px;
                width: 100%;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: .25s ease-in-out;

                &:nth-child(1) {
                    top: 0px;
                }
                &:nth-child(2), &:nth-child(3) {
                    top: 7px;
                }
                &:nth-child(3) {
                    top: 14px;
                }
            }
        }

        .nav-stickout {
            position: fixed;
            left: 0;
            top: 0;
            background: #fff;
            width: 100%;
            padding: 10px 20px;
            z-index: 10;
            transform: translateY(-100%);
            transition: $transitionFast;
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
            img {
                max-width: 40px;
                width: 100%;
            }
        }

        .container {
            max-width: 90%;
            display: grid;
            grid-template-columns: auto auto auto 1fr auto;
            grid-gap: 40px;
            width: 100%;
            padding: 10px 0;

            > * {
                display: flex;
                align-items: center;
                align-self: center;
                // height: 40px;
            }

            .search-container {
                margin-right: 60px;
                width: 100%;
            }

            .btn {
                font-family: $montLight;
                font-size: 12px;
            }

            .navbar-header {

                .navbar-brand {
                    margin: 0;
                    // height: 100%;
                    padding: 0;

                    img {
                        max-width: 80px;
                        // height: 100%;
                        object-fit: contain;
                    }
                }
            }

            .nav--search-btn {
                display: none;
            }
        }
    }

    .nav-links {
        background-color: #F47A21;
        padding: 0 50px;
        color: #fff;

        .container {
            max-width: 100%;
            padding: 0;

            > * {
                flex: 1;
                padding: 14px 30px;
                text-align: center;
                position: relative;
                overflow: hidden;

                a {

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        width: 70%;
                        height: 3px;
                        background: #fff;
                        transform: translateY(100%);
                        transition: $transitionFast;
                    }

                    &.active,
                    &:hover {

                        &::after {
                            transform: translateY(0);
                        }
                    }
                }
            }
        }
    }

    .nav-sidebar-container {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 999999;
        transition: 0.3s;
        transition-delay: 0.3s;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        color: $black;
        transform: translateX(-100%);
        opacity: 0;
        color: $black;

        .link-container {
            background: #fff;  
            min-width: 300px;
            max-width: 400px;
            max-height: 100%;
            width: 100%;
            padding: 50px 0;  
            box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.2);
            transition: 0.3s;
            transform: translateX(-100%);
        }

        .action-container {
            padding: 0 50px;

            img {
                max-width: 25px;
            }

            i {
                font-size: 18px;
            }
        }

        .menu-list {
            overflow: auto;
            position: relative;

            ul {
                li {
                    font-size: 14px;
                    font-family: $montMedium;
                    position: relative;
    
                    a {
                        position: relative;
                        display: block;
                        z-index: 2;
                        padding: 12px 50px;
                    }
                }
            }

            >ul {
                li {

                    a {
                        &:after {
                            content: attr(text);
                            bottom: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            background-color: $orange;
                            position: absolute;
                            transform: scaleY(0);
                            transition: $transitionFast;
                            color: #fff;
                            padding: 12px 50px;
                        }
        
                        &.active,
                        &:hover {
    
                            &:after {
                                transform: scaleY(1);
                            }
                        }
                    }
                }
            }

            .mobile-linkouts {
                // background: $defaultGradient;
                border-top: 1px solid #ccc;
            }
        }

        .quick-links {
            border-top: 1px solid #DFDFDF;

            a {
                display: block;
                color: #9D9D9D;
                padding: 12px 50px;
                transition: $transitionFast;

                &:hover {
                    color: $black;
                }
            }
        }

        &.open {
            transform: translateX(0);
            opacity: 1;
            transition: opacity 0.3s;
            transition-delay: 0s;

            .link-container {
                transition-delay: 0.3s;
                transform: translateX(0);
            }
        }
    }

    &.moved {

        .navbar {

            .nav-stickout {
                transform: translateY(0);
            }
        }
    }

    @media (max-width: 1450px) {

        .nav-disclaimer {
    
            img {
                right: 0;
            }
        }

        .nav-links {
            padding: 0;

            .link {
                padding: 10px;
            }
        }
    }

    @media (max-width: 991px) {
        .nav-disclaimer {
    
            img {
                width: 250px;
            }
        }

        .navbar {
            .container {
                grid-template-columns: auto auto 1fr auto auto;
                grid-auto-flow: dense;
                grid-gap: 20px;

                .search-container {
                    margin-right: 30px;
                }
                
                .hamburger {
                    display: block;
                }

                .navbar-header {

                    .navbar-brand {

                        img {
                            max-width: 50px;
                        }
                    }
                }
            }
        }

        .nav-links {
            display: none;
        }
    }

    @media (max-width: 767px) {
        .navbar {
            .container {
                grid-template-columns: auto auto 1fr auto auto;
                grid-gap: 20px;
                
                .navbar-header {

                    .navbar-brand {

                        img {
                            max-width: 40px;

                        }
                    }
                }

                .modal-registration-button,
                .checkout-btn,
                .line-separator,
                .modal-login-btn-container,
                .nav--checkout-btn {
                    display: none;
                }

                .search-container {
                    padding: 5px 10px;
                }
            }
        }
    }

    @media (max-width: 500px) {
        .mobile-search-container {
            display: none;
        }

        .nav-disclaimer {
    
            img {
                width: 200px;
            }
        }

        .navbar {
            .container {
                grid-template-columns: auto 1fr auto auto;
                grid-gap: 10px;

                .search-container {
                    display: none;
                }

                .nav--search-btn {
                    display: block;
                }
            }
        }
    }

    @media (max-width: 420px) {
        .navbar {
            .container {
                .modal-login-button {

                    i {
                        font-size: 20px;
                        margin: 0;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }
    }
}