.slider {
    min-height: 300px;
    background: #111;
    position: relative;
    // overflow: hidden;

    .active-slide,
    .last-active-slide {
        @include commonBackground();
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        animation-delay: 0s;

        > div {
            max-width: 50%;
        }
    }

    .last-active-slide {
        opacity: 1;
        transform: scale(0);
        transition: opacity 0.3s;
    }

    .slider-control {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        border: none;
        background: #fff;
        border-radius: 100%;
        color: $black;
        height: 70px;
        width: 70px; 
        transition: 0.3s;

        &.prev-btn {
            left: -35px;
            box-shadow: 0px 10px 25px #04060517;
        }

        &.next-btn {
            right: -35px;
            box-shadow: 0px 10px 25px #04060517;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    .slider-thumbnails {
        position: absolute;
        width: 100%;
        bottom: 20px;
        left: 0;

        .thumb {
            width: 50px;
            height: 5px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.5);
            margin: 0 10px;
            position: relative;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: #fff;
                transform: translateX(100%);
            }

            &.active {
                pointer-events: none;
                
                &::after {
                    transform: translateX(0);
                }
            }
        }

        &.next {
            pointer-events: none;

            .thumb {

                &.active-out {

                    &::after {
                        transform: translateX(0);
                        animation-name: thumbOutNext;
                        animation-duration: 0.5s;
                        animation-fill-mode: forwards;
                    }
                }

                &.active {

                    &::after {
                        transform: translateX(-100%);
                        animation-name: thumbInNext;
                        animation-duration: 0.5s;
                        animation-fill-mode: forwards;
                    }
                }
            }
        }

        &.prev {
            pointer-events: none;

            .thumb {

                &.active-out {

                    &::after {
                        transform: translateX(0);
                        animation-name: thumbOutPrev;
                        animation-duration: 0.5s;
                        animation-fill-mode: forwards;
                    }
                }

                &.active {

                    &::after {
                        transform: translateX(-100%);
                        animation-name: thumbInPrev;
                        animation-duration: 0.5s;
                        animation-fill-mode: forwards;
                    }
                }
            }
        }
    }

    &.in-transition {

        .last-active-slide {
            transform: scale(1);
            opacity: 0;
        }
    }

    @media (max-width: 991px) {
        
        .active-slide,
        .last-active-slide {
            padding: 30px;

            > div {
                max-width: 70%;
            }
        }

        .slider-control {
            transform: scale(0.7);
    
            &:hover {
                transform: scale(0.8);
            }
        }
    }

    @media (max-width: 767px) {
        margin-left: -16px;
        margin-right: -16px;

        .active-slide,
        .last-active-slide {

            > div {
                max-width: 100%;
            }
        }

        .slider-control {
            transform: scale(0.5);
    
            &:hover {
                transform: scale(0.6);
            }
        }
    }

    @media (max-width: 450px) {

        .slider-control {
            transform: scale(0.5);
    
            &:hover {
                transform: scale(0.6);
            }

            &.prev-btn {
                left: -15px;
            }
    
            &.next-btn {
                right: -15px;
            }
        }
    }
}