
.home {

    .steps {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
        margin-top: 30px;
        padding: 0 50px;
        
        >div {
            padding: 10px;
        }

        > div:nth-child(2) {
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
        }
    }

    .partner-list {

        .partner-card {
            text-align: center;

            img {
                max-height: 80px;
                max-width: 100%;
                margin: auto;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    @media (max-width: 991px) {

        .featured-collection {

            .banner-js {

                .main-banner {
                    height: auto;
                }

                .banner-footer {
                    grid-template-columns: 1fr;

                    .next-preview {
                        justify-content: center;
                    }
                }

                .link-to-collection {
                    text-align: center !important;
                    margin-bottom: 20px;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .steps {
            grid-template-columns: 1fr;

            >div {
                border: 1px solid #ccc;
                border-radius: 5px;
                margin-top: 10px;
            }
        }
        
    }

    @media (max-width: 550px) {

        .featured-products {
            grid-template-columns: 1fr 1fr;

            .featured-product-card {
                height: 50vw;

                .flex-end {
                    
                    .btn {
                        font-size: 10px;
                    }
                 }
            }
        }
    }
}