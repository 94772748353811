.btn {
    border-radius: 25px;
    font-family: $montRegular;
	padding: 0.625rem 1.5rem;
    // font-size: 0.875rem;
    color: #fff;
    background: $gray;
    font-size: $medium;
    transition: $transitionFast;

	&.with-icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
    }
    
    // TYPE
    &-icon {
        width: auto;
        height: auto;
        background: transparent;
        padding: 0;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // BTN COLOR
    &.btn-primary {
        background: #284356 !important;
        border-color: #284356 !important;
    }

    &.btn-secondary {
        background: $orange;

        &-dark {
            background: #D9580A;

            &:hover {
                color: #fff;
            }
        }
    }

    &.btn-gray {
        background: $gray;
    }

    &.btn-lgray {
        background: $lightGray;
        color: #111;
    }

    &.btn-mgray {
        background: $medGray;
        color: #111;
    }

    &.btn-violet {
        background: #C9287D;

        &:hover {
            color: #fff;
        }
    }

    &.btn-clear {
        background: transparent;

        &.black {
            color: $black;
            border: 1px solid $black;
        }

        &.clear-black {
            color: $black;
        }

        &.secondary {
            color: $orange;
            border: 1px solid $orange;
        }

        &.white {
            color: white;
            border: 1px solid white;
        }

        &.link {
            color: #356077;
            border: 1px solid #356077;
        }
    }

    &.btn-black {
        background: $black;

        &:hover {
            color: #fff;
        }
    }

    &.btn-white {
        background: #fff;
        color: $orange;
    }

    &.btn-oblivion {
        background: #000;
        
        &:hover {
            color: #fff;
        }
    }

    &.btn-fb {
        background: #3b5998;
        color: #fff;
    }

    &.btn-twitter {
        background: #38A1F3;
        color: #fff;
    }

    &.btn-google {
        background: #ff3e30;
        color: #fff;
    }

    // BTN SIZES
    &.btn-sm {
        font-size: $small;
    }

    &.btn-lg {
        font-size: $large;
    }

    //BTN PAD SETTINGS
    &.btn-pad-large {
        padding: 10px 30px;
    }

    &.btn-pad-xlarge {
        padding: 20px 30px;
    }

    &.no-padding {
        padding: 0;
    }

    //--BTN SETTINGS
    &.btn-block {
        display: block;

        &-center {
            margin: auto;
        }
    }

    &.btn-sqr {
        border-radius: 0;
    }

    &.btn-bold {
        font-weight: bold;
    }
    
    .icon-container {
        padding-left: 20px;
    }

    &.btn-nostyles {
        padding: 0;
        background: none;
    }
    
    &:active:not(.no-transition) {
        transition: 0.1s;
        transform: translateY(-10%);
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.5) !important;
        background: inherit;
    }
}

.btn:focus:not(:active) {
    box-shadow: none !important;
}

.button-loader {
    border-radius: 25px;
    overflow: hidden;
    margin: auto;
    position: relative;
    transition: 0.5s;
    max-width: 500px;

    .loading-state,
    .sent-state {
        position: absolute;
        width: 43px;
        height: 43px;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        color: #Fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        transform: scale(0);
        border-radius: 100%;
    }

    .loading-state {
        background: $orange;

        i {
            animation-name: float;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }

    .sent-state {
        background: $green;
    }

    &.loading {
        max-width: 43px;

        .loading-state {
            transition: 0.5s;
            transform: scale(1);
        }
    }

    &.sent {
        max-width: 43px;

        .loading-state {
            transform: scale(1);
        }

        .sent-state {
            transition: 0.5s;
            transform: scale(1);
        }
    }
}

.underline-hover {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #fff;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: $transitionFast;
    }

    &.uh-black {
        &::after {
            background: $black;
        }
    }

    &:hover,
    &.active {

        &::after {
            transform: scaleX(1);
        }
    }
}

.scale-hover {
    transition: $transitionFast;

    &:hover {
        transform: scale(1.2);
    }
}

// MATERIAL STYLE OVERWRITES
[material-checkbox=primary] {
    color: $green !important;
}

@media (max-width: 767px) {
    .btn {
        font-size: $small;
        
        &.btn-lg {
            font-size: $medium;
        }

        &.btn-pad-large {
            padding: 5px 15px;
        }
    
        &.btn-pad-xlarge {
            padding: 10px 15px;
        }
    }
}