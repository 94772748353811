.footer {
    > .page-container {

        border-top: 1px solid #D7D7D7;
        padding: 100px 0;

        .reach-us-content {
            i {
                width: 20px;
            }
        }

        .subscribe-content {

            .subscribe-form {
                position: relative;
                max-width: 500px;
                margin: auto;
                .input-group {
                    position: relative;
                    overflow: hidden;

                    input {
                        padding: 12px 20px;
                        font-size: 12px;
                        border-radius: 100px;
                        height: auto;
                        border-color: #D1D1D1;
                        padding-right: 50px;
                    }
    
                    .send-btn {
                        position: absolute;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        padding: 0 20px;
                        right: 0;
                        top: 0;
                        background: none;
                        border: none;
                        box-shadow: none;
                        color: $orange;
                        transform: translateX(100%);
                        transition: 0.5s;
                        z-index: 10;

                        &.in {
                            transform: translateX(0);
                        }
                    }
                }

                .subscription-message {
                    position: absolute;
                    bottom: calc(100% + 20px);
                    right: 10px;
                    color: #fff;
                    font-size: 12px;
                    perspective: 500;

                    > div {
                        padding: 10px;
                        border-radius: 5px;
                        transition: 0.6s;
                        transform-origin: 50% 100%;
                        transform: rotateX(90deg);
                        opacity: 0;

                        .message {
                            position: relative;

                            &::after {
                                content: '';
                                position: absolute;
                                width: 0;
                                height: 0;
                                border-top: 10px solid #111;
                                border-left: 7px solid transparent;
                                border-right: 7px solid transparent;
                                margin: 0 auto;
                                top: calc(100% + 10px);
                                left: 0;
                                right: 0;
                            }
                        }
                    }
                    

                    &.success {

                        > div {
                            background: $green;

                            .message {
        
                                &::after {
                                    border-top: 10px solid $green;
                                }
                            }
                        }
                    }

                    &.error {

                        > div {
                            background: $red;

                            .message {
        
                                &::after {
                                    border-top: 10px solid $red;
                                }
                            }
                        }
                    }

                    &.in {

                        > div {
                            opacity: 1;
                            transform: rotateY(0deg);
                        }
                    }
                }
            }
            
        }

        .footer-link {
            transition: 0.3s;

            &:hover {
                color: $orange;
            }
        }
    }

    .copy-right {
        background: $orange;
        color: #fff;
        padding: 20px 50px;

        > .flex {
            max-width: 80%;
            margin: auto;
        }
    }

    @media (max-width: 991px) {
        font-size: 12px;

        .page-container {
            
            .reach-us-content {
                i {
                    display: none;
                }
            }

            > .flex {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 20px;
                
                > .flex-1 {
                    text-align: center;
                    justify-content: center;
                    margin-bottom: 20px;
                }
            }
        }
    }

    @media (max-width: 767px) {
        font-size: 12px;

        .page-container {
            max-width: 100%;

            > .flex {
                
                .quick-links {
                    display: grid;
                    grid-template-columns: 1fr;

                    a {
                        margin: 10px 0;
                    }
                }
            }
        }

        .copy-right {
    
            > .flex {
                display: block;
                text-align: center !important;

                .text-right {
                    text-align: center !important;
                    margin-top: 5px;
                }
            }
        }
    }
}
