.auto-suggest {
    &.search-container {
        display: flex;
        align-items: center;
        border-radius: 50px;
        border: solid 1px $orange;
        padding: 10px 20px;
        overflow: hidden;
        > i {
            color: $orange;
        }
        input {
            border: 0;
            height: 100%;
            width: calc(100% - 15px);
            font-size: 12px;

            &:focus {
                box-shadow: none;
            }
        }
    }
}