
.order-status-page{
    padding-top: $navTopSpacing;
    min-height: 500px;
    h1 {
        font-family: $montMedium;
    }
    @media (max-width: 991px) {
        padding-top: $navTopMobileSpacing;
    }
    .order-image-list {
        display: -webkit-box;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        max-width: 460px;
        margin: auto;
        padding-top: 15px;
        margin-bottom: 75px;
        li {
            max-width: 80px;
            height: 80px;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 80px;
            flex: 1 1 80px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            margin-right: 15px;
            margin-bottom: 15px;
        }
    }
}