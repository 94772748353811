.ingredients-selection {
    padding: 100px 0 0;
    display: flex;
    align-items: center;
    
    .slider-container {
        width: 100%;
        overflow: hidden;
        padding: 20px 0;

        .slick-list {
            overflow: visible;
        }

        .ingredient-option {
            width: 120px;
            margin: auto;
            border-radius: 100%;
            overflow: hidden;
            transition: $transitionFast;
            cursor: pointer;
            animation-name: growIn;
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
            transform: scale(0);

            img {
                width: 100%;
                height: 120px;
                object-fit: cover;
                object-position: center;
            }
    
            &:hover,
            &.active {
                box-shadow: 0 0 0 10px $green;
            }
        }
    }

    .slider-control {
        position: relative;
        z-index: 2;
        opacity: 0.3;
        font-size: 50px;
        margin: 0 50px;
        text-align: center;
        transition: $transitionFast;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
}

.ingredient-details {
    margin-top: 50px;

    .text-details {
        .ingredient-name {
            color: #fff;
            padding: 50px;
            position: relative;
            max-width: 500px;
            width: 100%;
            
            h1 {
                font-size: $h1;
            }

            .overlay-display {
                background-color: $green;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 130%;
                padding: 50px;

                .shop-link {
                    font-size: $small;
                }
            }
        }

        .ingredient-description {
            padding: 50px;
            width: 100%;
        }
    }

    .ingredient-bg {
        height: 300px;
        background-color: #ccc;
        @include commonBackground();
        background-attachment: fixed;
    }
}