.stores {
    #map {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .store-control {
        color: #a3a4a4;
        background: #eee;
        border: none;
        box-shadow: none;
        font-size: 20px;
        padding: 20px;
        font-weight: bold;
        transition: $transitionFast;

        &.active {
            color: #fff;
            background: $green;
        }

        &:not(.active):hover {
            opacity: 0.6;
        }
    }

    .stores-list {
        margin-top: 30px;
        grid-gap: 20px;
        
        .store-cell {
            padding: 10px 5px;
            font-size: $medium;
            
            .contact-details {
                opacity: 0.4;
                margin-top: 5px;
            }
        }
    }
}