.article-card {

    .img-container {
        background: #ccc;

        img {
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}