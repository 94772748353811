.related-products-slider {
    height: 600px;
    position: relative;

    .product-details {
        position: absolute;
        left: 30px;
        bottom: 30px;
        max-width: 60%;
        width: 100%;
    }

    @media (max-width: 991px) {
        height: 500px;

        .product-details {
            max-width: calc(100% - 60px);
        }
    }

    @media (max-width: 767px) {
        height: 300px;

        .product-details {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;
            background: none;
            margin-top: 100px;
            padding: 0;
            max-width: calc(100% - 10px);
            left: 5px;
            bottom: 15px;

            .details-container {
                background: #fff;
                padding: 10px;
            }

            .btn-container {
                text-align: center;
            }

            .list-control {
                margin-left: 0;
                justify-content: center;
                position: absolute;
                bottom: 0;
                left: 0;
                transform: translateY(200%);
                width: 100%;

                .btn {

                    &.margin-left-10 {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

.related-products-slider-container {
    @media (max-width: 767px) {
        margin-bottom: 100px !important;
    }
}