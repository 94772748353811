.template1 {
    @include commonBackground();
    position: relative;
    &:before {
        content: '';
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .3);
    }
    > .container {
        color: #fff;
        padding-top: 120px;
        padding-bottom: 150px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        position: relative;
        grid-gap: 20px;
        .title {
            font-family: $bebasBold;
            font-size: 2.5rem;
            line-height: 2rem;
            grid-column: 1/3;
        }
        .description {
            font-size: 0.875rem;
            max-width: 440px;
        }
        .link-container {
            align-self: center;
            .btn {
                font-size: 0.75rem;
                width: 162px;
            }
        }
    }
    @media (max-width: 768px) {
        > .container {
            grid-template-columns: 1fr;
            .title {
                grid-column: unset;
            }
        }
    }
}

.template2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    overflow: hidden;
    .background-wrapper {
        position: relative;
        overflow: hidden;
        .background {
            @include commonBackground();
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            transition: $transition;
        }
        &:hover {
            .background {
                transform: scale(1.1);
            }
        }
    }

    a {
        padding: 70px 60px;
        color: #fff;
        position: relative;
        z-index: 1;
        font-family: $montSemiBold;
        font-size: 1.125rem;
        line-height: 1.125rem;
        .margin-right {
            margin-right: 20px;
        }
    }
}