.product-view {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding-bottom: 30px;
    margin-bottom: 50px;

    .line-separator {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: -50%;
            width: 200%;
            height: 1px;
            background: $lightGray;
        }
    }

    .product-specifications {
        padding-left: 15px;
        
        li {
            list-style-type: disc;
        }
    }

    .related-product-list {
        grid-column: 1/3;
        border-top: 1px solid #eee;
        padding-top: 30px;
        margin-top: 60px;
        // border: 0;
    }

    .product-review-container {
        grid-column: 1/3;
        padding-top: 60px;
    }

    .product-images {
        margin: 0;
        .main-image {
            @include commonBackground();
            background-size: 70%;
            padding-top: 70%;
            height: 0;
            transition: $transition;
            width: calc(100% - 15px);
        }
        .thumbnail-list {
            .thumbnail {
                width: calc(100% - 30px);
                height: 0;
                padding-top: 70%;
                border: solid 1px $placeholderBg;
                @include commonBackground();
                margin: auto;
                transition: $transitionFast;
                cursor: pointer;

                &.selected {
                    transform: scale(1.1);
                    box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.2);
                }
            }
        }

        .slick-list {
            padding: 30px 0;
        }

        &.ph-item {
            .main-image,
            .thumbnail {
                background-color: #ced4da;
            }
        }
    }

    .product-info {
        padding: 0 30px;

        .actions-container {
            .action {

                &-qty {

                    input {
                        width: 100px;
                    }
                }

                &-buttons {
                    button {
                        max-width: 250px;
                    }
                }
            }
        }

        .description-container {
            margin-top: 30px;
            line-height: 2;
        }
    }

    .overall-rating {
        border-radius: 10px;
        border: 1px solid #eee;
        box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.1);
        background: #f5f5f5;
        padding: 10px 15px;
    }

    .product-stars {
        color: $orange;
        
        i {
            margin-right: 2px;
        }
    }

    @media (max-width: 1400px) {
        .product-images {
            .main-image {
                width: 100%;
                height: 300px;
                padding-top: 0;
            }
        }
    }

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
        
        .related-product-list, .product-review-container {
            grid-column: unset;
        }
    }
    
    @media (max-width: 767px) {
        .product-info {
            padding: 0;
        }

        .product-images {
            .main-image {
                height: 200px;
            }
        }
    }

    @media (max-width: 650px) {
        > div {
            margin: 0 7.5px;
        }
    }
}