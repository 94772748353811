.ellipsis-loader {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background: #fff;
        animation-name: ellipsisLoading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        margin: 0 10px;
    }

    &.dot-black {
        div {
            background: #ccc;
        }
    }

    &.el-black {
        background: $black;
    }

    @keyframes ellipsisLoading {
        from,
        to {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-100%);
        }
    }
}