.my-cart-page-container {

    .custom-table {
        display: grid;
        grid-template-columns: auto 1fr 2fr auto 1.5fr auto;
        border: solid 1px $lightGray;
        border-bottom: 0;

        > div {
            padding: 15px;
            border-bottom: solid 1px $lightGray;
        }

        .table-header {
            background-color: $lightGray;
            font-family: $montBold;
        }
        .image {
            .product-image {
                max-width: 50px;
            }
        }
        .edit-qty-container {
            display: grid;
            grid-template-columns: 40px 70px 40px;

            .btn {
                padding: 5px 0;
                border-radius: 0;

                i {
                    transition: $transitionFast;
                }

                &:hover {

                    i {
                        transform: scale(1.4);
                        color: inherit;
                    }
                }
            }
            input {
                text-align: center;
            }
        }
    }

    .mobile-controls {
        display: none;
    }

    @media (max-width: 767px) {

        .custom-table {
            grid-template-columns: 1fr 2fr auto 1fr auto;

            .table-header,
            .sku,
            .product-description {
                display: none;
            }
        }
    }

    @media (max-width: 600px) {

        .custom-table {
            grid-template-columns: auto 2fr;

            .item-price {
                display: none;
            }

            .edit-qty-container {
                grid-template-columns: 30px 40px 30px;
    
                .btn {
                    padding: 5px 0;
                }
            }
        }

        .desktop-controls {
            display: none;
        }

        .mobile-controls {
            display: block;

            &.flex {
                display: flex;
            }
        }

        .button-container {
            display: block;

            .btn {
                display: block;
                margin: 10px auto;
                width: 100%;
            }
        }
    }

    @media (max-width: 380px) {

        .mobile-controls {

            &.flex {
                display: block;

                .total {
                    text-align: left !important;
                    margin-top: 20px;
                }
            }
        }
    }
}