.about {
    
    .team-member {

        .img-container {
            text-align: center;

            img {
                width: 60%;
                object-fit: cover;
                object-position: center;
                border-radius: 100%;
            }
        }
    }

    @media (max-width: 767px) {

        .main-article {
            display: block;

            .txt-header {
                margin-bottom: 20px;
            }
        }

        .team-member-list {
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px 20px;
        }
    }

    @media (max-width: 550px) {

        .team-member-list {
            grid-template-columns: 1fr;
        }
    }
}