#comingSoon {
    background: #000;
    min-height: 100vh;
    position: relative;
    overflow: auto;

    .img-background-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        left: 0;
        top: 0;

        .img-overlay {
            position: absolute;
            width: 110%;
            height: 110%;
            object-fit: cover;
            object-position: center;
            transform: translate(-2.5%, -2.5%);
            transition: 1s;
    
            &.blurred {
                opacity: 0.6;
                filter: blur(22px);
            }
        }
    }

    img {
        &.logo {
            width: 200px;
        }
    }

    .content {
        position: relative;
        color: #fff;
        opacity: 0;
        transition: 0.5s;

        .big-txt {
            font-size: 55px;
            margin-bottom: 20px;

            sup {
                font-size: 0.5em;
            }
        }

        .txt-content {
            font-size: 20px;
        }

        .btn {
            background: #000;
            padding: 20px 30px;
            margin-top: 70px;
            max-width: 200px;
            width: 100%;
            font-weight: bold;
        }
    }

    .subscription-modal {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: scale(0);
        transition: 0s;
        transition-delay: 0.3s;
        background: rgba(0, 0, 0, 0.5);

        .box {
            background: #000;
            // max-width: 800px;
            // width: 100%;
            color: #fff;
            position: relative;
            transform: translateY(10%);
            opacity: 0;
            transition: 0.3s;

            >:first-child {
                border-right: 1px solid #666;
            }

            .btn {
                background: #000;
                border: 1px solid #fff;
                max-width: 200px;
                width: 100%;
                font-weight: bold;

                &:hover {
                    color: inherit;
                }

                &.soc-btn {
                    max-width: initial;
                    width: 300px;
                    border: none;
                    font-weight: 500;
                    display: block;
                    
                    &.fb {
                        background: #4267b2;
                    }

                    &.google {
                        background: #dc4e41;
                    }

                    &.twitter {
                        background: #5baaf4;
                    }
                }
            }

            .dismiss-btn {
                border: none;
                position: absolute;
                right: 10px;
                top: 10px;
                width: auto;
            }

            .success-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                color: #fff;
                background-color: #000;
                overflow: hidden;
                transform: scale(0);
                transition: .3s;

                .img-overlay {
                    opacity:  0.08;
                    position: absolute;
                }

                .message-container {
                    position: relative;
                }

                &.show {
                    transform: scale(1);
                }
            }

            .loading-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgba(0, 0, 0, 0.5);
                opacity: 0;
                transform: scale(0);
                transition: opacity 0.3s;

                &.show {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

        &.show {
            transform: scale(1);
            transition-delay: 0s;

            .box {
                transition-delay: 0.1s;
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    &.show {

        .img-overlay:not(.blurred) {
            opacity: 0;
        }

        .content {
            opacity: 1;
        }
    }

    @media (max-width: 991px) {

        .content {
            padding: 15px;

            .big-txt {
                font-size: 40px;
            }  
            
            .txt-content {
                font-size: 14px;
            }
        }

        @media (max-height: 504px) {
            .subscription-modal {
                overflow: scroll;

                .box {
                    margin: auto;
                }
            }
        }
    }

    @media (max-width: 850px) {
        .subscription-modal {
            
            .box {
                display: block;
                width: 90%;

                .pad-40 {
                    padding: 20px 30px;
                }

                >:first-child {
                    border-right: none;
                    padding-bottom: 0;
                }

                >:nth-child(2) {
                    padding-top: 0;
                }

                label {
                    font-size: 12px;
                }

                input {
                    margin-bottom: 5px;
                }

                .txt-xxlarge {
                    font-size: 18px;
                }
    
                .btn {
                    margin: 20px auto;
                    max-width: 300px;
                    width: 100%;
                    display: block;
    
                    &.soc-btn {
                        max-width: 300px;
                        width: 100%;
                    }
                }
    
                .dismiss-btn {
                    margin: 0;
                    width: auto;
                }
            }
        }

        @media (max-height: 645px) {
            .subscription-modal {
                overflow: scroll;

                .box {
                    margin: auto;
                }
            }
        }
    }

    @media (max-width: 767px) {
        img {
            &.logo {
                width: 150px;
            }
        }

        .content {
            padding: 15px;

            .big-txt {
                font-size: 35px;
            }  
            
            .txt-content {
                font-size: 12px;
            }
        }

        .subscription-modal {
            overflow: scroll;

            .box {
                display: block;
                width: 90%;

                .margin-top-40 {
                    margin-top: 0;
                }

                .register-socmed {
                    
                    > div {
                        margin: 0 5px;
                    }
                }
    
                .dismiss-btn {
                    margin: 0;
                    width: auto;
                }
            }
        }
    }

    @media (max-width: 452px) {
        img {
            &.logo {
                width: 100px;   
            }
        }

        .content {

            .big-txt {
                font-size: 25px;
            } 

            .txt-content {
                br {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 320px) {

        .content {

            .big-txt {
                font-size: 20px;
            }
        }
    }
}