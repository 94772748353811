.contact-us {

    &-container {
        @include commonBackground();
        padding-left: 0;
        padding-right: 0;
        margin-top: 50px;
    }

    .contact-info {

        i {
            width: 20px;
            margin-right: 5px;
        }
    }

    .contact-form {
        max-width: 600px;
        width: 100%;

        .submit-btn {
            max-width: 300px;
        }
    }

    @media (max-width: 991px) {

        &-container {
            display: block;
            padding: 20px 50px;

            > div {
                padding: 0;

                &.form-header {
                    text-align: center;

                    .contact-info {
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                    }
                }

                &.contact-form {
                    margin: 50px auto 0;
                }
            }
        }
    }

    @media (max-width: 767px) {

        &-container {
            display: block;
            padding: 20px;

            > div {
                padding: 0;

                &.form-header {
                    .contact-info {
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                        text-align: center;
                        font-size: 12px;

                        i {
                            display: block;
                            margin: 15px auto 5px;
                            font-size: 20px;
                        }
                    }
                }

                &.contact-form {
                    margin: 50px auto 0;
                }
            }
        }
    }
}