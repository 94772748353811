html {
	font-size: $medium;
	color: $black;
	overflow-x: hidden;
}
body {
	margin: 0;
	padding: 0;
	font-family: $montRegular;
	overflow-x: hidden;
	color: $black;
}

.modal {
	z-index: 999999;
	&-open {
		.main-navbar {
			nav {
				> .container {
					padding-right: 32px;
				}
			}
		}
	}
}

button {
	outline: none !important;
}
a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
	outline: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
	color: inherit;
}
h1 ,h2,h3, h4, h5, h6 {
	font-weight: bold;
}
ul {
	padding-left: 0;
	margin: 0;
}
li {
	list-style: none;
}
.bold {
	font-weight: bold;
}
.italic {
	font-style: italic;
}
.hidden {
	display: none;
}
.transitioned {
	transition: $transition;

	&-fast {
		transition: $transitionFast;
	}
}
.clickable {
	cursor: pointer;
}
.no-pointer {
	pointer-events: none;
}
.scale-hide {
	transform: scale(0);
}

.separator {
	margin: 0 5px;
}

.relative-container {
	position: relative;
}

.margin {
	margin: 5px;

	&-auto {
		margin: auto;
	}

	&-0 {
		margin: 0px !important;
		margin-bottom: 0px;
		margin-top: 0px;
		margin-left: 0px;
		margin-right: 0px;
	}

	&-10 {
		margin: 10px;
	}

	&-15 {
		margin: 15px;
	}

	&-20 {
		margin: 20px;
	}

	&-25 {
		margin: 25px;
	}

	&-30 {
		margin: 30px;
	}

	&-35 {
		margin: 35px;
	}

	&-40 {
		margin: 40px;
	}

	&-45 {
		margin: 45px;
	}

	&-50 {
		margin: 50px;
	}

	&-left {
		margin-left: 5px;

		&-10 {
			margin-left: 10px;
		}
	
		&-15 {
			margin-left: 15px;
		}
	
		&-20 {
			margin-left: 20px;
		}
	
		&-25 {
			margin-left: 25px;
		}
	
		&-30 {
			margin-left: 30px;
		}
	
		&-35 {
			margin-left: 35px;
		}
	
		&-40 {
			margin-left: 40px;
		}
	
		&-45 {
			margin-left: 45px;
		}
	
		&-50 {
			margin-left: 50px;
		}

		&-100 {
			margin-left: 100px;
		}
	}

	&-right {
		margin-right: 5px;

		&-10 {
			margin-right: 10px;
		}
	
		&-15 {
			margin-right: 15px;
		}
	
		&-20 {
			margin-right: 20px;
		}
	
		&-25 {
			margin-right: 25px;
		}
	
		&-30 {
			margin-right: 30px;
		}
	
		&-35 {
			margin-right: 35px;
		}
	
		&-40 {
			margin-right: 40px;
		}
	
		&-45 {
			margin-right: 45px;
		}
	
		&-50 {
			margin-right: 50px;
		}

		&-100 {
			margin-right: 100px;
		}
	}

	&-top {
		margin-top: 5px;

		&-10 {
			margin-top: 10px;
		}
	
		&-15 {
			margin-top: 15px;
		}
	
		&-20 {
			margin-top: 20px;
		}
	
		&-25 {
			margin-top: 25px;
		}
	
		&-30 {
			margin-top: 30px;
		}
	
		&-35 {
			margin-top: 35px;
		}
	
		&-40 {
			margin-top: 40px;
		}
	
		&-45 {
			margin-top: 45px;
		}
	
		&-50 {
			margin-top: 50px;
		}

		&-100 {
			margin-top: 100px;
		}
	}

	&-bottom {
		margin-bottom: 5px;

		&-10 {
			margin-bottom: 10px;
		}
	
		&-15 {
			margin-bottom: 15px;
		}
	
		&-20 {
			margin-bottom: 20px;
		}
	
		&-25 {
			margin-bottom: 25px;
		}
	
		&-30 {
			margin-bottom: 30px;
		}
	
		&-35 {
			margin-bottom: 35px;
		}
	
		&-40 {
			margin-bottom: 40px;
		}
	
		&-45 {
			margin-bottom: 45px;
		}
	
		&-50 {
			margin-bottom: 50px;
		}

		&-100 {
			margin-bottom: 100px;
		}
	}

	&-none {
		margin-left: 0;
		margin-right: 0;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.pad {
	padding: 5px;

	&-10 {
		padding: 10px;
	}

	&-15 {
		padding: 15px;
	}

	&-20 {
		padding: 20px;
	}

	&-25 {
		padding: 25px;
	}

	&-30 {
		padding: 30px;
	}

	&-35 {
		padding: 35px;
	}

	&-40 {
		padding: 40px;
	}

	&-45 {
		padding: 45px;
	}

	&-50 {
		padding: 50px;
	}

	&-100 {
		padding: 100px;
	}

	&-left {
		padding-left: 5px;

		&-10 {
			padding-left: 10px;
		}
	
		&-15 {
			padding-left: 15px;
		}
	
		&-20 {
			padding-left: 20px;
		}
	
		&-25 {
			padding-left: 25px;
		}
	
		&-30 {
			padding-left: 30px;
		}
	
		&-35 {
			padding-left: 35px;
		}
	
		&-40 {
			padding-left: 40px;
		}
	
		&-45 {
			padding-left: 45px;
		}
	
		&-50 {
			padding-left: 50px;
		}

		&-100 {
			padding-left: 100px;
		}
	}

	&-right {
		padding-right: 5px;

		&-10 {
			padding-right: 10px;
		}
	
		&-15 {
			padding-right: 15px;
		}
	
		&-20 {
			padding-right: 20px;
		}
	
		&-25 {
			padding-right: 25px;
		}
	
		&-30 {
			padding-right: 30px;
		}
	
		&-35 {
			padding-right: 35px;
		}
	
		&-40 {
			padding-right: 40px;
		}
	
		&-45 {
			padding-right: 45px;
		}
	
		&-50 {
			padding-right: 50px;
		}

		&-100 {
			padding-right: 100px;
		}
	}

	&-top {
		padding-top: 5px;

		&-10 {
			padding-top: 10px;
		}
	
		&-15 {
			padding-top: 15px;
		}
	
		&-20 {
			padding-top: 20px;
		}
	
		&-25 {
			padding-top: 25px;
		}
	
		&-30 {
			padding-top: 30px;
		}
	
		&-35 {
			padding-top: 35px;
		}
	
		&-40 {
			padding-top: 40px;
		}
	
		&-45 {
			padding-top: 45px;
		}
	
		&-50 {
			padding-top: 50px;
		}

		&-100 {
			padding-top: 100px;
		}
	}

	&-bottom {
		padding-bottom: 5px;

		&-10 {
			padding-bottom: 10px;
		}
	
		&-15 {
			padding-bottom: 15px;
		}
	
		&-20 {
			padding-bottom: 20px;
		}
	
		&-25 {
			padding-bottom: 25px;
		}
	
		&-30 {
			padding-bottom: 30px;
		}
	
		&-35 {
			padding-bottom: 35px;
		}
	
		&-40 {
			padding-bottom: 40px;
		}
	
		&-45 {
			padding-bottom: 45px;
		}
	
		&-50 {
			padding-bottom: 50px;
		}

		&-100 {
			padding-bottom: 100px;
		}
	}
}

.modal-align-vertical {
    display: flex;
	height: 100%;
    background: transparent;
    align-items: center;
    justify-content: center;
	margin: auto;
	justify-content: center;
	align-items: center;
	animation-duration: .6s;
	max-width: 50% !important;

	.modal-dialog {
		margin: auto;;
	}
}

.pointer-events-none {
	pointer-events: none;
}
.remove-bootstrap-animation {
	&.modal {
		&.fade {
			.modal-dialog { 				//Remove Bootstrap modal 
				transform: translate(0, 0); 	//Slide Down animation
				transition: none;				//If it has custom animation
			}
		}
	}
}

.ph-item {
	background-color: transparent;
    border: 0;
    border-radius: 0;
	margin: 0;
	padding: 0;	
	width: 100%;
	opacity: 0.5;
	
	> * {
		padding: 0;

		.ph-row {

			div {
				height: 15px;
				border-radius: 5px;

				&.big {
					height: 25px;
				}
			}
		}
	}
	&::before {
		animation-duration: 2s;
	}
}

.page-body-container {
	.page-banner-image {
		position: relative;
		min-height: 500px;
		background-color: #ccc;
		@include commonBackground();
		background-attachment: fixed;
	}

	.page-banner-text {
		padding: 30px;
		
		p {
			opacity: 0.4;
			font-size: 18px;

			&.txt-header {
				font-size: $h2;
				opacity: 1;
			}
		}
	}

	.page-container {
		max-width: 90%;
		width: 100%;
		margin: auto;
		padding-top: 50px;
	}
}

.pad-to-nav {
	padding-top: $navTopSpacing;
}

.skeleton-loading {
	position: relative;
	overflow: hidden;
	background-color: #DDDBDD;
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		background-image: linear-gradient(
		90deg,
			rgba(#fff, 0) 0,
			rgba(#fff, 0.2) 20%,
			rgba(#fff, 0.5) 60%,
			rgba(#fff, 0)
		);
		animation: shimmer 5s infinite;
		content: '';
	}
  
	@keyframes shimmer {
		100% {
			transform: translateX(100%);
		}
	}
}
.slick-arrow {
	&.slick-prev {
		&:before {
			font-family: 'Font Awesome 5 Pro';
			content: "\F104";
			font-size: 30px;
		}
	}
	&.slick-next {
		&:before {
			font-family: 'Font Awesome 5 Pro';
			content: "\F105";
			font-size: 30px;
		}
	}
}
.section-header {
	margin: auto;
	padding-top: 90px;
}
.section-title {
	font-size: 1.25rem;
	font-family: $montBold;
	padding-bottom: 10px;
}
h1 {
	&.section-title {
		font-size: 2.5rem;
		font-family: $bebasBold;
	}
}

.section-description {
	font-family: $montRegular;
	padding-bottom: 30px;
}

.green-background {
	background-color: $green;
}
.simple-image-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 30px;
	> li {
		height: 0;
		padding-top: 60%;
		@include commonBackground();
	}
}
.grid-fix-3-col {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 15px;
}
.grid-3-col { //responsive 3 columns inside container
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
	grid-gap: 30px;
}
.grid-4-col { //responsive 4 columns inside container
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 30px;
}
.added-product-container {
	display: grid;
	grid-template-columns: 100px 1px 1fr;
	grid-gap: 20px;
	padding: 0 15px;

	.image-container {
		@include commonBackground();
		background-size: contain;
	}

	.details {
		padding: 30px 0;
	}

	.line {
		background-color: $lightGray;
	}
}
.slick-dots {
	li {
		height: auto;
		width: auto;
		button {
			background-color: #fff;
			opacity: .6;
			border-radius: 100%;
			width: 10px;
			height: 10px;
			transition: all .3s ease;
			border: 1px solid $gray;

			&:before {
				content: none;
			}
		}
		&.slick-active, &:hover {
			button {
				background-color: $orange;
				border: 1px solid $orange;
				transform: scale(1.5);
			}
		}
	}
}

.slick-line {
	.slick-dots {
		li {
			button {
				border-radius: 10px;
				width: 40px;
				height: 5px;
				padding: 0;
			}
		}
	}
}

.max-width-90 {
	max-width: 1200px;
	width: 90%;
	margin: auto;
}

.full-width {
	width: 100%;
}

.inline-block {
	display: inline-block;
}

.line-height-1 {
	line-height: 1;
}

.grid {
	display: grid;

	&.justify-center {
		justify-content: center;
	}
	
	&-2 {
		grid-template-columns: 1fr 1fr;

		&-auto {
			grid-template-columns: auto auto;
		}
	}
	
	&-3 {
		grid-template-columns: 1fr 1fr 1fr;

		&-auto {
			grid-template-columns: auto auto auto;
		}
	}

	&-4 {
		grid-template-columns: 1fr 1fr 1fr 1fr;

		&-auto {
			grid-template-columns: auto auto auto auto;
		}
	}

	&-gap-20 {
		grid-gap: 20px;
	}

	&-gap-30 {
		grid-gap: 30px;
	}

	&-gap-40 {
		grid-gap: 40px;
	}

	&-gap-50 {
		grid-gap: 50px;
	}
}

.no-whitespace {
	white-space: nowrap;
}

.more-products-container {

	.grid-3-auto {
		grid-template-columns: minmax(auto, 300px) minmax(auto, 300px) minmax(auto, 300px);
		
	}

	.grid-4-auto {
		grid-template-columns: minmax(auto, 300px) minmax(auto, 300px) minmax(auto, 300px) minmax(auto, 300px);
		
	}

	.product-card-holder {
		max-width: 300px;
	}
}

.dropdown-card {
	animation-name: floatInDown;
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
	top: -10px; 
}

.collapse-container {
	transition: $transition;
	max-height: 0;
	overflow: hidden;
}

.screen-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 99999999999999999999;
}

.pagination-links {

	.page-link {
		width: 35px;
		height: 35px;
		font-size: 16px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		transition: 0.3s;

		&.page-arrow {
			background: #EBEBEB;
		}
		
		&.active {
			background: $orange;
			color: #fff;
		}

		&:hover {
			transform: scale(1.2);
		}
	}
}

.star-group {
	position: relative;

	.left-part,
	.right-part {
		position: absolute;
		top: 0;
		width: 50%;
		overflow: hidden;
	}

	.left-part {
		left: 0;
	}

	.right-part {
		right: 0;

		i {
			float: right;
		}
	}
}

.review-form {
	background: #f5f5f5;
	border-radius: 10px;
	
	textarea {
		width: 100%;
		border: 1px solid #ccc;
		border-radius: 10px;
		height: 150px;
		outline: none;
		padding: 10px;
	}
}

.review-card {
	border-radius: 20px;
	border: 1px solid #eee;
	box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
	padding: 20px;

	.customer-details {
		
		img {
			max-width: 70px;
			width: 70px;
			background-color: #ccc;
			border-radius: 100%;
			margin: 0 auto;
			overflow: hidden;
		}
	}
}

.tooltipped {
	position: relative;

	&::after {
		content: attr(tooltiptext);
		position: absolute;
		background: #111;
		color: #fff;
		top: 0;
		right: 0;
		font-size: 12px;
		white-space: nowrap;
		padding: 3px;
		border-radius: 3px;
		pointer-events: none;
		transition: 0.3s;
		opacity: 0;
		transform: translateY(-50%);
	}

	&:hover {

		&::after {
			transform: translateY(calc(-100% - 5px));
			opacity: 1;
		}
	}

}

.circle-loader {
	position: relative;
	width: 150px;
	height: 150px;
	margin: 0 auto;
	transform: scale(0.7);

	> div {
		position: absolute;
		width: 100%;
		height: 50%;
		overflow: hidden;
		left: 0;
		top: 0;
		animation-name: rotateLoader;
		animation-iteration-count: infinite;
		animation-duration: 1.5s;
		animation-timing-function: linear;
		transform-origin: 50% 100%;
		opacity: 0.9;

		&::after {
			content: '';
			position: absolute;
			width: 150px;
			height: 150px;
			border: 20px solid $orange;
			border-radius: 100%;
			box-shadow: inset 0 5px 5px rgba(241, 170, 170, 0.2);
		}

		&:nth-child(2) {
			animation-duration: 4s;
			&::after {
				border-color: $black;
				border-width: 15px;
			}
		}

		&:last-child {
			animation-duration: 3s;
			animation-direction: reverse;
			&::after {
				border-color: white;
				border-width: 10px;
			}
		}
	}

	@keyframes rotateLoader {
		from {
			transform: rotate(0);
		}

		to {
			transform: rotate(360deg);
		}
	}
	
}

.triangle-loader {
	width: 100px;
	height: 100px;
	position: relative;

	div {
		position: absolute;
		width: 5px;
		height: 100%;
		bottom: 0;
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: #fff;
			left: 0;
			top: 0;
		}

		&:first-child {
			left: -1px;
			bottom: 3px;
			transform: rotate(30deg);
			transform-origin: 0 100%;

			&::after {
				transform: translateY(100%);
				animation-name: slantedAnimation;
				animation-duration: 1.5s;
				animation-iteration-count: infinite;
			}
		}

		&:nth-child(2) {
			right: -2px;
			transform: rotate(-30deg);
			transform-origin: 0 100%;

			&::after {
				transform: translateY(-100%);
				animation-name: slantedAnimationReverse;
				animation-duration: 1.5s;
				animation-iteration-count: infinite;
				animation-delay: 0.5s;
			}
		}

		&:last-child {
			width: 100%;
			height: 5px;
			left: 0;

			&::after {
				transform: translateX(100%);
				animation-name: horizontalAnimation;
				animation-duration: 1.5s;
				animation-iteration-count: infinite;
				animation-delay: 1s;
			}
		}

		@keyframes slantedAnimation {
			from {
				transform: translateY(100%);
			}

			33% {
				transform: translateY(0%);
			}

			to {
				transform: translateY(-100%);
			}

		}

		@keyframes slantedAnimationReverse {
			from {
				transform: translateY(-100%);
			}

			33% {
				transform: translateY(0%);
			}

			to {
				transform: translateY(100%);
			}

		}

		@keyframes horizontalAnimation {
			from {
				transform: translateX(100%);
			}

			33.33% {
				transform: translateX(0%);
			}

			to {
				transform: translateX(-100%);
			}
		}
		
	}
}

@media (max-width: 1200px) {
	.container {
		max-width: 1400px;
		width: 100%;
	}
}

@media (max-width: 991px) {
	.page-body-container {
	
		.page-banner-image {
			min-height: 400px;
		}
	
		.page-banner-text {
			
			p {
	
				&.txt-header {

				}
			}
		}
	
		.page-container {

			&-full-xs {

			}	
		}
	}

	.modal-align-vertical {
		max-width: 80% !important;
	}

	.more-products-container {

		.grid-3-auto {
			grid-template-columns: minmax(auto, 300px) minmax(auto, 300px);
			
		}
	
		.grid-4-auto {
			grid-template-columns: minmax(auto, 300px) minmax(auto, 300px) minmax(auto, 300px);
			
		}
	
		.product-card-holder {
			max-width: 300px;
		}
	}

	.grid {
		
		&-md-1 {
			grid-template-columns: 1fr;
		}
		
		&-md-2 {
			grid-template-columns: 1fr 1fr;
		}

		&-md-3 {
			grid-template-columns: 1fr 1fr 1fr;
		}
	
		&-md-4 {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}
}

@media (max-width: 768px) {
	.margin {
		
		&-30 {
			margin: 25px;
		}
	
		&-35 {
			margin: 25px;
		}
	
		&-40 {
			margin: 30px;
		}
	
		&-45 {
			margin: 35px;
		}
	
		&-50 {
			margin: 35px;
		}
	
		&-left {
		
			&-30 {
				margin-left: 25px;
			}
		
			&-35 {
				margin-left: 25px;
			}
		
			&-40 {
				margin-left: 30px;
			}
		
			&-45 {
				margin-left: 35px;
			}
		
			&-50 {
				margin-left: 35px;
			}
	
			&-100 {
				margin-left: 50px;
			}
		}
	
		&-right {
		
			&-30 {
				margin-right: 25px;
			}
		
			&-35 {
				margin-right: 25px;
			}
		
			&-40 {
				margin-right: 30px;
			}
		
			&-45 {
				margin-right: 35px;
			}
		
			&-50 {
				margin-right: 35px;
			}
	
			&-100 {
				margin-right: 50px;
			}
		}
	
		&-top {
		
			&-30 {
				margin-top: 25px;
			}
		
			&-35 {
				margin-top: 25px;
			}
		
			&-40 {
				margin-top: 30px;
			}
		
			&-45 {
				margin-top: 35px;
			}
		
			&-50 {
				margin-top: 35px;
			}
	
			&-100 {
				margin-top: 50px;
			}
		}
	
		&-bottom {
		
			&-30 {
				margin-bottom: 25px;
			}
		
			&-35 {
				margin-bottom: 25px;
			}
		
			&-40 {
				margin-bottom: 30px;
			}
		
			&-45 {
				margin-bottom: 35px;
			}
		
			&-50 {
				margin-bottom: 35px;
			}
	
			&-100 {
				margin-bottom: 50px;
			}
		}

		&-xs-0 {
			margin: 0;
		}
	}
	
	.pad {
		padding: 5px;
	
		&-25,
		&-30,
		&-35,
		&-40,
		&-45,
		&-50,
		&-100 {
			padding: 20px;
		}
	
		&-left {
		
			&-25 {
				padding-left: 20px;
			}
		
			&-30 {
				padding-left: 20px;
			}
		
			&-35 {
				padding-left: 25px;
			}
		
			&-40 {
				padding-left: 30px;
			}
		
			&-45 {
				padding-left: 35px;
			}
		
			&-50 {
				padding-left: 35px;
			}
	
			&-100 {
				padding-left: 40px;
			}
		}
	
		&-right {
		
			&-25 {
				padding-right: 20px;
			}
		
			&-30 {
				padding-right: 20px;
			}
		
			&-35 {
				padding-right: 25px;
			}
		
			&-40 {
				padding-right: 30px;
			}
		
			&-45 {
				padding-right: 35px;
			}
		
			&-50 {
				padding-right: 35px;
			}
	
			&-100 {
				padding-right: 40px;
			}
		}
	
		&-top {
		
			&-25 {
				padding-top: 20px;
			}
		
			&-30 {
				padding-top: 20px;
			}
		
			&-35 {
				padding-top: 25px;
			}
		
			&-40 {
				padding-top: 30px;
			}
		
			&-45 {
				padding-top: 35px;
			}
		
			&-50 {
				padding-top: 35px;
			}
	
			&-100 {
				padding-top: 40px;
			}
		}
	
		&-bottom {
		
			&-25 {
				padding-bottom: 20px;
			}
		
			&-30 {
				padding-bottom: 20px;
			}
		
			&-35 {
				padding-bottom: 25px;
			}
		
			&-40 {
				padding-bottom: 30px;
			}
		
			&-45 {
				padding-bottom: 35px;
			}
		
			&-50 {
				padding-bottom: 35px;
			}
	
			&-100 {
				padding-bottom: 40px;
			}
		}
	}

	.pad-xs-0 {
		padding: 0 !important;	
	}
	
    .container{
        max-width: 100%;
        width: 100%;
	}
	
	.page-body-container {
		// padding-top: 65px;
	
		.page-banner-image {
			min-height: 300px;
		}
	
		.page-banner-text {
			padding: 15px;
			
			p {
				opacity: 0.4;
				font-size: 18px;
	
				&.txt-header {
					font-size: $h2;
					opacity: 1;
				}
			}
		}
	
		.page-container {
			margin-top: 20px;

			&-full-xs {
				max-width: 100%;
				padding: 0;
			}	
		}
	}

	.modal-align-vertical {
		max-width: 90% !important;
	}

	.grid {
		
		&-xs-1 {
			grid-template-columns: 1fr;
		}
		
		&-xs-2 {
			grid-template-columns: 1fr 1fr;
		}

		&-xs-3 {
			grid-template-columns: 1fr 1fr 1fr;
		}
	
		&-xs-4 {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}

	.more-products-container {
	
		.grid-4-auto {
			grid-template-columns: minmax(auto, 300px) minmax(auto, 300px);
			
		}
	}
}

@media (max-width: 600px) {
	.added-product-container {
		.button-container {
			display: block;
			
			.btn {
				margin: 10px auto;
				font-size: 12px;
				display: block;
				max-width: 300px;
				width: 100%;
			}
		}
	}
}

@media (max-width: 576px) {
    .container{
        max-width: 100%;
        width: 100%;
    }
}

// HEIGHT  MEDIA QUERY
@media (max-height: 550px) {
	.modal {
		.modal-align-vertical {
			margin: 50px auto 10px;
			height: auto;
			min-height: 100%;
		}
	}

	.added-product-container {
		grid-template-columns: 1fr;
		grid-gap: 5px;

		.image-container {
			height: 100px;
			margin-top: 5px;
		}
	}
}