.product-card-js {

    &.product-card {
        position: relative;
        cursor: pointer;

        .image-container {
            margin-bottom: 15px;
            position: relative;
            background: $orange;
            overflow: hidden;

            img {
                width: 100%;
                // min-height: 250px;
                object-fit: contain;
                object-position: center;
                transition: $transitionFast;
                transform: scale(1.1);
            }

            .loading-image-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                background: #ccc;
            }

            .hover-overlay {
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                background: #282828;
                transform: translateY(100%);
                transition: $transitionFast;
            }
        }

        .discount-badge {
            position: absolute;
            transform: translateX(-42.5%);
            top: 0;
            width: 400px;
            // background: #111;

            > div {
                transform: rotate(-45deg) scale(0.6);
                background: $red;
                width: 100%;
            }
        }

        .category {
            color: $lightGray;
            font-size: 0.625rem;
            font-family: $montSemiBold;
            margin: 0;
        }

        .product-title {
            margin: 0;
            padding: 10px 0 5px;
            color: $black;
        }

        .product-price {
            color: $black;
            padding-bottom: 10px;
        }

        .product-star {
            color: $orange;
            
            i {
                margin-right: 2px;
            }
        }

        &:hover {
            
            .image-container {

                img {
                    transform: scale(1);
                }

                .hover-overlay {
                    transform: translateY(0);
                }
            }
        }

        &.ph-item {
            .image-container {
                background-color: $lightGray;
            }
        }
    }

    @media (max-width: 991px) {
        &.product-card {

            .discount-badge {
                // transform: scale(0.8);
            }
        }
    }

    @media (max-width: 550px) {
        &.product-card {

            .discount-badge {
                transform: translateX(-46%);

                .txt-medium {
                    display: none;
                }
            }
        }
    }
}
