.shipping-zone-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border: solid 1px #f0f0f0;
    border-bottom: 0;
    > div {
        padding: 10px;
        border-bottom: solid 1px #f0f0f0;
    }
    .list-label {
        background-color: #f0f0f0;
        font-family: $montBold;
    }
    .zone-name {
        grid-column: 1/4;
        font-family: $montMedium;
    }
    .zone-type {
        padding-left: 15px;
    }
}