/*!
 * Font Awesome Pro 5.0.8 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  -webkit-filter: none;
          filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\F26E"; }

.fa-accessible-icon:before {
  content: "\F368"; }

.fa-accusoft:before {
  content: "\F369"; }

.fa-address-book:before {
  content: "\F2B9"; }

.fa-address-card:before {
  content: "\F2BB"; }

.fa-adjust:before {
  content: "\F042"; }

.fa-adn:before {
  content: "\F170"; }

.fa-adversal:before {
  content: "\F36A"; }

.fa-affiliatetheme:before {
  content: "\F36B"; }

.fa-alarm-clock:before {
  content: "\F34E"; }

.fa-algolia:before {
  content: "\F36C"; }

.fa-align-center:before {
  content: "\F037"; }

.fa-align-justify:before {
  content: "\F039"; }

.fa-align-left:before {
  content: "\F036"; }

.fa-align-right:before {
  content: "\F038"; }

.fa-allergies:before {
  content: "\F461"; }

.fa-amazon:before {
  content: "\F270"; }

.fa-amazon-pay:before {
  content: "\F42C"; }

.fa-ambulance:before {
  content: "\F0F9"; }

.fa-american-sign-language-interpreting:before {
  content: "\F2A3"; }

.fa-amilia:before {
  content: "\F36D"; }

.fa-anchor:before {
  content: "\F13D"; }

.fa-android:before {
  content: "\F17B"; }

.fa-angellist:before {
  content: "\F209"; }

.fa-angle-double-down:before {
  content: "\F103"; }

.fa-angle-double-left:before {
  content: "\F100"; }

.fa-angle-double-right:before {
  content: "\F101"; }

.fa-angle-double-up:before {
  content: "\F102"; }

.fa-angle-down:before {
  content: "\F107"; }

.fa-angle-left:before {
  content: "\F104"; }

.fa-angle-right:before {
  content: "\F105"; }

.fa-angle-up:before {
  content: "\F106"; }

.fa-angrycreative:before {
  content: "\F36E"; }

.fa-angular:before {
  content: "\F420"; }

.fa-app-store:before {
  content: "\F36F"; }

.fa-app-store-ios:before {
  content: "\F370"; }

.fa-apper:before {
  content: "\F371"; }

.fa-apple:before {
  content: "\F179"; }

.fa-apple-pay:before {
  content: "\F415"; }

.fa-archive:before {
  content: "\F187"; }

.fa-arrow-alt-circle-down:before {
  content: "\F358"; }

.fa-arrow-alt-circle-left:before {
  content: "\F359"; }

.fa-arrow-alt-circle-right:before {
  content: "\F35A"; }

.fa-arrow-alt-circle-up:before {
  content: "\F35B"; }

.fa-arrow-alt-down:before {
  content: "\F354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\F346"; }

.fa-arrow-alt-from-left:before {
  content: "\F347"; }

.fa-arrow-alt-from-right:before {
  content: "\F348"; }

.fa-arrow-alt-from-top:before {
  content: "\F349"; }

.fa-arrow-alt-left:before {
  content: "\F355"; }

.fa-arrow-alt-right:before {
  content: "\F356"; }

.fa-arrow-alt-square-down:before {
  content: "\F350"; }

.fa-arrow-alt-square-left:before {
  content: "\F351"; }

.fa-arrow-alt-square-right:before {
  content: "\F352"; }

.fa-arrow-alt-square-up:before {
  content: "\F353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\F34A"; }

.fa-arrow-alt-to-left:before {
  content: "\F34B"; }

.fa-arrow-alt-to-right:before {
  content: "\F34C"; }

.fa-arrow-alt-to-top:before {
  content: "\F34D"; }

.fa-arrow-alt-up:before {
  content: "\F357"; }

.fa-arrow-circle-down:before {
  content: "\F0AB"; }

.fa-arrow-circle-left:before {
  content: "\F0A8"; }

.fa-arrow-circle-right:before {
  content: "\F0A9"; }

.fa-arrow-circle-up:before {
  content: "\F0AA"; }

.fa-arrow-down:before {
  content: "\F063"; }

.fa-arrow-from-bottom:before {
  content: "\F342"; }

.fa-arrow-from-left:before {
  content: "\F343"; }

.fa-arrow-from-right:before {
  content: "\F344"; }

.fa-arrow-from-top:before {
  content: "\F345"; }

.fa-arrow-left:before {
  content: "\F060"; }

.fa-arrow-right:before {
  content: "\F061"; }

.fa-arrow-square-down:before {
  content: "\F339"; }

.fa-arrow-square-left:before {
  content: "\F33A"; }

.fa-arrow-square-right:before {
  content: "\F33B"; }

.fa-arrow-square-up:before {
  content: "\F33C"; }

.fa-arrow-to-bottom:before {
  content: "\F33D"; }

.fa-arrow-to-left:before {
  content: "\F33E"; }

.fa-arrow-to-right:before {
  content: "\F340"; }

.fa-arrow-to-top:before {
  content: "\F341"; }

.fa-arrow-up:before {
  content: "\F062"; }

.fa-arrows:before {
  content: "\F047"; }

.fa-arrows-alt:before {
  content: "\F0B2"; }

.fa-arrows-alt-h:before {
  content: "\F337"; }

.fa-arrows-alt-v:before {
  content: "\F338"; }

.fa-arrows-h:before {
  content: "\F07E"; }

.fa-arrows-v:before {
  content: "\F07D"; }

.fa-assistive-listening-systems:before {
  content: "\F2A2"; }

.fa-asterisk:before {
  content: "\F069"; }

.fa-asymmetrik:before {
  content: "\F372"; }

.fa-at:before {
  content: "\F1FA"; }

.fa-audible:before {
  content: "\F373"; }

.fa-audio-description:before {
  content: "\F29E"; }

.fa-autoprefixer:before {
  content: "\F41C"; }

.fa-avianex:before {
  content: "\F374"; }

.fa-aviato:before {
  content: "\F421"; }

.fa-aws:before {
  content: "\F375"; }

.fa-backward:before {
  content: "\F04A"; }

.fa-badge:before {
  content: "\F335"; }

.fa-badge-check:before {
  content: "\F336"; }

.fa-balance-scale:before {
  content: "\F24E"; }

.fa-ban:before {
  content: "\F05E"; }

.fa-band-aid:before {
  content: "\F462"; }

.fa-bandcamp:before {
  content: "\F2D5"; }

.fa-barcode:before {
  content: "\F02A"; }

.fa-barcode-alt:before {
  content: "\F463"; }

.fa-barcode-read:before {
  content: "\F464"; }

.fa-barcode-scan:before {
  content: "\F465"; }

.fa-bars:before {
  content: "\F0C9"; }

.fa-baseball:before {
  content: "\F432"; }

.fa-baseball-ball:before {
  content: "\F433"; }

.fa-basketball-ball:before {
  content: "\F434"; }

.fa-basketball-hoop:before {
  content: "\F435"; }

.fa-bath:before {
  content: "\F2CD"; }

.fa-battery-bolt:before {
  content: "\F376"; }

.fa-battery-empty:before {
  content: "\F244"; }

.fa-battery-full:before {
  content: "\F240"; }

.fa-battery-half:before {
  content: "\F242"; }

.fa-battery-quarter:before {
  content: "\F243"; }

.fa-battery-slash:before {
  content: "\F377"; }

.fa-battery-three-quarters:before {
  content: "\F241"; }

.fa-bed:before {
  content: "\F236"; }

.fa-beer:before {
  content: "\F0FC"; }

.fa-behance:before {
  content: "\F1B4"; }

.fa-behance-square:before {
  content: "\F1B5"; }

.fa-bell:before {
  content: "\F0F3"; }

.fa-bell-slash:before {
  content: "\F1F6"; }

.fa-bicycle:before {
  content: "\F206"; }

.fa-bimobject:before {
  content: "\F378"; }

.fa-binoculars:before {
  content: "\F1E5"; }

.fa-birthday-cake:before {
  content: "\F1FD"; }

.fa-bitbucket:before {
  content: "\F171"; }

.fa-bitcoin:before {
  content: "\F379"; }

.fa-bity:before {
  content: "\F37A"; }

.fa-black-tie:before {
  content: "\F27E"; }

.fa-blackberry:before {
  content: "\F37B"; }

.fa-blind:before {
  content: "\F29D"; }

.fa-blogger:before {
  content: "\F37C"; }

.fa-blogger-b:before {
  content: "\F37D"; }

.fa-bluetooth:before {
  content: "\F293"; }

.fa-bluetooth-b:before {
  content: "\F294"; }

.fa-bold:before {
  content: "\F032"; }

.fa-bolt:before {
  content: "\F0E7"; }

.fa-bomb:before {
  content: "\F1E2"; }

.fa-book:before {
  content: "\F02D"; }

.fa-bookmark:before {
  content: "\F02E"; }

.fa-bowling-ball:before {
  content: "\F436"; }

.fa-bowling-pins:before {
  content: "\F437"; }

.fa-box:before {
  content: "\F466"; }

.fa-box-check:before {
  content: "\F467"; }

.fa-boxes:before {
  content: "\F468"; }

.fa-boxing-glove:before {
  content: "\F438"; }

.fa-braille:before {
  content: "\F2A1"; }

.fa-briefcase:before {
  content: "\F0B1"; }

.fa-briefcase-medical:before {
  content: "\F469"; }

.fa-browser:before {
  content: "\F37E"; }

.fa-btc:before {
  content: "\F15A"; }

.fa-bug:before {
  content: "\F188"; }

.fa-building:before {
  content: "\F1AD"; }

.fa-bullhorn:before {
  content: "\F0A1"; }

.fa-bullseye:before {
  content: "\F140"; }

.fa-burn:before {
  content: "\F46A"; }

.fa-buromobelexperte:before {
  content: "\F37F"; }

.fa-bus:before {
  content: "\F207"; }

.fa-buysellads:before {
  content: "\F20D"; }

.fa-calculator:before {
  content: "\F1EC"; }

.fa-calendar:before {
  content: "\F133"; }

.fa-calendar-alt:before {
  content: "\F073"; }

.fa-calendar-check:before {
  content: "\F274"; }

.fa-calendar-edit:before {
  content: "\F333"; }

.fa-calendar-exclamation:before {
  content: "\F334"; }

.fa-calendar-minus:before {
  content: "\F272"; }

.fa-calendar-plus:before {
  content: "\F271"; }

.fa-calendar-times:before {
  content: "\F273"; }

.fa-camera:before {
  content: "\F030"; }

.fa-camera-alt:before {
  content: "\F332"; }

.fa-camera-retro:before {
  content: "\F083"; }

.fa-capsules:before {
  content: "\F46B"; }

.fa-car:before {
  content: "\F1B9"; }

.fa-caret-circle-down:before {
  content: "\F32D"; }

.fa-caret-circle-left:before {
  content: "\F32E"; }

.fa-caret-circle-right:before {
  content: "\F330"; }

.fa-caret-circle-up:before {
  content: "\F331"; }

.fa-caret-down:before {
  content: "\F0D7"; }

.fa-caret-left:before {
  content: "\F0D9"; }

.fa-caret-right:before {
  content: "\F0DA"; }

.fa-caret-square-down:before {
  content: "\F150"; }

.fa-caret-square-left:before {
  content: "\F191"; }

.fa-caret-square-right:before {
  content: "\F152"; }

.fa-caret-square-up:before {
  content: "\F151"; }

.fa-caret-up:before {
  content: "\F0D8"; }

.fa-cart-arrow-down:before {
  content: "\F218"; }

.fa-cart-plus:before {
  content: "\F217"; }

.fa-cc-amazon-pay:before {
  content: "\F42D"; }

.fa-cc-amex:before {
  content: "\F1F3"; }

.fa-cc-apple-pay:before {
  content: "\F416"; }

.fa-cc-diners-club:before {
  content: "\F24C"; }

.fa-cc-discover:before {
  content: "\F1F2"; }

.fa-cc-jcb:before {
  content: "\F24B"; }

.fa-cc-mastercard:before {
  content: "\F1F1"; }

.fa-cc-paypal:before {
  content: "\F1F4"; }

.fa-cc-stripe:before {
  content: "\F1F5"; }

.fa-cc-visa:before {
  content: "\F1F0"; }

.fa-centercode:before {
  content: "\F380"; }

.fa-certificate:before {
  content: "\F0A3"; }

.fa-chart-area:before {
  content: "\F1FE"; }

.fa-chart-bar:before {
  content: "\F080"; }

.fa-chart-line:before {
  content: "\F201"; }

.fa-chart-pie:before {
  content: "\F200"; }

.fa-check:before {
  content: "\F00C"; }

.fa-check-circle:before {
  content: "\F058"; }

.fa-check-square:before {
  content: "\F14A"; }

.fa-chess:before {
  content: "\F439"; }

.fa-chess-bishop:before {
  content: "\F43A"; }

.fa-chess-bishop-alt:before {
  content: "\F43B"; }

.fa-chess-board:before {
  content: "\F43C"; }

.fa-chess-clock:before {
  content: "\F43D"; }

.fa-chess-clock-alt:before {
  content: "\F43E"; }

.fa-chess-king:before {
  content: "\F43F"; }

.fa-chess-king-alt:before {
  content: "\F440"; }

.fa-chess-knight:before {
  content: "\F441"; }

.fa-chess-knight-alt:before {
  content: "\F442"; }

.fa-chess-pawn:before {
  content: "\F443"; }

.fa-chess-pawn-alt:before {
  content: "\F444"; }

.fa-chess-queen:before {
  content: "\F445"; }

.fa-chess-queen-alt:before {
  content: "\F446"; }

.fa-chess-rook:before {
  content: "\F447"; }

.fa-chess-rook-alt:before {
  content: "\F448"; }

.fa-chevron-circle-down:before {
  content: "\F13A"; }

.fa-chevron-circle-left:before {
  content: "\F137"; }

.fa-chevron-circle-right:before {
  content: "\F138"; }

.fa-chevron-circle-up:before {
  content: "\F139"; }

.fa-chevron-double-down:before {
  content: "\F322"; }

.fa-chevron-double-left:before {
  content: "\F323"; }

.fa-chevron-double-right:before {
  content: "\F324"; }

.fa-chevron-double-up:before {
  content: "\F325"; }

.fa-chevron-down:before {
  content: "\F078"; }

.fa-chevron-left:before {
  content: "\F053"; }

.fa-chevron-right:before {
  content: "\F054"; }

.fa-chevron-square-down:before {
  content: "\F329"; }

.fa-chevron-square-left:before {
  content: "\F32A"; }

.fa-chevron-square-right:before {
  content: "\F32B"; }

.fa-chevron-square-up:before {
  content: "\F32C"; }

.fa-chevron-up:before {
  content: "\F077"; }

.fa-child:before {
  content: "\F1AE"; }

.fa-chrome:before {
  content: "\F268"; }

.fa-circle:before {
  content: "\F111"; }

.fa-circle-notch:before {
  content: "\F1CE"; }

.fa-clipboard:before {
  content: "\F328"; }

.fa-clipboard-check:before {
  content: "\F46C"; }

.fa-clipboard-list:before {
  content: "\F46D"; }

.fa-clock:before {
  content: "\F017"; }

.fa-clone:before {
  content: "\F24D"; }

.fa-closed-captioning:before {
  content: "\F20A"; }

.fa-cloud:before {
  content: "\F0C2"; }

.fa-cloud-download:before {
  content: "\F0ED"; }

.fa-cloud-download-alt:before {
  content: "\F381"; }

.fa-cloud-upload:before {
  content: "\F0EE"; }

.fa-cloud-upload-alt:before {
  content: "\F382"; }

.fa-cloudscale:before {
  content: "\F383"; }

.fa-cloudsmith:before {
  content: "\F384"; }

.fa-cloudversify:before {
  content: "\F385"; }

.fa-club:before {
  content: "\F327"; }

.fa-code:before {
  content: "\F121"; }

.fa-code-branch:before {
  content: "\F126"; }

.fa-code-commit:before {
  content: "\F386"; }

.fa-code-merge:before {
  content: "\F387"; }

.fa-codepen:before {
  content: "\F1CB"; }

.fa-codiepie:before {
  content: "\F284"; }

.fa-coffee:before {
  content: "\F0F4"; }

.fa-cog:before {
  content: "\F013"; }

.fa-cogs:before {
  content: "\F085"; }

.fa-columns:before {
  content: "\F0DB"; }

.fa-comment:before {
  content: "\F075"; }

.fa-comment-alt:before {
  content: "\F27A"; }

.fa-comments:before {
  content: "\F086"; }

.fa-compass:before {
  content: "\F14E"; }

.fa-compress:before {
  content: "\F066"; }

.fa-compress-alt:before {
  content: "\F422"; }

.fa-compress-wide:before {
  content: "\F326"; }

.fa-connectdevelop:before {
  content: "\F20E"; }

.fa-contao:before {
  content: "\F26D"; }

.fa-conveyor-belt:before {
  content: "\F46E"; }

.fa-conveyor-belt-alt:before {
  content: "\F46F"; }

.fa-copy:before {
  content: "\F0C5"; }

.fa-copyright:before {
  content: "\F1F9"; }

.fa-cpanel:before {
  content: "\F388"; }

.fa-creative-commons:before {
  content: "\F25E"; }

.fa-credit-card:before {
  content: "\F09D"; }

.fa-credit-card-blank:before {
  content: "\F389"; }

.fa-credit-card-front:before {
  content: "\F38A"; }

.fa-cricket:before {
  content: "\F449"; }

.fa-crop:before {
  content: "\F125"; }

.fa-crosshairs:before {
  content: "\F05B"; }

.fa-css3:before {
  content: "\F13C"; }

.fa-css3-alt:before {
  content: "\F38B"; }

.fa-cube:before {
  content: "\F1B2"; }

.fa-cubes:before {
  content: "\F1B3"; }

.fa-curling:before {
  content: "\F44A"; }

.fa-cut:before {
  content: "\F0C4"; }

.fa-cuttlefish:before {
  content: "\F38C"; }

.fa-d-and-d:before {
  content: "\F38D"; }

.fa-dashcube:before {
  content: "\F210"; }

.fa-database:before {
  content: "\F1C0"; }

.fa-deaf:before {
  content: "\F2A4"; }

.fa-delicious:before {
  content: "\F1A5"; }

.fa-deploydog:before {
  content: "\F38E"; }

.fa-deskpro:before {
  content: "\F38F"; }

.fa-desktop:before {
  content: "\F108"; }

.fa-desktop-alt:before {
  content: "\F390"; }

.fa-deviantart:before {
  content: "\F1BD"; }

.fa-diagnoses:before {
  content: "\F470"; }

.fa-diamond:before {
  content: "\F219"; }

.fa-digg:before {
  content: "\F1A6"; }

.fa-digital-ocean:before {
  content: "\F391"; }

.fa-discord:before {
  content: "\F392"; }

.fa-discourse:before {
  content: "\F393"; }

.fa-dna:before {
  content: "\F471"; }

.fa-dochub:before {
  content: "\F394"; }

.fa-docker:before {
  content: "\F395"; }

.fa-dollar-sign:before {
  content: "\F155"; }

.fa-dolly:before {
  content: "\F472"; }

.fa-dolly-empty:before {
  content: "\F473"; }

.fa-dolly-flatbed:before {
  content: "\F474"; }

.fa-dolly-flatbed-alt:before {
  content: "\F475"; }

.fa-dolly-flatbed-empty:before {
  content: "\F476"; }

.fa-dot-circle:before {
  content: "\F192"; }

.fa-download:before {
  content: "\F019"; }

.fa-draft2digital:before {
  content: "\F396"; }

.fa-dribbble:before {
  content: "\F17D"; }

.fa-dribbble-square:before {
  content: "\F397"; }

.fa-dropbox:before {
  content: "\F16B"; }

.fa-drupal:before {
  content: "\F1A9"; }

.fa-dumbbell:before {
  content: "\F44B"; }

.fa-dyalog:before {
  content: "\F399"; }

.fa-earlybirds:before {
  content: "\F39A"; }

.fa-edge:before {
  content: "\F282"; }

.fa-edit:before {
  content: "\F044"; }

.fa-eject:before {
  content: "\F052"; }

.fa-elementor:before {
  content: "\F430"; }

.fa-ellipsis-h:before {
  content: "\F141"; }

.fa-ellipsis-h-alt:before {
  content: "\F39B"; }

.fa-ellipsis-v:before {
  content: "\F142"; }

.fa-ellipsis-v-alt:before {
  content: "\F39C"; }

.fa-ember:before {
  content: "\F423"; }

.fa-empire:before {
  content: "\F1D1"; }

.fa-envelope:before {
  content: "\F0E0"; }

.fa-envelope-open:before {
  content: "\F2B6"; }

.fa-envelope-square:before {
  content: "\F199"; }

.fa-envira:before {
  content: "\F299"; }

.fa-eraser:before {
  content: "\F12D"; }

.fa-erlang:before {
  content: "\F39D"; }

.fa-ethereum:before {
  content: "\F42E"; }

.fa-etsy:before {
  content: "\F2D7"; }

.fa-euro-sign:before {
  content: "\F153"; }

.fa-exchange:before {
  content: "\F0EC"; }

.fa-exchange-alt:before {
  content: "\F362"; }

.fa-exclamation:before {
  content: "\F12A"; }

.fa-exclamation-circle:before {
  content: "\F06A"; }

.fa-exclamation-square:before {
  content: "\F321"; }

.fa-exclamation-triangle:before {
  content: "\F071"; }

.fa-expand:before {
  content: "\F065"; }

.fa-expand-alt:before {
  content: "\F424"; }

.fa-expand-arrows:before {
  content: "\F31D"; }

.fa-expand-arrows-alt:before {
  content: "\F31E"; }

.fa-expand-wide:before {
  content: "\F320"; }

.fa-expeditedssl:before {
  content: "\F23E"; }

.fa-external-link:before {
  content: "\F08E"; }

.fa-external-link-alt:before {
  content: "\F35D"; }

.fa-external-link-square:before {
  content: "\F14C"; }

.fa-external-link-square-alt:before {
  content: "\F360"; }

.fa-eye:before {
  content: "\F06E"; }

.fa-eye-dropper:before {
  content: "\F1FB"; }

.fa-eye-slash:before {
  content: "\F070"; }

.fa-facebook:before {
  content: "\F09A"; }

.fa-facebook-f:before {
  content: "\F39E"; }

.fa-facebook-messenger:before {
  content: "\F39F"; }

.fa-facebook-square:before {
  content: "\F082"; }

.fa-fast-backward:before {
  content: "\F049"; }

.fa-fast-forward:before {
  content: "\F050"; }

.fa-fax:before {
  content: "\F1AC"; }

.fa-female:before {
  content: "\F182"; }

.fa-field-hockey:before {
  content: "\F44C"; }

.fa-fighter-jet:before {
  content: "\F0FB"; }

.fa-file:before {
  content: "\F15B"; }

.fa-file-alt:before {
  content: "\F15C"; }

.fa-file-archive:before {
  content: "\F1C6"; }

.fa-file-audio:before {
  content: "\F1C7"; }

.fa-file-check:before {
  content: "\F316"; }

.fa-file-code:before {
  content: "\F1C9"; }

.fa-file-edit:before {
  content: "\F31C"; }

.fa-file-excel:before {
  content: "\F1C3"; }

.fa-file-exclamation:before {
  content: "\F31A"; }

.fa-file-image:before {
  content: "\F1C5"; }

.fa-file-medical:before {
  content: "\F477"; }

.fa-file-medical-alt:before {
  content: "\F478"; }

.fa-file-minus:before {
  content: "\F318"; }

.fa-file-pdf:before {
  content: "\F1C1"; }

.fa-file-plus:before {
  content: "\F319"; }

.fa-file-powerpoint:before {
  content: "\F1C4"; }

.fa-file-times:before {
  content: "\F317"; }

.fa-file-video:before {
  content: "\F1C8"; }

.fa-file-word:before {
  content: "\F1C2"; }

.fa-film:before {
  content: "\F008"; }

.fa-film-alt:before {
  content: "\F3A0"; }

.fa-filter:before {
  content: "\F0B0"; }

.fa-fire:before {
  content: "\F06D"; }

.fa-fire-extinguisher:before {
  content: "\F134"; }

.fa-firefox:before {
  content: "\F269"; }

.fa-first-aid:before {
  content: "\F479"; }

.fa-first-order:before {
  content: "\F2B0"; }

.fa-firstdraft:before {
  content: "\F3A1"; }

.fa-flag:before {
  content: "\F024"; }

.fa-flag-checkered:before {
  content: "\F11E"; }

.fa-flask:before {
  content: "\F0C3"; }

.fa-flickr:before {
  content: "\F16E"; }

.fa-flipboard:before {
  content: "\F44D"; }

.fa-fly:before {
  content: "\F417"; }

.fa-folder:before {
  content: "\F07B"; }

.fa-folder-open:before {
  content: "\F07C"; }

.fa-font:before {
  content: "\F031"; }

.fa-font-awesome:before {
  content: "\F2B4"; }

.fa-font-awesome-alt:before {
  content: "\F35C"; }

.fa-font-awesome-flag:before {
  content: "\F425"; }

.fa-fonticons:before {
  content: "\F280"; }

.fa-fonticons-fi:before {
  content: "\F3A2"; }

.fa-football-ball:before {
  content: "\F44E"; }

.fa-football-helmet:before {
  content: "\F44F"; }

.fa-forklift:before {
  content: "\F47A"; }

.fa-fort-awesome:before {
  content: "\F286"; }

.fa-fort-awesome-alt:before {
  content: "\F3A3"; }

.fa-forumbee:before {
  content: "\F211"; }

.fa-forward:before {
  content: "\F04E"; }

.fa-foursquare:before {
  content: "\F180"; }

.fa-free-code-camp:before {
  content: "\F2C5"; }

.fa-freebsd:before {
  content: "\F3A4"; }

.fa-frown:before {
  content: "\F119"; }

.fa-futbol:before {
  content: "\F1E3"; }

.fa-gamepad:before {
  content: "\F11B"; }

.fa-gavel:before {
  content: "\F0E3"; }

.fa-gem:before {
  content: "\F3A5"; }

.fa-genderless:before {
  content: "\F22D"; }

.fa-get-pocket:before {
  content: "\F265"; }

.fa-gg:before {
  content: "\F260"; }

.fa-gg-circle:before {
  content: "\F261"; }

.fa-gift:before {
  content: "\F06B"; }

.fa-git:before {
  content: "\F1D3"; }

.fa-git-square:before {
  content: "\F1D2"; }

.fa-github:before {
  content: "\F09B"; }

.fa-github-alt:before {
  content: "\F113"; }

.fa-github-square:before {
  content: "\F092"; }

.fa-gitkraken:before {
  content: "\F3A6"; }

.fa-gitlab:before {
  content: "\F296"; }

.fa-gitter:before {
  content: "\F426"; }

.fa-glass-martini:before {
  content: "\F000"; }

.fa-glide:before {
  content: "\F2A5"; }

.fa-glide-g:before {
  content: "\F2A6"; }

.fa-globe:before {
  content: "\F0AC"; }

.fa-gofore:before {
  content: "\F3A7"; }

.fa-golf-ball:before {
  content: "\F450"; }

.fa-golf-club:before {
  content: "\F451"; }

.fa-goodreads:before {
  content: "\F3A8"; }

.fa-goodreads-g:before {
  content: "\F3A9"; }

.fa-google:before {
  content: "\F1A0"; }

.fa-google-drive:before {
  content: "\F3AA"; }

.fa-google-play:before {
  content: "\F3AB"; }

.fa-google-plus:before {
  content: "\F2B3"; }

.fa-google-plus-g:before {
  content: "\F0D5"; }

.fa-google-plus-square:before {
  content: "\F0D4"; }

.fa-google-wallet:before {
  content: "\F1EE"; }

.fa-graduation-cap:before {
  content: "\F19D"; }

.fa-gratipay:before {
  content: "\F184"; }

.fa-grav:before {
  content: "\F2D6"; }

.fa-gripfire:before {
  content: "\F3AC"; }

.fa-grunt:before {
  content: "\F3AD"; }

.fa-gulp:before {
  content: "\F3AE"; }

.fa-h-square:before {
  content: "\F0FD"; }

.fa-h1:before {
  content: "\F313"; }

.fa-h2:before {
  content: "\F314"; }

.fa-h3:before {
  content: "\F315"; }

.fa-hacker-news:before {
  content: "\F1D4"; }

.fa-hacker-news-square:before {
  content: "\F3AF"; }

.fa-hand-holding-box:before {
  content: "\F47B"; }

.fa-hand-lizard:before {
  content: "\F258"; }

.fa-hand-paper:before {
  content: "\F256"; }

.fa-hand-peace:before {
  content: "\F25B"; }

.fa-hand-point-down:before {
  content: "\F0A7"; }

.fa-hand-point-left:before {
  content: "\F0A5"; }

.fa-hand-point-right:before {
  content: "\F0A4"; }

.fa-hand-point-up:before {
  content: "\F0A6"; }

.fa-hand-pointer:before {
  content: "\F25A"; }

.fa-hand-receiving:before {
  content: "\F47C"; }

.fa-hand-rock:before {
  content: "\F255"; }

.fa-hand-scissors:before {
  content: "\F257"; }

.fa-hand-spock:before {
  content: "\F259"; }

.fa-handshake:before {
  content: "\F2B5"; }

.fa-hashtag:before {
  content: "\F292"; }

.fa-hdd:before {
  content: "\F0A0"; }

.fa-heading:before {
  content: "\F1DC"; }

.fa-headphones:before {
  content: "\F025"; }

.fa-heart:before {
  content: "\F004"; }

.fa-heartbeat:before {
  content: "\F21E"; }

.fa-hexagon:before {
  content: "\F312"; }

.fa-hips:before {
  content: "\F452"; }

.fa-hire-a-helper:before {
  content: "\F3B0"; }

.fa-history:before {
  content: "\F1DA"; }

.fa-hockey-puck:before {
  content: "\F453"; }

.fa-hockey-sticks:before {
  content: "\F454"; }

.fa-home:before {
  content: "\F015"; }

.fa-hooli:before {
  content: "\F427"; }

.fa-hospital:before {
  content: "\F0F8"; }

.fa-hospital-alt:before {
  content: "\F47D"; }

.fa-hospital-symbol:before {
  content: "\F47E"; }

.fa-hotjar:before {
  content: "\F3B1"; }

.fa-hourglass:before {
  content: "\F254"; }

.fa-hourglass-end:before {
  content: "\F253"; }

.fa-hourglass-half:before {
  content: "\F252"; }

.fa-hourglass-start:before {
  content: "\F251"; }

.fa-houzz:before {
  content: "\F27C"; }

.fa-html5:before {
  content: "\F13B"; }

.fa-hubspot:before {
  content: "\F3B2"; }

.fa-i-cursor:before {
  content: "\F246"; }

.fa-id-badge:before {
  content: "\F2C1"; }

.fa-id-card:before {
  content: "\F2C2"; }

.fa-id-card-alt:before {
  content: "\F47F"; }

.fa-image:before {
  content: "\F03E"; }

.fa-images:before {
  content: "\F302"; }

.fa-imdb:before {
  content: "\F2D8"; }

.fa-inbox:before {
  content: "\F01C"; }

.fa-inbox-in:before {
  content: "\F310"; }

.fa-inbox-out:before {
  content: "\F311"; }

.fa-indent:before {
  content: "\F03C"; }

.fa-industry:before {
  content: "\F275"; }

.fa-industry-alt:before {
  content: "\F3B3"; }

.fa-info:before {
  content: "\F129"; }

.fa-info-circle:before {
  content: "\F05A"; }

.fa-info-square:before {
  content: "\F30F"; }

.fa-instagram:before {
  content: "\F16D"; }

.fa-internet-explorer:before {
  content: "\F26B"; }

.fa-inventory:before {
  content: "\F480"; }

.fa-ioxhost:before {
  content: "\F208"; }

.fa-italic:before {
  content: "\F033"; }

.fa-itunes:before {
  content: "\F3B4"; }

.fa-itunes-note:before {
  content: "\F3B5"; }

.fa-jack-o-lantern:before {
  content: "\F30E"; }

.fa-jenkins:before {
  content: "\F3B6"; }

.fa-joget:before {
  content: "\F3B7"; }

.fa-joomla:before {
  content: "\F1AA"; }

.fa-js:before {
  content: "\F3B8"; }

.fa-js-square:before {
  content: "\F3B9"; }

.fa-jsfiddle:before {
  content: "\F1CC"; }

.fa-key:before {
  content: "\F084"; }

.fa-keyboard:before {
  content: "\F11C"; }

.fa-keycdn:before {
  content: "\F3BA"; }

.fa-kickstarter:before {
  content: "\F3BB"; }

.fa-kickstarter-k:before {
  content: "\F3BC"; }

.fa-korvue:before {
  content: "\F42F"; }

.fa-language:before {
  content: "\F1AB"; }

.fa-laptop:before {
  content: "\F109"; }

.fa-laravel:before {
  content: "\F3BD"; }

.fa-lastfm:before {
  content: "\F202"; }

.fa-lastfm-square:before {
  content: "\F203"; }

.fa-leaf:before {
  content: "\F06C"; }

.fa-leanpub:before {
  content: "\F212"; }

.fa-lemon:before {
  content: "\F094"; }

.fa-less:before {
  content: "\F41D"; }

.fa-level-down:before {
  content: "\F149"; }

.fa-level-down-alt:before {
  content: "\F3BE"; }

.fa-level-up:before {
  content: "\F148"; }

.fa-level-up-alt:before {
  content: "\F3BF"; }

.fa-life-ring:before {
  content: "\F1CD"; }

.fa-lightbulb:before {
  content: "\F0EB"; }

.fa-line:before {
  content: "\F3C0"; }

.fa-link:before {
  content: "\F0C1"; }

.fa-linkedin:before {
  content: "\F08C"; }

.fa-linkedin-in:before {
  content: "\F0E1"; }

.fa-linode:before {
  content: "\F2B8"; }

.fa-linux:before {
  content: "\F17C"; }

.fa-lira-sign:before {
  content: "\F195"; }

.fa-list:before {
  content: "\F03A"; }

.fa-list-alt:before {
  content: "\F022"; }

.fa-list-ol:before {
  content: "\F0CB"; }

.fa-list-ul:before {
  content: "\F0CA"; }

.fa-location-arrow:before {
  content: "\F124"; }

.fa-lock:before {
  content: "\F023"; }

.fa-lock-alt:before {
  content: "\F30D"; }

.fa-lock-open:before {
  content: "\F3C1"; }

.fa-lock-open-alt:before {
  content: "\F3C2"; }

.fa-long-arrow-alt-down:before {
  content: "\F309"; }

.fa-long-arrow-alt-left:before {
  content: "\F30A"; }

.fa-long-arrow-alt-right:before {
  content: "\F30B"; }

.fa-long-arrow-alt-up:before {
  content: "\F30C"; }

.fa-long-arrow-down:before {
  content: "\F175"; }

.fa-long-arrow-left:before {
  content: "\F177"; }

.fa-long-arrow-right:before {
  content: "\F178"; }

.fa-long-arrow-up:before {
  content: "\F176"; }

.fa-low-vision:before {
  content: "\F2A8"; }

.fa-luchador:before {
  content: "\F455"; }

.fa-lyft:before {
  content: "\F3C3"; }

.fa-magento:before {
  content: "\F3C4"; }

.fa-magic:before {
  content: "\F0D0"; }

.fa-magnet:before {
  content: "\F076"; }

.fa-male:before {
  content: "\F183"; }

.fa-map:before {
  content: "\F279"; }

.fa-map-marker:before {
  content: "\F041"; }

.fa-map-marker-alt:before {
  content: "\F3C5"; }

.fa-map-pin:before {
  content: "\F276"; }

.fa-map-signs:before {
  content: "\F277"; }

.fa-mars:before {
  content: "\F222"; }

.fa-mars-double:before {
  content: "\F227"; }

.fa-mars-stroke:before {
  content: "\F229"; }

.fa-mars-stroke-h:before {
  content: "\F22B"; }

.fa-mars-stroke-v:before {
  content: "\F22A"; }

.fa-maxcdn:before {
  content: "\F136"; }

.fa-medapps:before {
  content: "\F3C6"; }

.fa-medium:before {
  content: "\F23A"; }

.fa-medium-m:before {
  content: "\F3C7"; }

.fa-medkit:before {
  content: "\F0FA"; }

.fa-medrt:before {
  content: "\F3C8"; }

.fa-meetup:before {
  content: "\F2E0"; }

.fa-meh:before {
  content: "\F11A"; }

.fa-mercury:before {
  content: "\F223"; }

.fa-microchip:before {
  content: "\F2DB"; }

.fa-microphone:before {
  content: "\F130"; }

.fa-microphone-alt:before {
  content: "\F3C9"; }

.fa-microphone-slash:before {
  content: "\F131"; }

.fa-microsoft:before {
  content: "\F3CA"; }

.fa-minus:before {
  content: "\F068"; }

.fa-minus-circle:before {
  content: "\F056"; }

.fa-minus-hexagon:before {
  content: "\F307"; }

.fa-minus-octagon:before {
  content: "\F308"; }

.fa-minus-square:before {
  content: "\F146"; }

.fa-mix:before {
  content: "\F3CB"; }

.fa-mixcloud:before {
  content: "\F289"; }

.fa-mizuni:before {
  content: "\F3CC"; }

.fa-mobile:before {
  content: "\F10B"; }

.fa-mobile-alt:before {
  content: "\F3CD"; }

.fa-mobile-android:before {
  content: "\F3CE"; }

.fa-mobile-android-alt:before {
  content: "\F3CF"; }

.fa-modx:before {
  content: "\F285"; }

.fa-monero:before {
  content: "\F3D0"; }

.fa-money-bill:before {
  content: "\F0D6"; }

.fa-money-bill-alt:before {
  content: "\F3D1"; }

.fa-moon:before {
  content: "\F186"; }

.fa-motorcycle:before {
  content: "\F21C"; }

.fa-mouse-pointer:before {
  content: "\F245"; }

.fa-music:before {
  content: "\F001"; }

.fa-napster:before {
  content: "\F3D2"; }

.fa-neuter:before {
  content: "\F22C"; }

.fa-newspaper:before {
  content: "\F1EA"; }

.fa-nintendo-switch:before {
  content: "\F418"; }

.fa-node:before {
  content: "\F419"; }

.fa-node-js:before {
  content: "\F3D3"; }

.fa-notes-medical:before {
  content: "\F481"; }

.fa-npm:before {
  content: "\F3D4"; }

.fa-ns8:before {
  content: "\F3D5"; }

.fa-nutritionix:before {
  content: "\F3D6"; }

.fa-object-group:before {
  content: "\F247"; }

.fa-object-ungroup:before {
  content: "\F248"; }

.fa-octagon:before {
  content: "\F306"; }

.fa-odnoklassniki:before {
  content: "\F263"; }

.fa-odnoklassniki-square:before {
  content: "\F264"; }

.fa-opencart:before {
  content: "\F23D"; }

.fa-openid:before {
  content: "\F19B"; }

.fa-opera:before {
  content: "\F26A"; }

.fa-optin-monster:before {
  content: "\F23C"; }

.fa-osi:before {
  content: "\F41A"; }

.fa-outdent:before {
  content: "\F03B"; }

.fa-page4:before {
  content: "\F3D7"; }

.fa-pagelines:before {
  content: "\F18C"; }

.fa-paint-brush:before {
  content: "\F1FC"; }

.fa-palfed:before {
  content: "\F3D8"; }

.fa-pallet:before {
  content: "\F482"; }

.fa-pallet-alt:before {
  content: "\F483"; }

.fa-paper-plane:before {
  content: "\F1D8"; }

.fa-paperclip:before {
  content: "\F0C6"; }

.fa-paragraph:before {
  content: "\F1DD"; }

.fa-paste:before {
  content: "\F0EA"; }

.fa-patreon:before {
  content: "\F3D9"; }

.fa-pause:before {
  content: "\F04C"; }

.fa-pause-circle:before {
  content: "\F28B"; }

.fa-paw:before {
  content: "\F1B0"; }

.fa-paypal:before {
  content: "\F1ED"; }

.fa-pen:before {
  content: "\F304"; }

.fa-pen-alt:before {
  content: "\F305"; }

.fa-pen-square:before {
  content: "\F14B"; }

.fa-pencil:before {
  content: "\F040"; }

.fa-pencil-alt:before {
  content: "\F303"; }

.fa-pennant:before {
  content: "\F456"; }

.fa-percent:before {
  content: "\F295"; }

.fa-periscope:before {
  content: "\F3DA"; }

.fa-phabricator:before {
  content: "\F3DB"; }

.fa-phoenix-framework:before {
  content: "\F3DC"; }

.fa-phone:before {
  content: "\F095"; }

.fa-phone-slash:before {
  content: "\F3DD"; }

.fa-phone-square:before {
  content: "\F098"; }

.fa-phone-volume:before {
  content: "\F2A0"; }

.fa-php:before {
  content: "\F457"; }

.fa-pied-piper:before {
  content: "\F2AE"; }

.fa-pied-piper-alt:before {
  content: "\F1A8"; }

.fa-pied-piper-pp:before {
  content: "\F1A7"; }

.fa-pills:before {
  content: "\F484"; }

.fa-pinterest:before {
  content: "\F0D2"; }

.fa-pinterest-p:before {
  content: "\F231"; }

.fa-pinterest-square:before {
  content: "\F0D3"; }

.fa-plane:before {
  content: "\F072"; }

.fa-plane-alt:before {
  content: "\F3DE"; }

.fa-play:before {
  content: "\F04B"; }

.fa-play-circle:before {
  content: "\F144"; }

.fa-playstation:before {
  content: "\F3DF"; }

.fa-plug:before {
  content: "\F1E6"; }

.fa-plus:before {
  content: "\F067"; }

.fa-plus-circle:before {
  content: "\F055"; }

.fa-plus-hexagon:before {
  content: "\F300"; }

.fa-plus-octagon:before {
  content: "\F301"; }

.fa-plus-square:before {
  content: "\F0FE"; }

.fa-podcast:before {
  content: "\F2CE"; }

.fa-poo:before {
  content: "\F2FE"; }

.fa-portrait:before {
  content: "\F3E0"; }

.fa-pound-sign:before {
  content: "\F154"; }

.fa-power-off:before {
  content: "\F011"; }

.fa-prescription-bottle:before {
  content: "\F485"; }

.fa-prescription-bottle-alt:before {
  content: "\F486"; }

.fa-print:before {
  content: "\F02F"; }

.fa-procedures:before {
  content: "\F487"; }

.fa-product-hunt:before {
  content: "\F288"; }

.fa-pushed:before {
  content: "\F3E1"; }

.fa-puzzle-piece:before {
  content: "\F12E"; }

.fa-python:before {
  content: "\F3E2"; }

.fa-qq:before {
  content: "\F1D6"; }

.fa-qrcode:before {
  content: "\F029"; }

.fa-question:before {
  content: "\F128"; }

.fa-question-circle:before {
  content: "\F059"; }

.fa-question-square:before {
  content: "\F2FD"; }

.fa-quidditch:before {
  content: "\F458"; }

.fa-quinscape:before {
  content: "\F459"; }

.fa-quora:before {
  content: "\F2C4"; }

.fa-quote-left:before {
  content: "\F10D"; }

.fa-quote-right:before {
  content: "\F10E"; }

.fa-racquet:before {
  content: "\F45A"; }

.fa-random:before {
  content: "\F074"; }

.fa-ravelry:before {
  content: "\F2D9"; }

.fa-react:before {
  content: "\F41B"; }

.fa-rebel:before {
  content: "\F1D0"; }

.fa-rectangle-landscape:before {
  content: "\F2FA"; }

.fa-rectangle-portrait:before {
  content: "\F2FB"; }

.fa-rectangle-wide:before {
  content: "\F2FC"; }

.fa-recycle:before {
  content: "\F1B8"; }

.fa-red-river:before {
  content: "\F3E3"; }

.fa-reddit:before {
  content: "\F1A1"; }

.fa-reddit-alien:before {
  content: "\F281"; }

.fa-reddit-square:before {
  content: "\F1A2"; }

.fa-redo:before {
  content: "\F01E"; }

.fa-redo-alt:before {
  content: "\F2F9"; }

.fa-registered:before {
  content: "\F25D"; }

.fa-rendact:before {
  content: "\F3E4"; }

.fa-renren:before {
  content: "\F18B"; }

.fa-repeat:before {
  content: "\F363"; }

.fa-repeat-1:before {
  content: "\F365"; }

.fa-repeat-1-alt:before {
  content: "\F366"; }

.fa-repeat-alt:before {
  content: "\F364"; }

.fa-reply:before {
  content: "\F3E5"; }

.fa-reply-all:before {
  content: "\F122"; }

.fa-replyd:before {
  content: "\F3E6"; }

.fa-resolving:before {
  content: "\F3E7"; }

.fa-retweet:before {
  content: "\F079"; }

.fa-retweet-alt:before {
  content: "\F361"; }

.fa-road:before {
  content: "\F018"; }

.fa-rocket:before {
  content: "\F135"; }

.fa-rocketchat:before {
  content: "\F3E8"; }

.fa-rockrms:before {
  content: "\F3E9"; }

.fa-rss:before {
  content: "\F09E"; }

.fa-rss-square:before {
  content: "\F143"; }

.fa-ruble-sign:before {
  content: "\F158"; }

.fa-rupee-sign:before {
  content: "\F156"; }

.fa-safari:before {
  content: "\F267"; }

.fa-sass:before {
  content: "\F41E"; }

.fa-save:before {
  content: "\F0C7"; }

.fa-scanner:before {
  content: "\F488"; }

.fa-scanner-keyboard:before {
  content: "\F489"; }

.fa-scanner-touchscreen:before {
  content: "\F48A"; }

.fa-schlix:before {
  content: "\F3EA"; }

.fa-scribd:before {
  content: "\F28A"; }

.fa-scrubber:before {
  content: "\F2F8"; }

.fa-search:before {
  content: "\F002"; }

.fa-search-minus:before {
  content: "\F010"; }

.fa-search-plus:before {
  content: "\F00E"; }

.fa-searchengin:before {
  content: "\F3EB"; }

.fa-sellcast:before {
  content: "\F2DA"; }

.fa-sellsy:before {
  content: "\F213"; }

.fa-server:before {
  content: "\F233"; }

.fa-servicestack:before {
  content: "\F3EC"; }

.fa-share:before {
  content: "\F064"; }

.fa-share-all:before {
  content: "\F367"; }

.fa-share-alt:before {
  content: "\F1E0"; }

.fa-share-alt-square:before {
  content: "\F1E1"; }

.fa-share-square:before {
  content: "\F14D"; }

.fa-shekel-sign:before {
  content: "\F20B"; }

.fa-shield:before {
  content: "\F132"; }

.fa-shield-alt:before {
  content: "\F3ED"; }

.fa-shield-check:before {
  content: "\F2F7"; }

.fa-ship:before {
  content: "\F21A"; }

.fa-shipping-fast:before {
  content: "\F48B"; }

.fa-shipping-timed:before {
  content: "\F48C"; }

.fa-shirtsinbulk:before {
  content: "\F214"; }

.fa-shopping-bag:before {
  content: "\F290"; }

.fa-shopping-basket:before {
  content: "\F291"; }

.fa-shopping-cart:before {
  content: "\F07A"; }

.fa-shower:before {
  content: "\F2CC"; }

.fa-shuttlecock:before {
  content: "\F45B"; }

.fa-sign-in:before {
  content: "\F090"; }

.fa-sign-in-alt:before {
  content: "\F2F6"; }

.fa-sign-language:before {
  content: "\F2A7"; }

.fa-sign-out:before {
  content: "\F08B"; }

.fa-sign-out-alt:before {
  content: "\F2F5"; }

.fa-signal:before {
  content: "\F012"; }

.fa-simplybuilt:before {
  content: "\F215"; }

.fa-sistrix:before {
  content: "\F3EE"; }

.fa-sitemap:before {
  content: "\F0E8"; }

.fa-skyatlas:before {
  content: "\F216"; }

.fa-skype:before {
  content: "\F17E"; }

.fa-slack:before {
  content: "\F198"; }

.fa-slack-hash:before {
  content: "\F3EF"; }

.fa-sliders-h:before {
  content: "\F1DE"; }

.fa-sliders-h-square:before {
  content: "\F3F0"; }

.fa-sliders-v:before {
  content: "\F3F1"; }

.fa-sliders-v-square:before {
  content: "\F3F2"; }

.fa-slideshare:before {
  content: "\F1E7"; }

.fa-smile:before {
  content: "\F118"; }

.fa-smoking:before {
  content: "\F48D"; }

.fa-snapchat:before {
  content: "\F2AB"; }

.fa-snapchat-ghost:before {
  content: "\F2AC"; }

.fa-snapchat-square:before {
  content: "\F2AD"; }

.fa-snowflake:before {
  content: "\F2DC"; }

.fa-sort:before {
  content: "\F0DC"; }

.fa-sort-alpha-down:before {
  content: "\F15D"; }

.fa-sort-alpha-up:before {
  content: "\F15E"; }

.fa-sort-amount-down:before {
  content: "\F160"; }

.fa-sort-amount-up:before {
  content: "\F161"; }

.fa-sort-down:before {
  content: "\F0DD"; }

.fa-sort-numeric-down:before {
  content: "\F162"; }

.fa-sort-numeric-up:before {
  content: "\F163"; }

.fa-sort-up:before {
  content: "\F0DE"; }

.fa-soundcloud:before {
  content: "\F1BE"; }

.fa-space-shuttle:before {
  content: "\F197"; }

.fa-spade:before {
  content: "\F2F4"; }

.fa-speakap:before {
  content: "\F3F3"; }

.fa-spinner:before {
  content: "\F110"; }

.fa-spinner-third:before {
  content: "\F3F4"; }

.fa-spotify:before {
  content: "\F1BC"; }

.fa-square:before {
  content: "\F0C8"; }

.fa-square-full:before {
  content: "\F45C"; }

.fa-stack-exchange:before {
  content: "\F18D"; }

.fa-stack-overflow:before {
  content: "\F16C"; }

.fa-star:before {
  content: "\F005"; }

.fa-star-exclamation:before {
  content: "\F2F3"; }

.fa-star-half:before {
  content: "\F089"; }

.fa-staylinked:before {
  content: "\F3F5"; }

.fa-steam:before {
  content: "\F1B6"; }

.fa-steam-square:before {
  content: "\F1B7"; }

.fa-steam-symbol:before {
  content: "\F3F6"; }

.fa-step-backward:before {
  content: "\F048"; }

.fa-step-forward:before {
  content: "\F051"; }

.fa-stethoscope:before {
  content: "\F0F1"; }

.fa-sticker-mule:before {
  content: "\F3F7"; }

.fa-sticky-note:before {
  content: "\F249"; }

.fa-stop:before {
  content: "\F04D"; }

.fa-stop-circle:before {
  content: "\F28D"; }

.fa-stopwatch:before {
  content: "\F2F2"; }

.fa-strava:before {
  content: "\F428"; }

.fa-street-view:before {
  content: "\F21D"; }

.fa-strikethrough:before {
  content: "\F0CC"; }

.fa-stripe:before {
  content: "\F429"; }

.fa-stripe-s:before {
  content: "\F42A"; }

.fa-studiovinari:before {
  content: "\F3F8"; }

.fa-stumbleupon:before {
  content: "\F1A4"; }

.fa-stumbleupon-circle:before {
  content: "\F1A3"; }

.fa-subscript:before {
  content: "\F12C"; }

.fa-subway:before {
  content: "\F239"; }

.fa-suitcase:before {
  content: "\F0F2"; }

.fa-sun:before {
  content: "\F185"; }

.fa-superpowers:before {
  content: "\F2DD"; }

.fa-superscript:before {
  content: "\F12B"; }

.fa-supple:before {
  content: "\F3F9"; }

.fa-sync:before {
  content: "\F021"; }

.fa-sync-alt:before {
  content: "\F2F1"; }

.fa-syringe:before {
  content: "\F48E"; }

.fa-table:before {
  content: "\F0CE"; }

.fa-table-tennis:before {
  content: "\F45D"; }

.fa-tablet:before {
  content: "\F10A"; }

.fa-tablet-alt:before {
  content: "\F3FA"; }

.fa-tablet-android:before {
  content: "\F3FB"; }

.fa-tablet-android-alt:before {
  content: "\F3FC"; }

.fa-tablet-rugged:before {
  content: "\F48F"; }

.fa-tablets:before {
  content: "\F490"; }

.fa-tachometer:before {
  content: "\F0E4"; }

.fa-tachometer-alt:before {
  content: "\F3FD"; }

.fa-tag:before {
  content: "\F02B"; }

.fa-tags:before {
  content: "\F02C"; }

.fa-tasks:before {
  content: "\F0AE"; }

.fa-taxi:before {
  content: "\F1BA"; }

.fa-telegram:before {
  content: "\F2C6"; }

.fa-telegram-plane:before {
  content: "\F3FE"; }

.fa-tencent-weibo:before {
  content: "\F1D5"; }

.fa-tennis-ball:before {
  content: "\F45E"; }

.fa-terminal:before {
  content: "\F120"; }

.fa-text-height:before {
  content: "\F034"; }

.fa-text-width:before {
  content: "\F035"; }

.fa-th:before {
  content: "\F00A"; }

.fa-th-large:before {
  content: "\F009"; }

.fa-th-list:before {
  content: "\F00B"; }

.fa-themeisle:before {
  content: "\F2B2"; }

.fa-thermometer:before {
  content: "\F491"; }

.fa-thermometer-empty:before {
  content: "\F2CB"; }

.fa-thermometer-full:before {
  content: "\F2C7"; }

.fa-thermometer-half:before {
  content: "\F2C9"; }

.fa-thermometer-quarter:before {
  content: "\F2CA"; }

.fa-thermometer-three-quarters:before {
  content: "\F2C8"; }

.fa-thumbs-down:before {
  content: "\F165"; }

.fa-thumbs-up:before {
  content: "\F164"; }

.fa-thumbtack:before {
  content: "\F08D"; }

.fa-ticket:before {
  content: "\F145"; }

.fa-ticket-alt:before {
  content: "\F3FF"; }

.fa-times:before {
  content: "\F00D"; }

.fa-times-circle:before {
  content: "\F057"; }

.fa-times-hexagon:before {
  content: "\F2EE"; }

.fa-times-octagon:before {
  content: "\F2F0"; }

.fa-times-square:before {
  content: "\F2D3"; }

.fa-tint:before {
  content: "\F043"; }

.fa-toggle-off:before {
  content: "\F204"; }

.fa-toggle-on:before {
  content: "\F205"; }

.fa-trademark:before {
  content: "\F25C"; }

.fa-train:before {
  content: "\F238"; }

.fa-transgender:before {
  content: "\F224"; }

.fa-transgender-alt:before {
  content: "\F225"; }

.fa-trash:before {
  content: "\F1F8"; }

.fa-trash-alt:before {
  content: "\F2ED"; }

.fa-tree:before {
  content: "\F1BB"; }

.fa-tree-alt:before {
  content: "\F400"; }

.fa-trello:before {
  content: "\F181"; }

.fa-triangle:before {
  content: "\F2EC"; }

.fa-tripadvisor:before {
  content: "\F262"; }

.fa-trophy:before {
  content: "\F091"; }

.fa-trophy-alt:before {
  content: "\F2EB"; }

.fa-truck:before {
  content: "\F0D1"; }

.fa-tty:before {
  content: "\F1E4"; }

.fa-tumblr:before {
  content: "\F173"; }

.fa-tumblr-square:before {
  content: "\F174"; }

.fa-tv:before {
  content: "\F26C"; }

.fa-tv-retro:before {
  content: "\F401"; }

.fa-twitch:before {
  content: "\F1E8"; }

.fa-twitter:before {
  content: "\F099"; }

.fa-twitter-square:before {
  content: "\F081"; }

.fa-typo3:before {
  content: "\F42B"; }

.fa-uber:before {
  content: "\F402"; }

.fa-uikit:before {
  content: "\F403"; }

.fa-umbrella:before {
  content: "\F0E9"; }

.fa-underline:before {
  content: "\F0CD"; }

.fa-undo:before {
  content: "\F0E2"; }

.fa-undo-alt:before {
  content: "\F2EA"; }

.fa-uniregistry:before {
  content: "\F404"; }

.fa-universal-access:before {
  content: "\F29A"; }

.fa-university:before {
  content: "\F19C"; }

.fa-unlink:before {
  content: "\F127"; }

.fa-unlock:before {
  content: "\F09C"; }

.fa-unlock-alt:before {
  content: "\F13E"; }

.fa-untappd:before {
  content: "\F405"; }

.fa-upload:before {
  content: "\F093"; }

.fa-usb:before {
  content: "\F287"; }

.fa-usd-circle:before {
  content: "\F2E8"; }

.fa-usd-square:before {
  content: "\F2E9"; }

.fa-user:before {
  content: "\F007"; }

.fa-user-alt:before {
  content: "\F406"; }

.fa-user-circle:before {
  content: "\F2BD"; }

.fa-user-md:before {
  content: "\F0F0"; }

.fa-user-plus:before {
  content: "\F234"; }

.fa-user-secret:before {
  content: "\F21B"; }

.fa-user-times:before {
  content: "\F235"; }

.fa-users:before {
  content: "\F0C0"; }

.fa-ussunnah:before {
  content: "\F407"; }

.fa-utensil-fork:before {
  content: "\F2E3"; }

.fa-utensil-knife:before {
  content: "\F2E4"; }

.fa-utensil-spoon:before {
  content: "\F2E5"; }

.fa-utensils:before {
  content: "\F2E7"; }

.fa-utensils-alt:before {
  content: "\F2E6"; }

.fa-vaadin:before {
  content: "\F408"; }

.fa-venus:before {
  content: "\F221"; }

.fa-venus-double:before {
  content: "\F226"; }

.fa-venus-mars:before {
  content: "\F228"; }

.fa-viacoin:before {
  content: "\F237"; }

.fa-viadeo:before {
  content: "\F2A9"; }

.fa-viadeo-square:before {
  content: "\F2AA"; }

.fa-vial:before {
  content: "\F492"; }

.fa-vials:before {
  content: "\F493"; }

.fa-viber:before {
  content: "\F409"; }

.fa-video:before {
  content: "\F03D"; }

.fa-vimeo:before {
  content: "\F40A"; }

.fa-vimeo-square:before {
  content: "\F194"; }

.fa-vimeo-v:before {
  content: "\F27D"; }

.fa-vine:before {
  content: "\F1CA"; }

.fa-vk:before {
  content: "\F189"; }

.fa-vnv:before {
  content: "\F40B"; }

.fa-volleyball-ball:before {
  content: "\F45F"; }

.fa-volume-down:before {
  content: "\F027"; }

.fa-volume-mute:before {
  content: "\F2E2"; }

.fa-volume-off:before {
  content: "\F026"; }

.fa-volume-up:before {
  content: "\F028"; }

.fa-vuejs:before {
  content: "\F41F"; }

.fa-warehouse:before {
  content: "\F494"; }

.fa-warehouse-alt:before {
  content: "\F495"; }

.fa-watch:before {
  content: "\F2E1"; }

.fa-weibo:before {
  content: "\F18A"; }

.fa-weight:before {
  content: "\F496"; }

.fa-weixin:before {
  content: "\F1D7"; }

.fa-whatsapp:before {
  content: "\F232"; }

.fa-whatsapp-square:before {
  content: "\F40C"; }

.fa-wheelchair:before {
  content: "\F193"; }

.fa-whistle:before {
  content: "\F460"; }

.fa-whmcs:before {
  content: "\F40D"; }

.fa-wifi:before {
  content: "\F1EB"; }

.fa-wikipedia-w:before {
  content: "\F266"; }

.fa-window:before {
  content: "\F40E"; }

.fa-window-alt:before {
  content: "\F40F"; }

.fa-window-close:before {
  content: "\F410"; }

.fa-window-maximize:before {
  content: "\F2D0"; }

.fa-window-minimize:before {
  content: "\F2D1"; }

.fa-window-restore:before {
  content: "\F2D2"; }

.fa-windows:before {
  content: "\F17A"; }

.fa-won-sign:before {
  content: "\F159"; }

.fa-wordpress:before {
  content: "\F19A"; }

.fa-wordpress-simple:before {
  content: "\F411"; }

.fa-wpbeginner:before {
  content: "\F297"; }

.fa-wpexplorer:before {
  content: "\F2DE"; }

.fa-wpforms:before {
  content: "\F298"; }

.fa-wrench:before {
  content: "\F0AD"; }

.fa-x-ray:before {
  content: "\F497"; }

.fa-xbox:before {
  content: "\F412"; }

.fa-xing:before {
  content: "\F168"; }

.fa-xing-square:before {
  content: "\F169"; }

.fa-y-combinator:before {
  content: "\F23B"; }

.fa-yahoo:before {
  content: "\F19E"; }

.fa-yandex:before {
  content: "\F413"; }

.fa-yandex-international:before {
  content: "\F414"; }

.fa-yelp:before {
  content: "\F1E9"; }

.fa-yen-sign:before {
  content: "\F157"; }

.fa-yoast:before {
  content: "\F2B1"; }

.fa-youtube:before {
  content: "\F167"; }

.fa-youtube-square:before {
  content: "\F431"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/fa-brands-400.45813ba6.eot);
  src: url(/static/media/fa-brands-400.45813ba6.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-brands-400.65b96d56.woff2) format("woff2"), url(/static/media/fa-brands-400.bcfcad6c.woff) format("woff"), url(/static/media/fa-brands-400.5c5f6302.ttf) format("truetype"), url(/static/media/fa-brands-400.2120ba9b.svg#fontawesome) format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/fa-light-300.ef489bc9.eot);
  src: url(/static/media/fa-light-300.ef489bc9.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-light-300.25ea1f58.woff2) format("woff2"), url(/static/media/fa-light-300.1420ebcb.woff) format("woff"), url(/static/media/fa-light-300.3645a24c.ttf) format("truetype"), url(/static/media/fa-light-300.4cf66f81.svg#fontawesome) format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/fa-regular-400.16f04cc3.eot);
  src: url(/static/media/fa-regular-400.16f04cc3.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-regular-400.1b846087.woff2) format("woff2"), url(/static/media/fa-regular-400.47ffb280.woff) format("woff"), url(/static/media/fa-regular-400.bbd1e7a4.ttf) format("truetype"), url(/static/media/fa-regular-400.39661f2a.svg#fontawesome) format("svg"); }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/fa-solid-900.881dc7cf.eot);
  src: url(/static/media/fa-solid-900.881dc7cf.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-solid-900.5229fc2d.woff2) format("woff2"), url(/static/media/fa-solid-900.bc595bb4.woff) format("woff"), url(/static/media/fa-solid-900.bacbaa42.ttf) format("truetype"), url(/static/media/fa-solid-900.1492b1ff.svg#fontawesome) format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #212d69;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ff3333;
  --orange: #F47920;
  --yellow: #ffc107;
  --green: #477625;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #477625;
  --secondary: #F47920;
  --success: #477625;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #ff3333;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(40, 40, 40, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #477625;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #243c13;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.5rem; }

h3, .h3 {
  font-size: 1.17rem; }

h4, .h4 {
  font-size: 1rem; }

h5, .h5 {
  font-size: 0.83rem; }

h6, .h6 {
  font-size: 0.67rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(40, 40, 40, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-order: -1;
          order: -1; }

.order-last {
  -webkit-order: 13;
          order: 13; }

.order-0 {
  -webkit-order: 0;
          order: 0; }

.order-1 {
  -webkit-order: 1;
          order: 1; }

.order-2 {
  -webkit-order: 2;
          order: 2; }

.order-3 {
  -webkit-order: 3;
          order: 3; }

.order-4 {
  -webkit-order: 4;
          order: 4; }

.order-5 {
  -webkit-order: 5;
          order: 5; }

.order-6 {
  -webkit-order: 6;
          order: 6; }

.order-7 {
  -webkit-order: 7;
          order: 7; }

.order-8 {
  -webkit-order: 8;
          order: 8; }

.order-9 {
  -webkit-order: 9;
          order: 9; }

.order-10 {
  -webkit-order: 10;
          order: 10; }

.order-11 {
  -webkit-order: 11;
          order: 11; }

.order-12 {
  -webkit-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(40, 40, 40, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(40, 40, 40, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cbd9c2; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9fb88e; }

.table-hover .table-primary:hover {
  background-color: #becfb2; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #becfb2; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fcd9c1; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f9b98b; }

.table-hover .table-secondary:hover {
  background-color: #fbcaa9; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #fbcaa9; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cbd9c2; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9fb88e; }

.table-hover .table-success:hover {
  background-color: #becfb2; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #becfb2; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffc6c6; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ff9595; }

.table-hover .table-danger:hover {
  background-color: #ffadad; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffadad; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(40, 40, 40, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(27, 27, 27, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(27, 27, 27, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #84c952;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #477625; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(71, 118, 37, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #477625;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23477625' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #477625;
    box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #477625;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23477625' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #477625;
    box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #477625; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #477625; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #477625; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5e9d31;
  background-color: #5e9d31; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #477625; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #477625; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #477625;
  box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff3333; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(255, 51, 51, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ff3333;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff3333' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff3333' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #ff3333;
    box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ff3333;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff3333' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff3333' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #ff3333;
    box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff3333; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff3333; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #ff3333; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff6666;
  background-color: #ff6666; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff3333; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff3333; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ff3333;
  box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.25); }

.form-inline {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #477625;
  border-color: #477625; }
  .btn-primary:hover {
    color: #fff;
    background-color: #35591c;
    border-color: #304f19; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 139, 70, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #477625;
    border-color: #477625; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #304f19;
    border-color: #2a4516; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(99, 139, 70, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #F47920;
  border-color: #F47920; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #e3660b;
    border-color: #d6600b; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 141, 65, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #F47920;
    border-color: #F47920; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #d6600b;
    border-color: #ca5b0a; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 141, 65, 0.5); }

.btn-success {
  color: #fff;
  background-color: #477625;
  border-color: #477625; }
  .btn-success:hover {
    color: #fff;
    background-color: #35591c;
    border-color: #304f19; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 139, 70, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #477625;
    border-color: #477625; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #304f19;
    border-color: #2a4516; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(99, 139, 70, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #ff3333;
  border-color: #ff3333; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ff0d0d;
    border-color: red; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 82, 82, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #ff3333;
    border-color: #ff3333; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: red;
    border-color: #f20000; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 82, 82, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #477625;
  border-color: #477625; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #477625;
    border-color: #477625; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #477625;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #477625;
    border-color: #477625; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.5); }

.btn-outline-secondary {
  color: #F47920;
  border-color: #F47920; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #F47920;
    border-color: #F47920; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 121, 32, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #F47920;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #F47920;
    border-color: #F47920; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 121, 32, 0.5); }

.btn-outline-success {
  color: #477625;
  border-color: #477625; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #477625;
    border-color: #477625; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #477625;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #477625;
    border-color: #477625; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #ff3333;
  border-color: #ff3333; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ff3333;
    border-color: #ff3333; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ff3333;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff3333;
    border-color: #ff3333; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #477625;
  text-decoration: none; }
  .btn-link:hover {
    color: #243c13;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(40, 40, 40, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #477625; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-flex;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #477625;
    background-color: #477625; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #84c952; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #9fd579;
    border-color: #9fd579; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #477625;
  background-color: #477625; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(71, 118, 37, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(71, 118, 37, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(71, 118, 37, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(71, 118, 37, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #84c952;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #84c952;
    box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #477625;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #9fd579; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #477625;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #9fd579; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #477625;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #9fd579; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #477625; }

.nav-fill .nav-item {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
            flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(40, 40, 40, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(40, 40, 40, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(40, 40, 40, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(40, 40, 40, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(40, 40, 40, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(40, 40, 40, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(40, 40, 40, 0.5);
  border-color: rgba(40, 40, 40, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(40, 40, 40, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(40, 40, 40, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(40, 40, 40, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(40, 40, 40, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(40, 40, 40, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(40, 40, 40, 0.03);
  border-bottom: 1px solid rgba(40, 40, 40, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(40, 40, 40, 0.03);
  border-top: 1px solid rgba(40, 40, 40, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: -webkit-flex;
        display: flex;
        -webkit-flex: 1 0;
                flex: 1 0;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap; }
      .card-group > .card {
        -webkit-flex: 1 0;
                flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -webkit-flex;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #477625;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #243c13;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #477625;
  border-color: #477625; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #477625; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #304f19; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #F47920; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #d6600b; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 121, 32, 0.5); }

.badge-success {
  color: #fff;
  background-color: #477625; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #304f19; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(71, 118, 37, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #ff3333; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: red; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #385126;
  background-color: #dae4d3;
  border-color: #cbd9c2; }
  .alert-primary hr {
    border-top-color: #becfb2; }
  .alert-primary .alert-link {
    color: #202e16; }

.alert-secondary {
  color: #925224;
  background-color: #fde4d2;
  border-color: #fcd9c1; }
  .alert-secondary hr {
    border-top-color: #fbcaa9; }
  .alert-secondary .alert-link {
    color: #693b1a; }

.alert-success {
  color: #385126;
  background-color: #dae4d3;
  border-color: #cbd9c2; }
  .alert-success hr {
    border-top-color: #becfb2; }
  .alert-success .alert-link {
    color: #202e16; }

.alert-info {
  color: #1f6773;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #14434b; }

.alert-warning {
  color: #987817;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #6c5510; }

.alert-danger {
  color: #982e2e;
  background-color: #ffd6d6;
  border-color: #ffc6c6; }
  .alert-danger hr {
    border-top-color: #ffadad; }
  .alert-danger .alert-link {
    color: #712222; }

.alert-light {
  color: #949595;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #7a7c7c; }

.alert-dark {
  color: #2e3134;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #161819; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-flex;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #477625;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start; }

.media-body {
  -webkit-flex: 1 1;
          flex: 1 1; }

.list-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(40, 40, 40, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #477625;
    border-color: #477625; }

.list-group-horizontal {
  -webkit-flex-direction: row;
          flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #385126;
  background-color: #cbd9c2; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #385126;
    background-color: #becfb2; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #385126;
    border-color: #385126; }

.list-group-item-secondary {
  color: #925224;
  background-color: #fcd9c1; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #925224;
    background-color: #fbcaa9; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #925224;
    border-color: #925224; }

.list-group-item-success {
  color: #385126;
  background-color: #cbd9c2; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #385126;
    background-color: #becfb2; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #385126;
    border-color: #385126; }

.list-group-item-info {
  color: #1f6773;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #1f6773;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #1f6773;
    border-color: #1f6773; }

.list-group-item-warning {
  color: #987817;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #987817;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #987817;
    border-color: #987817; }

.list-group-item-danger {
  color: #982e2e;
  background-color: #ffc6c6; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #982e2e;
    background-color: #ffadad; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #982e2e;
    border-color: #982e2e; }

.list-group-item-light {
  color: #949595;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #949595;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #949595;
    border-color: #949595; }

.list-group-item-dark {
  color: #2e3134;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #2e3134;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #2e3134;
    border-color: #2e3134; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #282828;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #282828;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(40, 40, 40, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }

.modal-dialog-scrollable {
  display: -webkit-flex;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #282828; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #282828; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #282828; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #282828; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #282828; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #282828;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(40, 40, 40, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(40, 40, 40, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(40, 40, 40, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(40, 40, 40, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #477625 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #304f19 !important; }

.bg-secondary {
  background-color: #F47920 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #d6600b !important; }

.bg-success {
  background-color: #477625 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #304f19 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #ff3333 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: red !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #477625 !important; }

.border-secondary {
  border-color: #F47920 !important; }

.border-success {
  border-color: #477625 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #ff3333 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-flex !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-flex-grow: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important; }

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-justify-content: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important; }

.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important; }

.align-items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-align-items: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important; }

.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important; }

.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important; }

.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important; }

.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important; }

.align-content-stretch {
  -webkit-align-content: stretch !important;
          align-content: stretch !important; }

.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important; }

.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important; }

.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important; }

.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important; }

.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important; }

.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-sm-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-sm-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-md-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-md-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-lg-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-lg-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-xl-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-xl-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(40, 40, 40, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(40, 40, 40, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(40, 40, 40, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #477625 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #243c13 !important; }

.text-secondary {
  color: #F47920 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #be5509 !important; }

.text-success {
  color: #477625 !important; }

a.text-success:hover, a.text-success:focus {
  color: #243c13 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #ff3333 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #e60000 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(40, 40, 40, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #282828; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

@font-face {
  font-family: 'Lato-Bold';
  src: url(/static/media/Lato-Bold.eb953203.ttf) format("truetype"); }

@font-face {
  font-family: 'Lato-Regular';
  src: url(/static/media/Lato-Regular.3b9b9903.ttf) format("truetype"); }

@font-face {
  font-family: 'Merriweather-Italic';
  src: url(/static/media/Merriweather-Italic.2b00cb94.otf) format("OpenType"); }

@font-face {
  font-family: 'librefranklin-bold';
  src: url(/static/media/librefranklin-bold.242d8322.ttf) format("truetype"); }

@font-face {
  font-family: 'librefranklin-semibold';
  src: url(/static/media/librefranklin-semibold.cfb7b3cd.ttf) format("truetype"); }

@font-face {
  font-family: 'librefranklin-extrabold';
  src: url(/static/media/librefranklin-extrabold.a4ee16aa.ttf) format("truetype"); }

@font-face {
  font-family: 'librefranklin-regular';
  src: url(/static/media/librefranklin-regular.30ee6085.ttf) format("truetype"); }

@font-face {
  font-family: 'librefranklin-light';
  src: url(/static/media/librefranklin-light.3a48672c.ttf) format("truetype"); }

@font-face {
  font-family: 'librefranklin-lightitalic';
  src: url(/static/media/librefranklin-lightitalic.ee1921a8.ttf) format("truetype"); }

@font-face {
  font-family: 'librefranklin-semibolditalic';
  src: url(/static/media/librefranklin-semibolditalic.f8f721a7.ttf) format("truetype"); }

@font-face {
  font-family: 'librefranklin-medium';
  src: url(/static/media/librefranklin-medium.89fadac2.ttf) format("truetype"); }

@font-face {
  font-family: 'BebasNeue-Bold';
  src: url(/static/media/BebasNeue-Bold.95c15f5d.otf) format("OpenType"); }

.txt-oversized {
  font-size: 60px; }

.txt-header {
  font-size: 40px; }
  .txt-header-2 {
    font-size: 30px; }

.txt-large {
  font-size: 18px; }

.txt-xlarge {
  font-size: 20px; }

.txt-xxlarge {
  font-size: 25px; }

.txt-medium {
  font-size: 14px; }

.txt-small {
  font-size: 12px; }

.txt-error {
  color: #ff3333; }

.txt-success {
  color: #477625; }

.txt-warning {
  color: #F47920; }

.txt-gray {
  color: #747474; }
  .txt-gray-med {
    color: #c2c2c2; }
  .txt-gray-light {
    color: #f3f3f3; }

.txt-white {
  color: #fff; }

.txt-link {
  color: #356077; }

.txt-dark-link {
  color: #284356; }

.txt-bold {
  font-weight: bold; }
  .txt-bold-black {
    font-weight: 800; }

.txt-regular {
  font-weight: normal; }

.txt-uppercase {
  text-transform: uppercase; }

.txt-capitalize {
  text-transform: capitalize; }

.txt-underlined {
  text-decoration: underline; }

.txt-strike {
  text-decoration: line-through; }
  .txt-strike-element {
    position: relative; }
    .txt-strike-element::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #c2c2c2;
      margin: auto 0; }

.txt-lineheight-1 {
  line-height: 1; }

@media (max-width: 991px) {
  .txt-oversized {
    font-size: 40px; }
  .txt-header {
    font-size: 30px; }
    .txt-header-2 {
      font-size: 25px; }
  .txt-large {
    font-size: 14px; }
  .txt-xlarge {
    font-size: 18px; }
  .txt-xxlarge {
    font-size: 20px; }
  .txt-medium {
    font-size: 12px; } }

@media (max-width: 767px) {
  .txt-oversized {
    font-size: 30px; }
  .txt-header {
    font-size: 25px; }
    .txt-header-2 {
      font-size: 20px; }
  .txt-large {
    font-size: 14px; }
  .txt-xlarge {
    font-size: 14px; }
  .txt-xxlarge {
    font-size: 18px; } }

.flex {
  display: -webkit-flex;
  display: flex; }
  .flex .flex-1 {
    -webkit-flex: 1 1;
            flex: 1 1; }
  .flex.align-center {
    -webkit-align-items: center;
            align-items: center; }
  .flex.justify-center {
    -webkit-justify-content: center;
            justify-content: center; }
  .flex.flex-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
  .flex.direction-column {
    -webkit-flex-direction: column;
            flex-direction: column; }

.flex-flow-column {
  -webkit-flex-flow: column;
          flex-flow: column; }

.align-items-center {
  -webkit-align-items: center;
          align-items: center; }

.justify-content-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.align-all-center {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

@media (max-width: 991px) {
  .flex-rm-md {
    display: block; } }

@media (max-width: 767px) {
  .flex-rm-xs {
    display: block; } }

.float-in-up-down {
  -webkit-animation-name: floatInUpDown;
          animation-name: floatInUpDown;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  opacity: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%); }

.fade-in {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  opacity: 0; }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0; }

.fade-in-down {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0; }

.pulse {
  -webkit-animation-name: pulse;
          animation-name: pulse;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s; }
  .pulse-inwards {
    -webkit-animation-name: pulse-inwards;
            animation-name: pulse-inwards;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s; }
  .pulse-heart {
    -webkit-animation-name: pulseHeart;
            animation-name: pulseHeart;
    -webkit-animation-duration: 5s;
            animation-duration: 5s; }

.infinite-animation {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.scale-in {
  -webkit-animation-name: scaleIn;
          animation-name: scaleIn;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-duration: linear;
          animation-duration: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform: scale(0);
          transform: scale(0); }

@-webkit-keyframes scaleIn {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  25% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  35% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes scaleIn {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  25% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  35% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(20%);
            transform: translateY(20%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(20%);
            transform: translateY(20%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes growIn {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes growIn {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes float {
  from,
  to {
    -webkit-transform: translateY(10%);
            transform: translateY(10%); }
  25% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  50% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%); }
  75% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@keyframes float {
  from,
  to {
    -webkit-transform: translateY(10%);
            transform: translateY(10%); }
  25% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  50% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%); }
  75% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes floatInUpDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%); }
  50% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes floatInUpDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%); }
  50% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes floatInDown {
  from {
    top: -10px; }
  to {
    top: 0; } }

@keyframes floatInDown {
  from {
    top: -10px; }
  to {
    top: 0; } }

@-webkit-keyframes pulse {
  from,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); } }

@keyframes pulse {
  from,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); } }

@-webkit-keyframes pulse-inwards {
  from,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5); } }

@keyframes pulse-inwards {
  from,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5); } }

@-webkit-keyframes pulseHeart {
  from,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); }
  4% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  8% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  12% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  16%,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes pulseHeart {
  from,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); }
  4% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  8% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  12% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  16%,
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes jump {
  from,
  to {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.5); } }

@keyframes jump {
  from,
  to {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.5); } }

.ellipsis-loader {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .ellipsis-loader div {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: #fff;
    -webkit-animation-name: ellipsisLoading;
            animation-name: ellipsisLoading;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    margin: 0 10px; }
  .ellipsis-loader.dot-black div {
    background: #ccc; }
  .ellipsis-loader.el-black {
    background: #282828; }

@-webkit-keyframes ellipsisLoading {
  from,
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

@keyframes ellipsisLoading {
  from,
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

.fullwidth-product-card {
  background-color: #c2c2c2;
  padding: 25px;
  position: relative; }
  .fullwidth-product-card .main-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }
    .fullwidth-product-card .main-image img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
      object-position: center; }
  .fullwidth-product-card .transition-overlay-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    max-width: 100%;
    overflow: hidden; }
    .fullwidth-product-card .transition-overlay-image img {
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
      object-position: center; }
  .fullwidth-product-card .product-details {
    background-color: #fff;
    padding: 30px;
    margin-top: 200px;
    max-width: 60%;
    position: relative; }
  .fullwidth-product-card.in-transition .transition-overlay-image {
    opacity: 1;
    transition: max-width 0.6s ease-out;
    max-width: 0%; }
    .fullwidth-product-card.in-transition .transition-overlay-image.prev {
      left: auto;
      left: initial;
      right: 0; }
      .fullwidth-product-card.in-transition .transition-overlay-image.prev img {
        left: auto;
        left: initial;
        right: 0; }
  @media (max-width: 991px) {
    .fullwidth-product-card .product-details {
      max-width: 100%; } }
  @media (max-width: 767px) {
    .fullwidth-product-card {
      padding: 15px 5px; }
      .fullwidth-product-card .product-details {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        background: none;
        margin-top: 100px;
        padding: 0; }
        .fullwidth-product-card .product-details .details-container {
          background: #fff;
          padding: 10px; }
        .fullwidth-product-card .product-details .btn-container {
          text-align: center; } }

.related-products-slider {
  height: 600px;
  position: relative; }
  .related-products-slider .product-details {
    position: absolute;
    left: 30px;
    bottom: 30px;
    max-width: 60%;
    width: 100%; }
  @media (max-width: 991px) {
    .related-products-slider {
      height: 500px; }
      .related-products-slider .product-details {
        max-width: calc(100% - 60px); } }
  @media (max-width: 767px) {
    .related-products-slider {
      height: 300px; }
      .related-products-slider .product-details {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        background: none;
        margin-top: 100px;
        padding: 0;
        max-width: calc(100% - 10px);
        left: 5px;
        bottom: 15px; }
        .related-products-slider .product-details .details-container {
          background: #fff;
          padding: 10px; }
        .related-products-slider .product-details .btn-container {
          text-align: center; }
        .related-products-slider .product-details .list-control {
          margin-left: 0;
          -webkit-justify-content: center;
                  justify-content: center;
          position: absolute;
          bottom: 0;
          left: 0;
          -webkit-transform: translateY(200%);
                  transform: translateY(200%);
          width: 100%; }
          .related-products-slider .product-details .list-control .btn.margin-left-10 {
            margin-left: 20px; } }

@media (max-width: 767px) {
  .related-products-slider-container {
    margin-bottom: 100px !important; } }

#comingSoon {
  background: #000;
  min-height: 100vh;
  position: relative;
  overflow: auto; }
  #comingSoon .img-background-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 0;
    top: 0; }
    #comingSoon .img-background-overlay .img-overlay {
      position: absolute;
      width: 110%;
      height: 110%;
      object-fit: cover;
      object-position: center;
      -webkit-transform: translate(-2.5%, -2.5%);
              transform: translate(-2.5%, -2.5%);
      transition: 1s; }
      #comingSoon .img-background-overlay .img-overlay.blurred {
        opacity: 0.6;
        -webkit-filter: blur(22px);
                filter: blur(22px); }
  #comingSoon img.logo {
    width: 200px; }
  #comingSoon .content {
    position: relative;
    color: #fff;
    opacity: 0;
    transition: 0.5s; }
    #comingSoon .content .big-txt {
      font-size: 55px;
      margin-bottom: 20px; }
      #comingSoon .content .big-txt sup {
        font-size: 0.5em; }
    #comingSoon .content .txt-content {
      font-size: 20px; }
    #comingSoon .content .btn {
      background: #000;
      padding: 20px 30px;
      margin-top: 70px;
      max-width: 200px;
      width: 100%;
      font-weight: bold; }
  #comingSoon .subscription-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: 0s;
    transition-delay: 0.3s;
    background: rgba(0, 0, 0, 0.5); }
    #comingSoon .subscription-modal .box {
      background: #000;
      color: #fff;
      position: relative;
      -webkit-transform: translateY(10%);
              transform: translateY(10%);
      opacity: 0;
      transition: 0.3s; }
      #comingSoon .subscription-modal .box > :first-child {
        border-right: 1px solid #666; }
      #comingSoon .subscription-modal .box .btn {
        background: #000;
        border: 1px solid #fff;
        max-width: 200px;
        width: 100%;
        font-weight: bold; }
        #comingSoon .subscription-modal .box .btn:hover {
          color: inherit; }
        #comingSoon .subscription-modal .box .btn.soc-btn {
          max-width: none;
          max-width: initial;
          width: 300px;
          border: none;
          font-weight: 500;
          display: block; }
          #comingSoon .subscription-modal .box .btn.soc-btn.fb {
            background: #4267b2; }
          #comingSoon .subscription-modal .box .btn.soc-btn.google {
            background: #dc4e41; }
          #comingSoon .subscription-modal .box .btn.soc-btn.twitter {
            background: #5baaf4; }
      #comingSoon .subscription-modal .box .dismiss-btn {
        border: none;
        position: absolute;
        right: 10px;
        top: 10px;
        width: auto; }
      #comingSoon .subscription-modal .box .success-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        color: #fff;
        background-color: #000;
        overflow: hidden;
        -webkit-transform: scale(0);
                transform: scale(0);
        transition: .3s; }
        #comingSoon .subscription-modal .box .success-overlay .img-overlay {
          opacity: 0.08;
          position: absolute; }
        #comingSoon .subscription-modal .box .success-overlay .message-container {
          position: relative; }
        #comingSoon .subscription-modal .box .success-overlay.show {
          -webkit-transform: scale(1);
                  transform: scale(1); }
      #comingSoon .subscription-modal .box .loading-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
        transition: opacity 0.3s; }
        #comingSoon .subscription-modal .box .loading-overlay.show {
          opacity: 1;
          -webkit-transform: scale(1);
                  transform: scale(1); }
    #comingSoon .subscription-modal.show {
      -webkit-transform: scale(1);
              transform: scale(1);
      transition-delay: 0s; }
      #comingSoon .subscription-modal.show .box {
        transition-delay: 0.1s;
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0); }
  #comingSoon.show .img-overlay:not(.blurred) {
    opacity: 0; }
  #comingSoon.show .content {
    opacity: 1; }
  @media (max-width: 991px) {
    #comingSoon .content {
      padding: 15px; }
      #comingSoon .content .big-txt {
        font-size: 40px; }
      #comingSoon .content .txt-content {
        font-size: 14px; } }

@media (max-width: 991px) and (max-height: 504px) {
  #comingSoon .subscription-modal {
    overflow: scroll; }
    #comingSoon .subscription-modal .box {
      margin: auto; } }
  @media (max-width: 850px) {
    #comingSoon .subscription-modal .box {
      display: block;
      width: 90%; }
      #comingSoon .subscription-modal .box .pad-40 {
        padding: 20px 30px; }
      #comingSoon .subscription-modal .box > :first-child {
        border-right: none;
        padding-bottom: 0; }
      #comingSoon .subscription-modal .box > :nth-child(2) {
        padding-top: 0; }
      #comingSoon .subscription-modal .box label {
        font-size: 12px; }
      #comingSoon .subscription-modal .box input {
        margin-bottom: 5px; }
      #comingSoon .subscription-modal .box .txt-xxlarge {
        font-size: 18px; }
      #comingSoon .subscription-modal .box .btn {
        margin: 20px auto;
        max-width: 300px;
        width: 100%;
        display: block; }
        #comingSoon .subscription-modal .box .btn.soc-btn {
          max-width: 300px;
          width: 100%; }
      #comingSoon .subscription-modal .box .dismiss-btn {
        margin: 0;
        width: auto; } }

@media (max-width: 850px) and (max-height: 645px) {
  #comingSoon .subscription-modal {
    overflow: scroll; }
    #comingSoon .subscription-modal .box {
      margin: auto; } }
  @media (max-width: 767px) {
    #comingSoon img.logo {
      width: 150px; }
    #comingSoon .content {
      padding: 15px; }
      #comingSoon .content .big-txt {
        font-size: 35px; }
      #comingSoon .content .txt-content {
        font-size: 12px; }
    #comingSoon .subscription-modal {
      overflow: scroll; }
      #comingSoon .subscription-modal .box {
        display: block;
        width: 90%; }
        #comingSoon .subscription-modal .box .margin-top-40 {
          margin-top: 0; }
        #comingSoon .subscription-modal .box .register-socmed > div {
          margin: 0 5px; }
        #comingSoon .subscription-modal .box .dismiss-btn {
          margin: 0;
          width: auto; } }
  @media (max-width: 452px) {
    #comingSoon img.logo {
      width: 100px; }
    #comingSoon .content .big-txt {
      font-size: 25px; }
    #comingSoon .content .txt-content br {
      display: none; } }
  @media (max-width: 320px) {
    #comingSoon .content .big-txt {
      font-size: 20px; } }

.slider {
  min-height: 300px;
  background: #111;
  position: relative; }
  .slider .active-slide,
  .slider .last-active-slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-animation-delay: 0s;
            animation-delay: 0s; }
    .slider .active-slide > div,
    .slider .last-active-slide > div {
      max-width: 50%; }
  .slider .last-active-slide {
    opacity: 1;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: opacity 0.3s; }
  .slider .slider-control {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    border: none;
    background: #fff;
    border-radius: 100%;
    color: #282828;
    height: 70px;
    width: 70px;
    transition: 0.3s; }
    .slider .slider-control.prev-btn {
      left: -35px;
      box-shadow: 0px 10px 25px #04060517; }
    .slider .slider-control.next-btn {
      right: -35px;
      box-shadow: 0px 10px 25px #04060517; }
    .slider .slider-control:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
  .slider .slider-thumbnails {
    position: absolute;
    width: 100%;
    bottom: 20px;
    left: 0; }
    .slider .slider-thumbnails .thumb {
      width: 50px;
      height: 5px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.5);
      margin: 0 10px;
      position: relative;
      overflow: hidden; }
      .slider .slider-thumbnails .thumb::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #fff;
        -webkit-transform: translateX(100%);
                transform: translateX(100%); }
      .slider .slider-thumbnails .thumb.active {
        pointer-events: none; }
        .slider .slider-thumbnails .thumb.active::after {
          -webkit-transform: translateX(0);
                  transform: translateX(0); }
    .slider .slider-thumbnails.next {
      pointer-events: none; }
      .slider .slider-thumbnails.next .thumb.active-out::after {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-animation-name: thumbOutNext;
                animation-name: thumbOutNext;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
      .slider .slider-thumbnails.next .thumb.active::after {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-animation-name: thumbInNext;
                animation-name: thumbInNext;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
    .slider .slider-thumbnails.prev {
      pointer-events: none; }
      .slider .slider-thumbnails.prev .thumb.active-out::after {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-animation-name: thumbOutPrev;
                animation-name: thumbOutPrev;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
      .slider .slider-thumbnails.prev .thumb.active::after {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-animation-name: thumbInPrev;
                animation-name: thumbInPrev;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
  .slider.in-transition .last-active-slide {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; }
  @media (max-width: 991px) {
    .slider .active-slide,
    .slider .last-active-slide {
      padding: 30px; }
      .slider .active-slide > div,
      .slider .last-active-slide > div {
        max-width: 70%; }
    .slider .slider-control {
      -webkit-transform: scale(0.7);
              transform: scale(0.7); }
      .slider .slider-control:hover {
        -webkit-transform: scale(0.8);
                transform: scale(0.8); } }
  @media (max-width: 767px) {
    .slider {
      margin-left: -16px;
      margin-right: -16px; }
      .slider .active-slide > div,
      .slider .last-active-slide > div {
        max-width: 100%; }
      .slider .slider-control {
        -webkit-transform: scale(0.5);
                transform: scale(0.5); }
        .slider .slider-control:hover {
          -webkit-transform: scale(0.6);
                  transform: scale(0.6); } }
  @media (max-width: 450px) {
    .slider .slider-control {
      -webkit-transform: scale(0.5);
              transform: scale(0.5); }
      .slider .slider-control:hover {
        -webkit-transform: scale(0.6);
                transform: scale(0.6); }
      .slider .slider-control.prev-btn {
        left: -15px; }
      .slider .slider-control.next-btn {
        right: -15px; } }

html {
  font-size: 14px;
  color: #282828;
  overflow-x: hidden; }

body {
  margin: 0;
  padding: 0;
  font-family: "librefranklin-regular";
  overflow-x: hidden;
  color: #282828; }

.modal {
  z-index: 999999; }
  .modal-open .main-navbar nav > .container {
    padding-right: 32px; }

button {
  outline: none !important; }

a, a:hover, a:focus {
  color: inherit;
  text-decoration: none;
  outline: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit; }

h1, h2, h3, h4, h5, h6 {
  font-weight: bold; }

ul {
  padding-left: 0;
  margin: 0; }

li {
  list-style: none; }

.bold {
  font-weight: bold; }

.italic {
  font-style: italic; }

.hidden {
  display: none; }

.transitioned {
  transition: 0.6s; }
  .transitioned-fast {
    transition: 0.3s; }

.clickable {
  cursor: pointer; }

.no-pointer {
  pointer-events: none; }

.scale-hide {
  -webkit-transform: scale(0);
          transform: scale(0); }

.separator {
  margin: 0 5px; }

.relative-container {
  position: relative; }

.margin {
  margin: 5px; }
  .margin-auto {
    margin: auto; }
  .margin-0 {
    margin: 0px !important;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px; }
  .margin-10 {
    margin: 10px; }
  .margin-15 {
    margin: 15px; }
  .margin-20 {
    margin: 20px; }
  .margin-25 {
    margin: 25px; }
  .margin-30 {
    margin: 30px; }
  .margin-35 {
    margin: 35px; }
  .margin-40 {
    margin: 40px; }
  .margin-45 {
    margin: 45px; }
  .margin-50 {
    margin: 50px; }
  .margin-left {
    margin-left: 5px; }
    .margin-left-10 {
      margin-left: 10px; }
    .margin-left-15 {
      margin-left: 15px; }
    .margin-left-20 {
      margin-left: 20px; }
    .margin-left-25 {
      margin-left: 25px; }
    .margin-left-30 {
      margin-left: 30px; }
    .margin-left-35 {
      margin-left: 35px; }
    .margin-left-40 {
      margin-left: 40px; }
    .margin-left-45 {
      margin-left: 45px; }
    .margin-left-50 {
      margin-left: 50px; }
    .margin-left-100 {
      margin-left: 100px; }
  .margin-right {
    margin-right: 5px; }
    .margin-right-10 {
      margin-right: 10px; }
    .margin-right-15 {
      margin-right: 15px; }
    .margin-right-20 {
      margin-right: 20px; }
    .margin-right-25 {
      margin-right: 25px; }
    .margin-right-30 {
      margin-right: 30px; }
    .margin-right-35 {
      margin-right: 35px; }
    .margin-right-40 {
      margin-right: 40px; }
    .margin-right-45 {
      margin-right: 45px; }
    .margin-right-50 {
      margin-right: 50px; }
    .margin-right-100 {
      margin-right: 100px; }
  .margin-top {
    margin-top: 5px; }
    .margin-top-10 {
      margin-top: 10px; }
    .margin-top-15 {
      margin-top: 15px; }
    .margin-top-20 {
      margin-top: 20px; }
    .margin-top-25 {
      margin-top: 25px; }
    .margin-top-30 {
      margin-top: 30px; }
    .margin-top-35 {
      margin-top: 35px; }
    .margin-top-40 {
      margin-top: 40px; }
    .margin-top-45 {
      margin-top: 45px; }
    .margin-top-50 {
      margin-top: 50px; }
    .margin-top-100 {
      margin-top: 100px; }
  .margin-bottom {
    margin-bottom: 5px; }
    .margin-bottom-10 {
      margin-bottom: 10px; }
    .margin-bottom-15 {
      margin-bottom: 15px; }
    .margin-bottom-20 {
      margin-bottom: 20px; }
    .margin-bottom-25 {
      margin-bottom: 25px; }
    .margin-bottom-30 {
      margin-bottom: 30px; }
    .margin-bottom-35 {
      margin-bottom: 35px; }
    .margin-bottom-40 {
      margin-bottom: 40px; }
    .margin-bottom-45 {
      margin-bottom: 45px; }
    .margin-bottom-50 {
      margin-bottom: 50px; }
    .margin-bottom-100 {
      margin-bottom: 100px; }
  .margin-none {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0; }

.pad {
  padding: 5px; }
  .pad-10 {
    padding: 10px; }
  .pad-15 {
    padding: 15px; }
  .pad-20 {
    padding: 20px; }
  .pad-25 {
    padding: 25px; }
  .pad-30 {
    padding: 30px; }
  .pad-35 {
    padding: 35px; }
  .pad-40 {
    padding: 40px; }
  .pad-45 {
    padding: 45px; }
  .pad-50 {
    padding: 50px; }
  .pad-100 {
    padding: 100px; }
  .pad-left {
    padding-left: 5px; }
    .pad-left-10 {
      padding-left: 10px; }
    .pad-left-15 {
      padding-left: 15px; }
    .pad-left-20 {
      padding-left: 20px; }
    .pad-left-25 {
      padding-left: 25px; }
    .pad-left-30 {
      padding-left: 30px; }
    .pad-left-35 {
      padding-left: 35px; }
    .pad-left-40 {
      padding-left: 40px; }
    .pad-left-45 {
      padding-left: 45px; }
    .pad-left-50 {
      padding-left: 50px; }
    .pad-left-100 {
      padding-left: 100px; }
  .pad-right {
    padding-right: 5px; }
    .pad-right-10 {
      padding-right: 10px; }
    .pad-right-15 {
      padding-right: 15px; }
    .pad-right-20 {
      padding-right: 20px; }
    .pad-right-25 {
      padding-right: 25px; }
    .pad-right-30 {
      padding-right: 30px; }
    .pad-right-35 {
      padding-right: 35px; }
    .pad-right-40 {
      padding-right: 40px; }
    .pad-right-45 {
      padding-right: 45px; }
    .pad-right-50 {
      padding-right: 50px; }
    .pad-right-100 {
      padding-right: 100px; }
  .pad-top {
    padding-top: 5px; }
    .pad-top-10 {
      padding-top: 10px; }
    .pad-top-15 {
      padding-top: 15px; }
    .pad-top-20 {
      padding-top: 20px; }
    .pad-top-25 {
      padding-top: 25px; }
    .pad-top-30 {
      padding-top: 30px; }
    .pad-top-35 {
      padding-top: 35px; }
    .pad-top-40 {
      padding-top: 40px; }
    .pad-top-45 {
      padding-top: 45px; }
    .pad-top-50 {
      padding-top: 50px; }
    .pad-top-100 {
      padding-top: 100px; }
  .pad-bottom {
    padding-bottom: 5px; }
    .pad-bottom-10 {
      padding-bottom: 10px; }
    .pad-bottom-15 {
      padding-bottom: 15px; }
    .pad-bottom-20 {
      padding-bottom: 20px; }
    .pad-bottom-25 {
      padding-bottom: 25px; }
    .pad-bottom-30 {
      padding-bottom: 30px; }
    .pad-bottom-35 {
      padding-bottom: 35px; }
    .pad-bottom-40 {
      padding-bottom: 40px; }
    .pad-bottom-45 {
      padding-bottom: 45px; }
    .pad-bottom-50 {
      padding-bottom: 50px; }
    .pad-bottom-100 {
      padding-bottom: 100px; }

.modal-align-vertical {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  background: transparent;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: auto;
  justify-content: center;
  align-items: center;
  -webkit-animation-duration: .6s;
          animation-duration: .6s;
  max-width: 50% !important; }
  .modal-align-vertical .modal-dialog {
    margin: auto; }

.pointer-events-none {
  pointer-events: none; }

.remove-bootstrap-animation.modal.fade .modal-dialog {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  transition: none; }

.ph-item {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  opacity: 0.5; }
  .ph-item > * {
    padding: 0; }
    .ph-item > * .ph-row div {
      height: 15px;
      border-radius: 5px; }
      .ph-item > * .ph-row div.big {
        height: 25px; }
  .ph-item::before {
    -webkit-animation-duration: 2s;
            animation-duration: 2s; }

.page-body-container .page-banner-image {
  position: relative;
  min-height: 500px;
  background-color: #ccc;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; }

.page-body-container .page-banner-text {
  padding: 30px; }
  .page-body-container .page-banner-text p {
    opacity: 0.4;
    font-size: 18px; }
    .page-body-container .page-banner-text p.txt-header {
      font-size: 30px;
      opacity: 1; }

.page-body-container .page-container {
  max-width: 90%;
  width: 100%;
  margin: auto;
  padding-top: 50px; }

.pad-to-nav {
  padding-top: 120px; }

.skeleton-loading {
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD; }
  .skeleton-loading::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    -webkit-animation: shimmer 5s infinite;
            animation: shimmer 5s infinite;
    content: ''; }

@-webkit-keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

.slick-arrow.slick-prev:before {
  font-family: 'Font Awesome 5 Pro';
  content: "\F104";
  font-size: 30px; }

.slick-arrow.slick-next:before {
  font-family: 'Font Awesome 5 Pro';
  content: "\F105";
  font-size: 30px; }

.section-header {
  margin: auto;
  padding-top: 90px; }

.section-title {
  font-size: 1.25rem;
  font-family: "librefranklin-bold";
  padding-bottom: 10px; }

h1.section-title {
  font-size: 2.5rem;
  font-family: "BebasNeue-Bold"; }

.section-description {
  font-family: "librefranklin-regular";
  padding-bottom: 30px; }

.green-background {
  background-color: #477625; }

.simple-image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px; }
  .simple-image-list > li {
    height: 0;
    padding-top: 60%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

.grid-fix-3-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px; }

.grid-3-col {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 30px; }

.grid-4-col {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px; }

.added-product-container {
  display: grid;
  grid-template-columns: 100px 1px 1fr;
  grid-gap: 20px;
  padding: 0 15px; }
  .added-product-container .image-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
  .added-product-container .details {
    padding: 30px 0; }
  .added-product-container .line {
    background-color: #f3f3f3; }

.slick-dots li {
  height: auto;
  width: auto; }
  .slick-dots li button {
    background-color: #fff;
    opacity: .6;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    transition: all .3s ease;
    border: 1px solid #747474; }
    .slick-dots li button:before {
      content: none; }
  .slick-dots li.slick-active button, .slick-dots li:hover button {
    background-color: #F47920;
    border: 1px solid #F47920;
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }

.slick-line .slick-dots li button {
  border-radius: 10px;
  width: 40px;
  height: 5px;
  padding: 0; }

.max-width-90 {
  max-width: 1200px;
  width: 90%;
  margin: auto; }

.full-width {
  width: 100%; }

.inline-block {
  display: inline-block; }

.line-height-1 {
  line-height: 1; }

.grid {
  display: grid; }
  .grid.justify-center {
    -webkit-justify-content: center;
            justify-content: center; }
  .grid-2 {
    grid-template-columns: 1fr 1fr; }
    .grid-2-auto {
      grid-template-columns: auto auto; }
  .grid-3 {
    grid-template-columns: 1fr 1fr 1fr; }
    .grid-3-auto {
      grid-template-columns: auto auto auto; }
  .grid-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr; }
    .grid-4-auto {
      grid-template-columns: auto auto auto auto; }
  .grid-gap-20 {
    grid-gap: 20px; }
  .grid-gap-30 {
    grid-gap: 30px; }
  .grid-gap-40 {
    grid-gap: 40px; }
  .grid-gap-50 {
    grid-gap: 50px; }

.no-whitespace {
  white-space: nowrap; }

.more-products-container .grid-3-auto {
  grid-template-columns: minmax(auto, 300px) minmax(auto, 300px) minmax(auto, 300px); }

.more-products-container .grid-4-auto {
  grid-template-columns: minmax(auto, 300px) minmax(auto, 300px) minmax(auto, 300px) minmax(auto, 300px); }

.more-products-container .product-card-holder {
  max-width: 300px; }

.dropdown-card {
  -webkit-animation-name: floatInDown;
          animation-name: floatInDown;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  top: -10px; }

.collapse-container {
  transition: 0.6s;
  max-height: 0;
  overflow: hidden; }

.screen-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999999999999999999; }

.pagination-links .page-link {
  width: 35px;
  height: 35px;
  font-size: 16px;
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  transition: 0.3s; }
  .pagination-links .page-link.page-arrow {
    background: #EBEBEB; }
  .pagination-links .page-link.active {
    background: #F47920;
    color: #fff; }
  .pagination-links .page-link:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

.star-group {
  position: relative; }
  .star-group .left-part,
  .star-group .right-part {
    position: absolute;
    top: 0;
    width: 50%;
    overflow: hidden; }
  .star-group .left-part {
    left: 0; }
  .star-group .right-part {
    right: 0; }
    .star-group .right-part i {
      float: right; }

.review-form {
  background: #f5f5f5;
  border-radius: 10px; }
  .review-form textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 150px;
    outline: none;
    padding: 10px; }

.review-card {
  border-radius: 20px;
  border: 1px solid #eee;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
  padding: 20px; }
  .review-card .customer-details img {
    max-width: 70px;
    width: 70px;
    background-color: #ccc;
    border-radius: 100%;
    margin: 0 auto;
    overflow: hidden; }

.tooltipped {
  position: relative; }
  .tooltipped::after {
    content: attr(tooltiptext);
    position: absolute;
    background: #111;
    color: #fff;
    top: 0;
    right: 0;
    font-size: 12px;
    white-space: nowrap;
    padding: 3px;
    border-radius: 3px;
    pointer-events: none;
    transition: 0.3s;
    opacity: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .tooltipped:hover::after {
    -webkit-transform: translateY(calc(-100% - 5px));
            transform: translateY(calc(-100% - 5px));
    opacity: 1; }

.circle-loader {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  -webkit-transform: scale(0.7);
          transform: scale(0.7); }
  .circle-loader > div {
    position: absolute;
    width: 100%;
    height: 50%;
    overflow: hidden;
    left: 0;
    top: 0;
    -webkit-animation-name: rotateLoader;
            animation-name: rotateLoader;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0.9; }
    .circle-loader > div::after {
      content: '';
      position: absolute;
      width: 150px;
      height: 150px;
      border: 20px solid #F47920;
      border-radius: 100%;
      box-shadow: inset 0 5px 5px rgba(241, 170, 170, 0.2); }
    .circle-loader > div:nth-child(2) {
      -webkit-animation-duration: 4s;
              animation-duration: 4s; }
      .circle-loader > div:nth-child(2)::after {
        border-color: #282828;
        border-width: 15px; }
    .circle-loader > div:last-child {
      -webkit-animation-duration: 3s;
              animation-duration: 3s;
      animation-direction: reverse; }
      .circle-loader > div:last-child::after {
        border-color: white;
        border-width: 10px; }

@-webkit-keyframes rotateLoader {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotateLoader {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.triangle-loader {
  width: 100px;
  height: 100px;
  position: relative; }
  .triangle-loader div {
    position: absolute;
    width: 5px;
    height: 100%;
    bottom: 0;
    overflow: hidden; }
    .triangle-loader div::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      left: 0;
      top: 0; }
    .triangle-loader div:first-child {
      left: -1px;
      bottom: 3px;
      -webkit-transform: rotate(30deg);
              transform: rotate(30deg);
      -webkit-transform-origin: 0 100%;
              transform-origin: 0 100%; }
      .triangle-loader div:first-child::after {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
        -webkit-animation-name: slantedAnimation;
                animation-name: slantedAnimation;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite; }
    .triangle-loader div:nth-child(2) {
      right: -2px;
      -webkit-transform: rotate(-30deg);
              transform: rotate(-30deg);
      -webkit-transform-origin: 0 100%;
              transform-origin: 0 100%; }
      .triangle-loader div:nth-child(2)::after {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
        -webkit-animation-name: slantedAnimationReverse;
                animation-name: slantedAnimationReverse;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-delay: 0.5s;
                animation-delay: 0.5s; }
    .triangle-loader div:last-child {
      width: 100%;
      height: 5px;
      left: 0; }
      .triangle-loader div:last-child::after {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
        -webkit-animation-name: horizontalAnimation;
                animation-name: horizontalAnimation;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-delay: 1s;
                animation-delay: 1s; }

@-webkit-keyframes slantedAnimation {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  33% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  to {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

@keyframes slantedAnimation {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  33% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  to {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

@-webkit-keyframes slantedAnimationReverse {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  33% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  to {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); } }

@keyframes slantedAnimationReverse {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  33% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  to {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); } }

@-webkit-keyframes horizontalAnimation {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  33.33% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@keyframes horizontalAnimation {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  33.33% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@media (max-width: 1200px) {
  .container {
    max-width: 1400px;
    width: 100%; } }

@media (max-width: 991px) {
  .page-body-container .page-banner-image {
    min-height: 400px; }
  .modal-align-vertical {
    max-width: 80% !important; }
  .more-products-container .grid-3-auto {
    grid-template-columns: minmax(auto, 300px) minmax(auto, 300px); }
  .more-products-container .grid-4-auto {
    grid-template-columns: minmax(auto, 300px) minmax(auto, 300px) minmax(auto, 300px); }
  .more-products-container .product-card-holder {
    max-width: 300px; }
  .grid-md-1 {
    grid-template-columns: 1fr; }
  .grid-md-2 {
    grid-template-columns: 1fr 1fr; }
  .grid-md-3 {
    grid-template-columns: 1fr 1fr 1fr; }
  .grid-md-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr; } }

@media (max-width: 768px) {
  .margin-30 {
    margin: 25px; }
  .margin-35 {
    margin: 25px; }
  .margin-40 {
    margin: 30px; }
  .margin-45 {
    margin: 35px; }
  .margin-50 {
    margin: 35px; }
  .margin-left-30 {
    margin-left: 25px; }
  .margin-left-35 {
    margin-left: 25px; }
  .margin-left-40 {
    margin-left: 30px; }
  .margin-left-45 {
    margin-left: 35px; }
  .margin-left-50 {
    margin-left: 35px; }
  .margin-left-100 {
    margin-left: 50px; }
  .margin-right-30 {
    margin-right: 25px; }
  .margin-right-35 {
    margin-right: 25px; }
  .margin-right-40 {
    margin-right: 30px; }
  .margin-right-45 {
    margin-right: 35px; }
  .margin-right-50 {
    margin-right: 35px; }
  .margin-right-100 {
    margin-right: 50px; }
  .margin-top-30 {
    margin-top: 25px; }
  .margin-top-35 {
    margin-top: 25px; }
  .margin-top-40 {
    margin-top: 30px; }
  .margin-top-45 {
    margin-top: 35px; }
  .margin-top-50 {
    margin-top: 35px; }
  .margin-top-100 {
    margin-top: 50px; }
  .margin-bottom-30 {
    margin-bottom: 25px; }
  .margin-bottom-35 {
    margin-bottom: 25px; }
  .margin-bottom-40 {
    margin-bottom: 30px; }
  .margin-bottom-45 {
    margin-bottom: 35px; }
  .margin-bottom-50 {
    margin-bottom: 35px; }
  .margin-bottom-100 {
    margin-bottom: 50px; }
  .margin-xs-0 {
    margin: 0; }
  .pad {
    padding: 5px; }
    .pad-25, .pad-30, .pad-35, .pad-40, .pad-45, .pad-50, .pad-100 {
      padding: 20px; }
    .pad-left-25 {
      padding-left: 20px; }
    .pad-left-30 {
      padding-left: 20px; }
    .pad-left-35 {
      padding-left: 25px; }
    .pad-left-40 {
      padding-left: 30px; }
    .pad-left-45 {
      padding-left: 35px; }
    .pad-left-50 {
      padding-left: 35px; }
    .pad-left-100 {
      padding-left: 40px; }
    .pad-right-25 {
      padding-right: 20px; }
    .pad-right-30 {
      padding-right: 20px; }
    .pad-right-35 {
      padding-right: 25px; }
    .pad-right-40 {
      padding-right: 30px; }
    .pad-right-45 {
      padding-right: 35px; }
    .pad-right-50 {
      padding-right: 35px; }
    .pad-right-100 {
      padding-right: 40px; }
    .pad-top-25 {
      padding-top: 20px; }
    .pad-top-30 {
      padding-top: 20px; }
    .pad-top-35 {
      padding-top: 25px; }
    .pad-top-40 {
      padding-top: 30px; }
    .pad-top-45 {
      padding-top: 35px; }
    .pad-top-50 {
      padding-top: 35px; }
    .pad-top-100 {
      padding-top: 40px; }
    .pad-bottom-25 {
      padding-bottom: 20px; }
    .pad-bottom-30 {
      padding-bottom: 20px; }
    .pad-bottom-35 {
      padding-bottom: 25px; }
    .pad-bottom-40 {
      padding-bottom: 30px; }
    .pad-bottom-45 {
      padding-bottom: 35px; }
    .pad-bottom-50 {
      padding-bottom: 35px; }
    .pad-bottom-100 {
      padding-bottom: 40px; }
  .pad-xs-0 {
    padding: 0 !important; }
  .container {
    max-width: 100%;
    width: 100%; }
  .page-body-container .page-banner-image {
    min-height: 300px; }
  .page-body-container .page-banner-text {
    padding: 15px; }
    .page-body-container .page-banner-text p {
      opacity: 0.4;
      font-size: 18px; }
      .page-body-container .page-banner-text p.txt-header {
        font-size: 30px;
        opacity: 1; }
  .page-body-container .page-container {
    margin-top: 20px; }
    .page-body-container .page-container-full-xs {
      max-width: 100%;
      padding: 0; }
  .modal-align-vertical {
    max-width: 90% !important; }
  .grid-xs-1 {
    grid-template-columns: 1fr; }
  .grid-xs-2 {
    grid-template-columns: 1fr 1fr; }
  .grid-xs-3 {
    grid-template-columns: 1fr 1fr 1fr; }
  .grid-xs-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr; }
  .more-products-container .grid-4-auto {
    grid-template-columns: minmax(auto, 300px) minmax(auto, 300px); } }

@media (max-width: 600px) {
  .added-product-container .button-container {
    display: block; }
    .added-product-container .button-container .btn {
      margin: 10px auto;
      font-size: 12px;
      display: block;
      max-width: 300px;
      width: 100%; } }

@media (max-width: 576px) {
  .container {
    max-width: 100%;
    width: 100%; } }

@media (max-height: 550px) {
  .modal .modal-align-vertical {
    margin: 50px auto 10px;
    height: auto;
    min-height: 100%; }
  .added-product-container {
    grid-template-columns: 1fr;
    grid-gap: 5px; }
    .added-product-container .image-container {
      height: 100px;
      margin-top: 5px; } }

.btn {
  border-radius: 25px;
  font-family: "librefranklin-regular";
  padding: 0.625rem 1.5rem;
  color: #fff;
  background: #747474;
  font-size: 14px;
  transition: 0.3s; }
  .btn.with-icon {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
  .btn-icon {
    width: auto;
    height: auto;
    background: transparent;
    padding: 0;
    width: 40px;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .btn.btn-primary {
    background: #284356 !important;
    border-color: #284356 !important; }
  .btn.btn-secondary {
    background: #F47920; }
    .btn.btn-secondary-dark {
      background: #D9580A; }
      .btn.btn-secondary-dark:hover {
        color: #fff; }
  .btn.btn-gray {
    background: #747474; }
  .btn.btn-lgray {
    background: #f3f3f3;
    color: #111; }
  .btn.btn-mgray {
    background: #c2c2c2;
    color: #111; }
  .btn.btn-violet {
    background: #C9287D; }
    .btn.btn-violet:hover {
      color: #fff; }
  .btn.btn-clear {
    background: transparent; }
    .btn.btn-clear.black {
      color: #282828;
      border: 1px solid #282828; }
    .btn.btn-clear.clear-black {
      color: #282828; }
    .btn.btn-clear.secondary {
      color: #F47920;
      border: 1px solid #F47920; }
    .btn.btn-clear.white {
      color: white;
      border: 1px solid white; }
    .btn.btn-clear.link {
      color: #356077;
      border: 1px solid #356077; }
  .btn.btn-black {
    background: #282828; }
    .btn.btn-black:hover {
      color: #fff; }
  .btn.btn-white {
    background: #fff;
    color: #F47920; }
  .btn.btn-oblivion {
    background: #000; }
    .btn.btn-oblivion:hover {
      color: #fff; }
  .btn.btn-fb {
    background: #3b5998;
    color: #fff; }
  .btn.btn-twitter {
    background: #38A1F3;
    color: #fff; }
  .btn.btn-google {
    background: #ff3e30;
    color: #fff; }
  .btn.btn-sm, .btn-group-sm > .btn {
    font-size: 12px; }
  .btn.btn-lg, .btn-group-lg > .btn {
    font-size: 18px; }
  .btn.btn-pad-large {
    padding: 10px 30px; }
  .btn.btn-pad-xlarge {
    padding: 20px 30px; }
  .btn.no-padding {
    padding: 0; }
  .btn.btn-block {
    display: block; }
    .btn.btn-block-center {
      margin: auto; }
  .btn.btn-sqr {
    border-radius: 0; }
  .btn.btn-bold {
    font-weight: bold; }
  .btn .icon-container {
    padding-left: 20px; }
  .btn.btn-nostyles {
    padding: 0;
    background: none; }
  .btn:active:not(.no-transition) {
    transition: 0.1s;
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.5) !important;
    background: inherit; }

.btn:focus:not(:active) {
  box-shadow: none !important; }

.button-loader {
  border-radius: 25px;
  overflow: hidden;
  margin: auto;
  position: relative;
  transition: 0.5s;
  max-width: 500px; }
  .button-loader .loading-state,
  .button-loader .sent-state {
    position: absolute;
    width: 43px;
    height: 43px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: #Fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 20px;
    -webkit-transform: scale(0);
            transform: scale(0);
    border-radius: 100%; }
  .button-loader .loading-state {
    background: #F47920; }
    .button-loader .loading-state i {
      -webkit-animation-name: float;
              animation-name: float;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear; }
  .button-loader .sent-state {
    background: #477625; }
  .button-loader.loading {
    max-width: 43px; }
    .button-loader.loading .loading-state {
      transition: 0.5s;
      -webkit-transform: scale(1);
              transform: scale(1); }
  .button-loader.sent {
    max-width: 43px; }
    .button-loader.sent .loading-state {
      -webkit-transform: scale(1);
              transform: scale(1); }
    .button-loader.sent .sent-state {
      transition: 0.5s;
      -webkit-transform: scale(1);
              transform: scale(1); }

.underline-hover {
  position: relative; }
  .underline-hover::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #fff;
    bottom: 0;
    left: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    transition: 0.3s; }
  .underline-hover.uh-black::after {
    background: #282828; }
  .underline-hover:hover::after, .underline-hover.active::after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }

.scale-hover {
  transition: 0.3s; }
  .scale-hover:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

[material-checkbox=primary] {
  color: #477625 !important; }

@media (max-width: 767px) {
  .btn {
    font-size: 12px; }
    .btn.btn-lg, .btn-group-lg > .btn {
      font-size: 14px; }
    .btn.btn-pad-large {
      padding: 5px 15px; }
    .btn.btn-pad-xlarge {
      padding: 10px 15px; } }

.main-navbar .nav-fixed {
  width: 100%;
  z-index: 99999; }

.main-navbar .nav-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  cursor: pointer;
  z-index: 1; }
  .main-navbar .nav-overlay.open {
    display: block; }

.main-navbar .mobile-search-container {
  display: none;
  padding: 10px;
  background: #F47920; }
  .main-navbar .mobile-search-container > .search-container {
    background: #fff; }

.main-navbar .nav-disclaimer {
  background: linear-gradient(90deg, #E85523, #F47A21, #F19F19);
  color: white;
  padding: 30px;
  position: relative; }
  .main-navbar .nav-disclaimer img {
    position: absolute;
    width: 400px;
    height: 100%;
    object-fit: contain;
    object-position: right bottom;
    right: 82px;
    top: 0; }

.main-navbar .navbar {
  background-color: #fff;
  padding: 10px 0 0;
  transition: 0.3s; }
  .main-navbar .navbar .user-indicator {
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translateX(20%) translateY(-20%);
            transform: translateX(20%) translateY(-20%);
    background: #fff;
    color: #ff3333 !important;
    pointer-events: none; }
  .main-navbar .navbar .hamburger {
    width: 22px;
    height: 15px;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 1;
    margin-right: 30px; }
    .main-navbar .navbar .hamburger span {
      display: block;
      background-color: #F47920;
      border-radius: 50px;
      position: absolute;
      height: 3px;
      width: 100%;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      transition: .25s ease-in-out; }
      .main-navbar .navbar .hamburger span:nth-child(1) {
        top: 0px; }
      .main-navbar .navbar .hamburger span:nth-child(2), .main-navbar .navbar .hamburger span:nth-child(3) {
        top: 7px; }
      .main-navbar .navbar .hamburger span:nth-child(3) {
        top: 14px; }
  .main-navbar .navbar .nav-stickout {
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    width: 100%;
    padding: 10px 20px;
    z-index: 10;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    transition: 0.3s;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1); }
    .main-navbar .navbar .nav-stickout img {
      max-width: 40px;
      width: 100%; }
  .main-navbar .navbar .container {
    max-width: 90%;
    display: grid;
    grid-template-columns: auto auto auto 1fr auto;
    grid-gap: 40px;
    width: 100%;
    padding: 10px 0; }
    .main-navbar .navbar .container > * {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-align-self: center;
              align-self: center; }
    .main-navbar .navbar .container .search-container {
      margin-right: 60px;
      width: 100%; }
    .main-navbar .navbar .container .btn {
      font-family: "librefranklin-light";
      font-size: 12px; }
    .main-navbar .navbar .container .navbar-header .navbar-brand {
      margin: 0;
      padding: 0; }
      .main-navbar .navbar .container .navbar-header .navbar-brand img {
        max-width: 80px;
        object-fit: contain; }
    .main-navbar .navbar .container .nav--search-btn {
      display: none; }

.main-navbar .nav-links {
  background-color: #F47A21;
  padding: 0 50px;
  color: #fff; }
  .main-navbar .nav-links .container {
    max-width: 100%;
    padding: 0; }
    .main-navbar .nav-links .container > * {
      -webkit-flex: 1 1;
              flex: 1 1;
      padding: 14px 30px;
      text-align: center;
      position: relative;
      overflow: hidden; }
      .main-navbar .nav-links .container > * a::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 70%;
        height: 3px;
        background: #fff;
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
        transition: 0.3s; }
      .main-navbar .nav-links .container > * a.active::after, .main-navbar .nav-links .container > * a:hover::after {
        -webkit-transform: translateY(0);
                transform: translateY(0); }

.main-navbar .nav-sidebar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  transition: 0.3s;
  transition-delay: 0.3s;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  color: #282828;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
  color: #282828; }
  .main-navbar .nav-sidebar-container .link-container {
    background: #fff;
    min-width: 300px;
    max-width: 400px;
    max-height: 100%;
    width: 100%;
    padding: 50px 0;
    box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  .main-navbar .nav-sidebar-container .action-container {
    padding: 0 50px; }
    .main-navbar .nav-sidebar-container .action-container img {
      max-width: 25px; }
    .main-navbar .nav-sidebar-container .action-container i {
      font-size: 18px; }
  .main-navbar .nav-sidebar-container .menu-list {
    overflow: auto;
    position: relative; }
    .main-navbar .nav-sidebar-container .menu-list ul li {
      font-size: 14px;
      font-family: "librefranklin-medium";
      position: relative; }
      .main-navbar .nav-sidebar-container .menu-list ul li a {
        position: relative;
        display: block;
        z-index: 2;
        padding: 12px 50px; }
    .main-navbar .nav-sidebar-container .menu-list > ul li a:after {
      content: attr(text);
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #F47920;
      position: absolute;
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      transition: 0.3s;
      color: #fff;
      padding: 12px 50px; }
    .main-navbar .nav-sidebar-container .menu-list > ul li a.active:after, .main-navbar .nav-sidebar-container .menu-list > ul li a:hover:after {
      -webkit-transform: scaleY(1);
              transform: scaleY(1); }
    .main-navbar .nav-sidebar-container .menu-list .mobile-linkouts {
      border-top: 1px solid #ccc; }
  .main-navbar .nav-sidebar-container .quick-links {
    border-top: 1px solid #DFDFDF; }
    .main-navbar .nav-sidebar-container .quick-links a {
      display: block;
      color: #9D9D9D;
      padding: 12px 50px;
      transition: 0.3s; }
      .main-navbar .nav-sidebar-container .quick-links a:hover {
        color: #282828; }
  .main-navbar .nav-sidebar-container.open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    transition: opacity 0.3s;
    transition-delay: 0s; }
    .main-navbar .nav-sidebar-container.open .link-container {
      transition-delay: 0.3s;
      -webkit-transform: translateX(0);
              transform: translateX(0); }

.main-navbar.moved .navbar .nav-stickout {
  -webkit-transform: translateY(0);
          transform: translateY(0); }

@media (max-width: 1450px) {
  .main-navbar .nav-disclaimer img {
    right: 0; }
  .main-navbar .nav-links {
    padding: 0; }
    .main-navbar .nav-links .link {
      padding: 10px; } }

@media (max-width: 991px) {
  .main-navbar .nav-disclaimer img {
    width: 250px; }
  .main-navbar .navbar .container {
    grid-template-columns: auto auto 1fr auto auto;
    grid-auto-flow: dense;
    grid-gap: 20px; }
    .main-navbar .navbar .container .search-container {
      margin-right: 30px; }
    .main-navbar .navbar .container .hamburger {
      display: block; }
    .main-navbar .navbar .container .navbar-header .navbar-brand img {
      max-width: 50px; }
  .main-navbar .nav-links {
    display: none; } }

@media (max-width: 767px) {
  .main-navbar .navbar .container {
    grid-template-columns: auto auto 1fr auto auto;
    grid-gap: 20px; }
    .main-navbar .navbar .container .navbar-header .navbar-brand img {
      max-width: 40px; }
    .main-navbar .navbar .container .modal-registration-button,
    .main-navbar .navbar .container .checkout-btn,
    .main-navbar .navbar .container .line-separator,
    .main-navbar .navbar .container .modal-login-btn-container,
    .main-navbar .navbar .container .nav--checkout-btn {
      display: none; }
    .main-navbar .navbar .container .search-container {
      padding: 5px 10px; } }

@media (max-width: 500px) {
  .main-navbar .mobile-search-container {
    display: none; }
  .main-navbar .nav-disclaimer img {
    width: 200px; }
  .main-navbar .navbar .container {
    grid-template-columns: auto 1fr auto auto;
    grid-gap: 10px; }
    .main-navbar .navbar .container .search-container {
      display: none; }
    .main-navbar .navbar .container .nav--search-btn {
      display: block; } }

@media (max-width: 420px) {
  .main-navbar .navbar .container .modal-login-button i {
    font-size: 20px;
    margin: 0; }
  .main-navbar .navbar .container .modal-login-button span {
    display: none; } }

.footer > .page-container {
  border-top: 1px solid #D7D7D7;
  padding: 100px 0; }
  .footer > .page-container .reach-us-content i {
    width: 20px; }
  .footer > .page-container .subscribe-content .subscribe-form {
    position: relative;
    max-width: 500px;
    margin: auto; }
    .footer > .page-container .subscribe-content .subscribe-form .input-group {
      position: relative;
      overflow: hidden; }
      .footer > .page-container .subscribe-content .subscribe-form .input-group input {
        padding: 12px 20px;
        font-size: 12px;
        border-radius: 100px;
        height: auto;
        border-color: #D1D1D1;
        padding-right: 50px; }
      .footer > .page-container .subscribe-content .subscribe-form .input-group .send-btn {
        position: absolute;
        height: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        padding: 0 20px;
        right: 0;
        top: 0;
        background: none;
        border: none;
        box-shadow: none;
        color: #F47920;
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
        transition: 0.5s;
        z-index: 10; }
        .footer > .page-container .subscribe-content .subscribe-form .input-group .send-btn.in {
          -webkit-transform: translateX(0);
                  transform: translateX(0); }
    .footer > .page-container .subscribe-content .subscribe-form .subscription-message {
      position: absolute;
      bottom: calc(100% + 20px);
      right: 10px;
      color: #fff;
      font-size: 12px;
      -webkit-perspective: 500;
              perspective: 500; }
      .footer > .page-container .subscribe-content .subscribe-form .subscription-message > div {
        padding: 10px;
        border-radius: 5px;
        transition: 0.6s;
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: rotateX(90deg);
                transform: rotateX(90deg);
        opacity: 0; }
        .footer > .page-container .subscribe-content .subscribe-form .subscription-message > div .message {
          position: relative; }
          .footer > .page-container .subscribe-content .subscribe-form .subscription-message > div .message::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-top: 10px solid #111;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            margin: 0 auto;
            top: calc(100% + 10px);
            left: 0;
            right: 0; }
      .footer > .page-container .subscribe-content .subscribe-form .subscription-message.success > div {
        background: #477625; }
        .footer > .page-container .subscribe-content .subscribe-form .subscription-message.success > div .message::after {
          border-top: 10px solid #477625; }
      .footer > .page-container .subscribe-content .subscribe-form .subscription-message.error > div {
        background: #ff3333; }
        .footer > .page-container .subscribe-content .subscribe-form .subscription-message.error > div .message::after {
          border-top: 10px solid #ff3333; }
      .footer > .page-container .subscribe-content .subscribe-form .subscription-message.in > div {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg); }
  .footer > .page-container .footer-link {
    transition: 0.3s; }
    .footer > .page-container .footer-link:hover {
      color: #F47920; }

.footer .copy-right {
  background: #F47920;
  color: #fff;
  padding: 20px 50px; }
  .footer .copy-right > .flex {
    max-width: 80%;
    margin: auto; }

@media (max-width: 991px) {
  .footer {
    font-size: 12px; }
    .footer .page-container .reach-us-content i {
      display: none; }
    .footer .page-container > .flex {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px; }
      .footer .page-container > .flex > .flex-1 {
        text-align: center;
        -webkit-justify-content: center;
                justify-content: center;
        margin-bottom: 20px; } }

@media (max-width: 767px) {
  .footer {
    font-size: 12px; }
    .footer .page-container {
      max-width: 100%; }
      .footer .page-container > .flex .quick-links {
        display: grid;
        grid-template-columns: 1fr; }
        .footer .page-container > .flex .quick-links a {
          margin: 10px 0; }
    .footer .copy-right > .flex {
      display: block;
      text-align: center !important; }
      .footer .copy-right > .flex .text-right {
        text-align: center !important;
        margin-top: 5px; } }

::-webkit-input-placeholder {
  font-style: italic;
  font-size: 12px; }

:-moz-placeholder {
  font-style: italic;
  font-size: 12px; }

::-moz-placeholder {
  font-style: italic;
  font-size: 12px; }

:-ms-input-placeholder {
  font-style: italic;
  font-size: 12px; }

.form-control.form-control-lg {
  height: calc(1.5em + 1rem + 10px);
  font-size: 0.875rem;
  font-family: "librefranklin-regular";
  color: #747474; }

.form-control.with-bottom-margin {
  margin-bottom: 20px; }

.form-control:focus {
  border-color: #ced4da;
  box-shadow: none; }

.form-group {
  margin-bottom: 0;
  width: 100%; }
  .form-group > select.form-control.loading {
    background: url(/static/media/Loading_icon.f8d51a24.gif) no-repeat right #ced4da;
    -webkit-appearance: none;
    background-size: 20px;
    background-position-x: calc(100% - 15px); }
  .form-group label {
    font-size: 14px; }
    .form-group label.lbl-sm {
      font-size: 12px; }

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus, .btn-primary:focus, .btn-primary.focus {
  box-shadow: none; }

.btn.loading {
  pointer-events: none;
  position: relative; }

.btn .icon-container {
  padding-left: 20px; }
  .btn .icon-container.right {
    padding-right: 20px;
    padding-left: 0; }

.input-with-icon-container {
  display: grid;
  grid-template-columns: 1fr auto; }
  .input-with-icon-container input {
    border-radius: 20px; }
  .input-with-icon-container.right-icon input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0; }
  .input-with-icon-container.right-icon .icon {
    background-color: #477625;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #fff;
    padding: 0 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    cursor: pointer; }

.banner-js {
  position: relative; }
  .banner-js .main-banner {
    min-height: calc(100vh - 232.5px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden; }
    .banner-js .main-banner .active-banner {
      width: 100%;
      position: relative;
      background-color: #ccc;
      background: linear-gradient(90deg, #E85523, #F47A21, #F19F19); }
      .banner-js .main-banner .active-banner .active-image {
        width: 100%;
        height: calc(100vh - 232.5px);
        min-height: 400px;
        transition: 0.3s;
        overflow: hidden;
        display: -webkit-flex;
        display: flex; }
        .banner-js .main-banner .active-banner .active-image > div {
          position: relative;
          width: 100%; }
          .banner-js .main-banner .active-banner .active-image > div .text-details {
            color: #fff;
            -webkit-flex: 1 1;
                    flex: 1 1; }
          .banner-js .main-banner .active-banner .active-image > div .image-details {
            height: 100%;
            position: relative; }
            .banner-js .main-banner .active-banner .active-image > div .image-details img {
              position: absolute;
              left: 0;
              top: 0;
              object-fit: contain;
              object-position: center;
              width: 100%;
              height: 100%; }
            .banner-js .main-banner .active-banner .active-image > div .image-details .embed_video {
              position: absolute;
              left: 0;
              top: 0;
              object-fit: contain;
              object-position: center;
              width: 100%;
              height: 100%;
              background: #111; }
              .banner-js .main-banner .active-banner .active-image > div .image-details .embed_video .iframe-container {
                position: absolute;
                width: 100%;
                height: 100%;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                        align-items: center; }
                .banner-js .main-banner .active-banner .active-image > div .image-details .embed_video .iframe-container iframe {
                  position: absolute;
                  height: 100%;
                  width: 100%; }
      .banner-js .main-banner .active-banner .transition-overlay {
        position: absolute;
        width: 0;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden; }
        .banner-js .main-banner .active-banner .transition-overlay > div {
          position: relative;
          width: 100%;
          height: 100%; }
          .banner-js .main-banner .active-banner .transition-overlay > div .text-details {
            color: #fff;
            -webkit-flex: 1 1;
                    flex: 1 1; }
          .banner-js .main-banner .active-banner .transition-overlay > div .image-details {
            height: 100%;
            position: relative; }
            .banner-js .main-banner .active-banner .transition-overlay > div .image-details img {
              position: absolute;
              left: 0;
              top: 0;
              object-fit: contain;
              object-position: center;
              width: 100%;
              height: 100%; }
            .banner-js .main-banner .active-banner .transition-overlay > div .image-details .embed_video {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: #111; }
      .banner-js .main-banner .active-banner.in-transition .active-image > div .text-details {
        opacity: 0;
        -webkit-transform: translateY(20%);
                transform: translateY(20%);
        -webkit-animation-name: flyIn;
                animation-name: flyIn;
        -webkit-animation-duration: 0.6s;
                animation-duration: 0.6s;
        -webkit-animation-delay: 0.5s;
                animation-delay: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
      .banner-js .main-banner .active-banner.in-transition .active-image > div .image-details {
        opacity: 0;
        -webkit-transform: translateY(20%);
                transform: translateY(20%);
        -webkit-animation-name: flyIn;
                animation-name: flyIn;
        -webkit-animation-duration: 0.6s;
                animation-duration: 0.6s;
        -webkit-animation-delay: 0.8s;
                animation-delay: 0.8s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
      .banner-js .main-banner .active-banner.in-transition .transition-overlay {
        width: 100%; }
        .banner-js .main-banner .active-banner.in-transition .transition-overlay > div .text-details {
          transition: 0.6s;
          -webkit-transform: translateY(-20%);
                  transform: translateY(-20%);
          opacity: 0; }
        .banner-js .main-banner .active-banner.in-transition .transition-overlay > div .image-details {
          transition: 0.6s;
          transition-delay: 0.3s;
          -webkit-transform: translateY(-20%);
                  transform: translateY(-20%);
          opacity: 0; }
  .banner-js .banner-thumbnails {
    position: absolute;
    width: 100%;
    bottom: 20px;
    left: 0; }
    .banner-js .banner-thumbnails .thumb {
      width: 50px;
      height: 5px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.5);
      margin: 0 10px;
      position: relative;
      overflow: hidden; }
      .banner-js .banner-thumbnails .thumb::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #fff;
        -webkit-transform: translateX(100%);
                transform: translateX(100%); }
      .banner-js .banner-thumbnails .thumb.active {
        pointer-events: none; }
        .banner-js .banner-thumbnails .thumb.active::after {
          -webkit-transform: translateX(0);
                  transform: translateX(0); }
    .banner-js .banner-thumbnails.next {
      pointer-events: none; }
      .banner-js .banner-thumbnails.next .thumb.active-out::after {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-animation-name: thumbOutNext;
                animation-name: thumbOutNext;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
      .banner-js .banner-thumbnails.next .thumb.active::after {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-animation-name: thumbInNext;
                animation-name: thumbInNext;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
    .banner-js .banner-thumbnails.prev {
      pointer-events: none; }
      .banner-js .banner-thumbnails.prev .thumb.active-out::after {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-animation-name: thumbOutPrev;
                animation-name: thumbOutPrev;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
      .banner-js .banner-thumbnails.prev .thumb.active::after {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-animation-name: thumbInPrev;
                animation-name: thumbInPrev;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
  @media (max-width: 991px) {
    .banner-js.banner-slider .main-banner {
      position: relative; }
      .banner-js.banner-slider .main-banner .active-banner .active-image > div,
      .banner-js.banner-slider .main-banner .active-banner .transition-overlay > div {
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse; }
        .banner-js.banner-slider .main-banner .active-banner .active-image > div .image-details,
        .banner-js.banner-slider .main-banner .active-banner .transition-overlay > div .image-details {
          width: 100%;
          margin: 20px 0; } }
  @media (max-width: 767px) {
    .banner-js.banner-slider .banner-thumbnails {
      position: relative;
      margin-bottom: 20px;
      width: 100%; } }

@-webkit-keyframes flyIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(20%);
            transform: translateY(20%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@keyframes flyIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(20%);
            transform: translateY(20%); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes thumbOutNext {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@keyframes thumbOutNext {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@-webkit-keyframes thumbOutPrev {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@keyframes thumbOutPrev {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@-webkit-keyframes thumbInNext {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@keyframes thumbInNext {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes thumbInPrev {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@keyframes thumbInPrev {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

.product-card-js.product-card {
  position: relative;
  cursor: pointer; }
  .product-card-js.product-card .image-container {
    margin-bottom: 15px;
    position: relative;
    background: #F47920;
    overflow: hidden; }
    .product-card-js.product-card .image-container img {
      width: 100%;
      object-fit: contain;
      object-position: center;
      transition: 0.3s;
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    .product-card-js.product-card .image-container .loading-image-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #ccc; }
    .product-card-js.product-card .image-container .hover-overlay {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      background: #282828;
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      transition: 0.3s; }
  .product-card-js.product-card .discount-badge {
    position: absolute;
    -webkit-transform: translateX(-42.5%);
            transform: translateX(-42.5%);
    top: 0;
    width: 400px; }
    .product-card-js.product-card .discount-badge > div {
      -webkit-transform: rotate(-45deg) scale(0.6);
              transform: rotate(-45deg) scale(0.6);
      background: #ff3333;
      width: 100%; }
  .product-card-js.product-card .category {
    color: #f3f3f3;
    font-size: 0.625rem;
    font-family: "librefranklin-semibold";
    margin: 0; }
  .product-card-js.product-card .product-title {
    margin: 0;
    padding: 10px 0 5px;
    color: #282828; }
  .product-card-js.product-card .product-price {
    color: #282828;
    padding-bottom: 10px; }
  .product-card-js.product-card .product-star {
    color: #F47920; }
    .product-card-js.product-card .product-star i {
      margin-right: 2px; }
  .product-card-js.product-card:hover .image-container img {
    -webkit-transform: scale(1);
            transform: scale(1); }
  .product-card-js.product-card:hover .image-container .hover-overlay {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .product-card-js.product-card.ph-item .image-container {
    background-color: #f3f3f3; }

@media (max-width: 550px) {
  .product-card-js.product-card .discount-badge {
    -webkit-transform: translateX(-46%);
            transform: translateX(-46%); }
    .product-card-js.product-card .discount-badge .txt-medium {
      display: none; } }

.product-list {
  grid-gap: 30px 50px;
  padding-bottom: 50px; }

.auto-suggest.search-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 50px;
  border: solid 1px #F47920;
  padding: 10px 20px;
  overflow: hidden; }
  .auto-suggest.search-container > i {
    color: #F47920; }
  .auto-suggest.search-container input {
    border: 0;
    height: 100%;
    width: calc(100% - 15px);
    font-size: 12px; }
    .auto-suggest.search-container input:focus {
      box-shadow: none; }

.elements.free-shipping-container {
  display: -webkit-flex;
  display: flex;
  color: #477625; }
  .elements.free-shipping-container > i {
    font-size: 26px;
    -webkit-align-self: center;
            align-self: center; }
  .elements.free-shipping-container .text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 12px;
    padding: 5px 10px; }
    .elements.free-shipping-container .text .main {
      font-family: "librefranklin-bold";
      font-size: 14px;
      line-height: 14px; }

.modal-login-button {
  cursor: pointer; }
  .modal-login-button i {
    transition: 0.3s; }
  .modal-login-button:hover i {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }

.cart-bag-container .bag-icon-container {
  height: 100%;
  color: #747474;
  position: relative;
  font-size: 25px;
  cursor: pointer; }
  .cart-bag-container .bag-icon-container .bag-count {
    background-color: white;
    color: #F47920;
    border-radius: 100%;
    border: 1px solid #F47920;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    right: -5px;
    top: -5px;
    position: absolute;
    transition: 0.3s; }
  .cart-bag-container .bag-icon-container:hover .bag-count {
    top: -10px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

.cart-bag-container .cart-bag-dropdown-menu {
  margin-top: 0.5rem;
  padding: 20px 15px; }
  .cart-bag-container .cart-bag-dropdown-menu .cart-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .cart-bag-container .cart-bag-dropdown-menu .cart-header > div:first-child {
      fleX: 1; }
  .cart-bag-container .cart-bag-dropdown-menu .item-list {
    max-height: 300px;
    overflow: auto; }
  .cart-bag-container .cart-bag-dropdown-menu .cart-item {
    position: relative;
    border-radius: 5px;
    border: 1px solid #f3f3f3;
    margin-bottom: 10px;
    padding: 10px;
    width: 370px; }
    .cart-bag-container .cart-bag-dropdown-menu .cart-item .image-container {
      width: 50px;
      height: 50px;
      position: relative;
      margin-right: 10px; }
      .cart-bag-container .cart-bag-dropdown-menu .cart-item .image-container img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: contain;
        object-position: center;
        background-color: #aaa; }
    .cart-bag-container .cart-bag-dropdown-menu .cart-item .item-details {
      -webkit-flex: 1 1;
              flex: 1 1;
      position: relative; }
      .cart-bag-container .cart-bag-dropdown-menu .cart-item .item-details .delete-item {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        transition: 0.6s;
        opacity: 1; }
        .cart-bag-container .cart-bag-dropdown-menu .cart-item .item-details .delete-item:hover {
          opacity: .6; }
      .cart-bag-container .cart-bag-dropdown-menu .cart-item .item-details .item-title {
        font-size: 0.875rem; }
      .cart-bag-container .cart-bag-dropdown-menu .cart-item .item-details .item-qty {
        font-size: 0.875rem; }
      .cart-bag-container .cart-bag-dropdown-menu .cart-item .item-details .item-price {
        font-size: 0.75rem; }
  .cart-bag-container .cart-bag-dropdown-menu .seperator {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 1px;
    background-color: #f3f3f3; }
  .cart-bag-container .cart-bag-dropdown-menu .cart-subtotal-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0; }
    .cart-bag-container .cart-bag-dropdown-menu .cart-subtotal-container .subtotal-number {
      text-align: right; }
  .cart-bag-container .cart-bag-dropdown-menu .button-container .btn:last-child {
    margin-left: auto; }

.cart-bag-container .user-links-dropdown {
  margin-top: 0.5rem;
  padding: 20px 15px;
  max-width: 400px;
  font-size: 12px; }
  .cart-bag-container .user-links-dropdown ul li {
    padding: 10px 0;
    position: relative; }
    .cart-bag-container .user-links-dropdown ul li i {
      width: 20px;
      color: #747474;
      transition: 0.3s; }
    .cart-bag-container .user-links-dropdown ul li:hover i:not(.user-indicator) {
      -webkit-transform: scale(1.3);
              transform: scale(1.3);
      color: #F47920; }

@media (max-width: 550px) {
  .cart-bag-container .cart-bag-dropdown-menu .cart-item {
    max-width: 270px; } }

@media (max-height: 500px) {
  .cart-bag-container .cart-bag-dropdown-menu .item-list {
    max-height: 200px; } }

.template1 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; }
  .template1:before {
    content: '';
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); }
  .template1 > .container {
    color: #fff;
    padding-top: 120px;
    padding-bottom: 150px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    position: relative;
    grid-gap: 20px; }
    .template1 > .container .title {
      font-family: "BebasNeue-Bold";
      font-size: 2.5rem;
      line-height: 2rem;
      grid-column: 1/3; }
    .template1 > .container .description {
      font-size: 0.875rem;
      max-width: 440px; }
    .template1 > .container .link-container {
      -webkit-align-self: center;
              align-self: center; }
      .template1 > .container .link-container .btn {
        font-size: 0.75rem;
        width: 162px; }
  @media (max-width: 768px) {
    .template1 > .container {
      grid-template-columns: 1fr; }
      .template1 > .container .title {
        grid-column: unset; } }

.template2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  overflow: hidden; }
  .template2 .background-wrapper {
    position: relative;
    overflow: hidden; }
    .template2 .background-wrapper .background {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transition: 0.6s; }
    .template2 .background-wrapper:hover .background {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
  .template2 a {
    padding: 70px 60px;
    color: #fff;
    position: relative;
    z-index: 1;
    font-family: "librefranklin-semibold";
    font-size: 1.125rem;
    line-height: 1.125rem; }
    .template2 a .margin-right {
      margin-right: 20px; }

.product-review-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px 60px; }
  .product-review-container .btn {
    max-width: 200px;
    margin: auto; }
  .product-review-container .user-image-container .user-image {
    width: 75px;
    height: 75px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%; }
  .product-review-container .product-review-content .product-review-form .rating-buttons {
    padding-bottom: 20px; }
  .product-review-container .product-review-content .product-review-form .form-group {
    margin-bottom: 30px; }
  .product-review-container h1 {
    font-size: 1.5rem;
    padding-top: 20px;
    margin: 0;
    font-family: "librefranklin-regular";
    grid-column: 1/3;
    color: #477625; }
  .product-review-container .product-review-list {
    grid-column: 1/3; }
    .product-review-container .product-review-list li {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 15px 60px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: solid 1px #f3f3f3; }
      .product-review-container .product-review-list li:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0; }
      .product-review-container .product-review-list li .review-header {
        display: grid;
        grid-template-columns: 1fr 1fr; }
        .product-review-container .product-review-list li .review-header .name {
          font-family: "librefranklin-lightitalic"; }
        .product-review-container .product-review-list li .review-header .date {
          font-size: 0.625rem;
          color: #f3f3f3;
          text-align: right; }
      .product-review-container .product-review-list li .review-description {
        font-size: 0.8125rem;
        padding-top: 15px; }
  @media (max-width: 600px) {
    .product-review-container {
      grid-template-columns: 1fr; }
      .product-review-container h1, .product-review-container .product-review-list {
        grid-column: unset; }
      .product-review-container .product-review-list li {
        grid-template-columns: 1fr; } }

.article-card .img-container {
  background: #ccc; }
  .article-card .img-container img {
    width: 100%;
    object-fit: cover;
    object-position: center; }

.home .steps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  margin-top: 30px;
  padding: 0 50px; }
  .home .steps > div {
    padding: 10px; }
  .home .steps > div:nth-child(2) {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc; }

.home .partner-list .partner-card {
  text-align: center; }
  .home .partner-list .partner-card img {
    max-height: 80px;
    max-width: 100%;
    margin: auto;
    object-fit: contain;
    object-position: center; }

@media (max-width: 991px) {
  .home .featured-collection .banner-js .main-banner {
    height: auto; }
  .home .featured-collection .banner-js .banner-footer {
    grid-template-columns: 1fr; }
    .home .featured-collection .banner-js .banner-footer .next-preview {
      -webkit-justify-content: center;
              justify-content: center; }
  .home .featured-collection .banner-js .link-to-collection {
    text-align: center !important;
    margin-bottom: 20px; } }

@media (max-width: 767px) {
  .home .steps {
    grid-template-columns: 1fr; }
    .home .steps > div {
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-top: 10px; } }

@media (max-width: 550px) {
  .home .featured-products {
    grid-template-columns: 1fr 1fr; }
    .home .featured-products .featured-product-card {
      height: 50vw; }
      .home .featured-products .featured-product-card .flex-end .btn {
        font-size: 10px; } }

.about .team-member .img-container {
  text-align: center; }
  .about .team-member .img-container img {
    width: 60%;
    object-fit: cover;
    object-position: center;
    border-radius: 100%; }

@media (max-width: 767px) {
  .about .main-article {
    display: block; }
    .about .main-article .txt-header {
      margin-bottom: 20px; }
  .about .team-member-list {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px 20px; } }

@media (max-width: 550px) {
  .about .team-member-list {
    grid-template-columns: 1fr; } }

.login {
  max-width: 500px;
  width: 100%;
  margin: auto; }
  .login .icon-badge {
    text-align: center; }
    .login .icon-badge i {
      width: 80px;
      height: 80px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      border: 5px solid #F47920;
      background: #F47920;
      color: #fff;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      border-radius: 100%;
      font-size: 40px;
      margin: auto;
      box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.3); }
  .login .login-header {
    font-weight: bold; }
  .login .login-form {
    padding: 20px; }
    .login .login-form .form-group {
      margin-bottom: 20px; }
  .login .submit-btn {
    margin-top: 20px; }
  .login .registration-link {
    margin-top: 20px; }
  @media (max-width: 767px) {
    .login .icon-badge i {
      width: 60px;
      height: 60px;
      font-size: 30px; } }
  @media (max-width: 450px) {
    .login .login-options-container {
      display: block; }
      .login .login-options-container .forgot-password {
        text-align: center !important;
        border: 1px solid #c2c2c2;
        padding: 5px;
        margin-top: 20px; } }

.product-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding-bottom: 30px;
  margin-bottom: 50px; }
  .product-view .line-separator {
    position: relative; }
    .product-view .line-separator:before {
      content: '';
      position: absolute;
      top: 0;
      right: -50%;
      width: 200%;
      height: 1px;
      background: #f3f3f3; }
  .product-view .product-specifications {
    padding-left: 15px; }
    .product-view .product-specifications li {
      list-style-type: disc; }
  .product-view .related-product-list {
    grid-column: 1/3;
    border-top: 1px solid #eee;
    padding-top: 30px;
    margin-top: 60px; }
  .product-view .product-review-container {
    grid-column: 1/3;
    padding-top: 60px; }
  .product-view .product-images {
    margin: 0; }
    .product-view .product-images .main-image {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
      padding-top: 70%;
      height: 0;
      transition: 0.6s;
      width: calc(100% - 15px); }
    .product-view .product-images .thumbnail-list .thumbnail {
      width: calc(100% - 30px);
      height: 0;
      padding-top: 70%;
      border: solid 1px #ced4da;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin: auto;
      transition: 0.3s;
      cursor: pointer; }
      .product-view .product-images .thumbnail-list .thumbnail.selected {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.2); }
    .product-view .product-images .slick-list {
      padding: 30px 0; }
    .product-view .product-images.ph-item .main-image,
    .product-view .product-images.ph-item .thumbnail {
      background-color: #ced4da; }
  .product-view .product-info {
    padding: 0 30px; }
    .product-view .product-info .actions-container .action-qty input {
      width: 100px; }
    .product-view .product-info .actions-container .action-buttons button {
      max-width: 250px; }
    .product-view .product-info .description-container {
      margin-top: 30px;
      line-height: 2; }
  .product-view .overall-rating {
    border-radius: 10px;
    border: 1px solid #eee;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.1);
    background: #f5f5f5;
    padding: 10px 15px; }
  .product-view .product-stars {
    color: #F47920; }
    .product-view .product-stars i {
      margin-right: 2px; }
  @media (max-width: 1400px) {
    .product-view .product-images .main-image {
      width: 100%;
      height: 300px;
      padding-top: 0; } }
  @media (max-width: 991px) {
    .product-view {
      grid-template-columns: 1fr; }
      .product-view .related-product-list, .product-view .product-review-container {
        grid-column: unset; } }
  @media (max-width: 767px) {
    .product-view .product-info {
      padding: 0; }
    .product-view .product-images .main-image {
      height: 200px; } }
  @media (max-width: 650px) {
    .product-view > div {
      margin: 0 7.5px; } }

.products-page {
  padding-top: 56px; }
  .products-page .main-products-content {
    margin-left: 100px; }
    .products-page .main-products-content .category-header {
      height: 400px;
      background-size: cover;
      background-position: center center; }
  .products-page .product-filter-category-list {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    max-width: 300px; }
    .products-page .product-filter-category-list .mobile-category-toggle {
      position: fixed;
      bottom: 20px;
      right: 0px;
      z-index: 100;
      display: none;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      transition: 0.3s; }
    .products-page .product-filter-category-list .category-list {
      padding: 0 50px; }
    .products-page .product-filter-category-list .category-container {
      padding: 15px 20px;
      opacity: .6;
      transition: 0.6s;
      cursor: pointer; }
      .products-page .product-filter-category-list .category-container .category-title {
        text-overflow: ellipsis;
        overflow: hidden; }
      .products-page .product-filter-category-list .category-container .sub-category-options {
        padding: 0 20px;
        transition: 0.3s;
        max-height: 0px;
        overflow: hidden; }
        .products-page .product-filter-category-list .category-container .sub-category-options .sub-option {
          padding-top: 10px; }
          .products-page .product-filter-category-list .category-container .sub-category-options .sub-option span:hover {
            font-weight: bold; }
  @media (max-width: 991px) {
    .products-page {
      padding-top: 20px; }
      .products-page .page-container {
        padding-top: 0px; }
      .products-page .main-products-content {
        margin-left: 0; }
        .products-page .main-products-content .category-header {
          height: 300px;
          background-size: cover;
          background-position: center center; }
      .products-page .product-filter-category-list {
        max-width: 100%; }
        .products-page .product-filter-category-list .mobile-category-toggle {
          display: block;
          -webkit-transform: translateX(-20px);
                  transform: translateX(-20px); }
        .products-page .product-filter-category-list .category-nav-list {
          position: fixed;
          width: 300px;
          height: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 99999;
          background: #eee;
          padding: 30px;
          overflow: auto;
          transition: 0.3s;
          -webkit-transform: translateX(-100%);
                  transform: translateX(-100%); }
          .products-page .product-filter-category-list .category-nav-list.in {
            -webkit-transform: translateX(0%);
                    transform: translateX(0%); } }
  @media (max-width: 767px) {
    .products-page .main-products-content .category-header {
      height: 200px; } }

.ingredients-selection {
  padding: 100px 0 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .ingredients-selection .slider-container {
    width: 100%;
    overflow: hidden;
    padding: 20px 0; }
    .ingredients-selection .slider-container .slick-list {
      overflow: visible; }
    .ingredients-selection .slider-container .ingredient-option {
      width: 120px;
      margin: auto;
      border-radius: 100%;
      overflow: hidden;
      transition: 0.3s;
      cursor: pointer;
      -webkit-animation-name: growIn;
              animation-name: growIn;
      -webkit-animation-duration: 0.3s;
              animation-duration: 0.3s;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      -webkit-transform: scale(0);
              transform: scale(0); }
      .ingredients-selection .slider-container .ingredient-option img {
        width: 100%;
        height: 120px;
        object-fit: cover;
        object-position: center; }
      .ingredients-selection .slider-container .ingredient-option:hover, .ingredients-selection .slider-container .ingredient-option.active {
        box-shadow: 0 0 0 10px #477625; }
  .ingredients-selection .slider-control {
    position: relative;
    z-index: 2;
    opacity: 0.3;
    font-size: 50px;
    margin: 0 50px;
    text-align: center;
    transition: 0.3s;
    cursor: pointer; }
    .ingredients-selection .slider-control:hover {
      opacity: 0.7; }

.ingredient-details {
  margin-top: 50px; }
  .ingredient-details .text-details .ingredient-name {
    color: #fff;
    padding: 50px;
    position: relative;
    max-width: 500px;
    width: 100%; }
    .ingredient-details .text-details .ingredient-name h1 {
      font-size: 40px; }
    .ingredient-details .text-details .ingredient-name .overlay-display {
      background-color: #477625;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 130%;
      padding: 50px; }
      .ingredient-details .text-details .ingredient-name .overlay-display .shop-link {
        font-size: 12px; }
  .ingredient-details .text-details .ingredient-description {
    padding: 50px;
    width: 100%; }
  .ingredient-details .ingredient-bg {
    height: 300px;
    background-color: #ccc;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; }

.stores #map {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.stores .store-control {
  color: #a3a4a4;
  background: #eee;
  border: none;
  box-shadow: none;
  font-size: 20px;
  padding: 20px;
  font-weight: bold;
  transition: 0.3s; }
  .stores .store-control.active {
    color: #fff;
    background: #477625; }
  .stores .store-control:not(.active):hover {
    opacity: 0.6; }

.stores .stores-list {
  margin-top: 30px;
  grid-gap: 20px; }
  .stores .stores-list .store-cell {
    padding: 10px 5px;
    font-size: 14px; }
    .stores .stores-list .store-cell .contact-details {
      opacity: 0.4;
      margin-top: 5px; }

.blogs {
  padding-bottom: 50px; }
  .blogs .page-banner-image .banner-text {
    max-width: 800px;
    width: 100%; }
    .blogs .page-banner-image .banner-text .background-overlay {
      background: rgba(0, 0, 0, 0.5);
      padding: 20px; }
  .blogs .blog-list {
    grid-gap: 30px; }
  .blogs .share-list i {
    width: 35px;
    height: 35px;
    background: #f3f3f3;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100%;
    transition: 0.3s; }
    .blogs .share-list i:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      background: #282828;
      color: #fff; }
  .blogs .blog-content {
    min-width: 800px; }
    .blogs .blog-content .author-details .author-image img {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      object-fit: cover;
      object-position: center; }
  .blogs .related-products {
    max-width: 300px;
    width: 100%; }
  .blogs .related-articles {
    border-top: 2px solid #f3f3f3; }
  @media (max-width: 1200px) {
    .blogs .blog-content {
      min-width: 700px; } }
  @media (max-width: 991px) {
    .blogs .banner-text {
      padding: 50px; }
    .blogs .blog-view-outer-container {
      display: block; }
      .blogs .blog-view-outer-container .blog-content {
        min-width: 0;
        min-width: initial;
        margin: auto; }
    .blogs .related-products {
      max-width: 100%; }
      .blogs .related-products .related-products-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px; } }
  @media (max-width: 767px) {
    .blogs .banner-text {
      padding: 20px; }
    .blogs .grid-3 {
      grid-template-columns: 1fr 1fr; }
    .blogs .blog-content .author-details {
      text-align: center;
      display: block; }
      .blogs .blog-content .author-details .margin-left-20 {
        margin-left: 0px;
        margin-top: 10px; } }
  @media (max-width: 550px) {
    .blogs .grid-3 {
      grid-template-columns: 1fr; }
      .blogs .grid-3 .article-card {
        margin-top: 30px; }
        .blogs .grid-3 .article-card .btn {
          display: block;
          margin-left: auto;
          margin-right: auto; }
    .blogs .related-products .related-products-container {
      grid-template-columns: 1fr 1fr; } }
  @media (max-width: 360px) {
    .blogs .banner-text .btn {
      display: block;
      margin-left: auto;
      margin-right: auto; }
    .blogs .related-products .related-products-container {
      grid-template-columns: 1fr; } }

.contact-us-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-left: 0;
  padding-right: 0;
  margin-top: 50px; }

.contact-us .contact-info i {
  width: 20px;
  margin-right: 5px; }

.contact-us .contact-form {
  max-width: 600px;
  width: 100%; }
  .contact-us .contact-form .submit-btn {
    max-width: 300px; }

@media (max-width: 991px) {
  .contact-us-container {
    display: block;
    padding: 20px 50px; }
    .contact-us-container > div {
      padding: 0; }
      .contact-us-container > div.form-header {
        text-align: center; }
        .contact-us-container > div.form-header .contact-info {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center;
          margin-top: 20px; }
      .contact-us-container > div.contact-form {
        margin: 50px auto 0; } }

@media (max-width: 767px) {
  .contact-us-container {
    display: block;
    padding: 20px; }
    .contact-us-container > div {
      padding: 0; }
      .contact-us-container > div.form-header .contact-info {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        margin-top: 20px;
        text-align: center;
        font-size: 12px; }
        .contact-us-container > div.form-header .contact-info i {
          display: block;
          margin: 15px auto 5px;
          font-size: 20px; }
      .contact-us-container > div.contact-form {
        margin: 50px auto 0; } }

.account .account-page-control {
  color: #a3a4a4;
  background: #eee;
  border: none;
  box-shadow: none;
  font-size: 20px;
  padding: 20px;
  font-weight: bold;
  transition: 0.3s; }
  .account .account-page-control.active {
    color: #fff;
    background: #477625; }
  .account .account-page-control:not(.active):hover {
    opacity: 0.6; }

.account .order-list .order-card {
  border: 1px solid #ccc;
  border-radius: 5px; }
  .account .order-list .order-card.ph-col-12 {
    height: 150px; }
  .account .order-list .order-card-header {
    padding: 20px; }
    .account .order-list .order-card-header .order-collapse {
      transition: 0.3s; }
  .account .order-list .order-card-body {
    padding: 0 20px; }
  .account .order-list .order-card-footer {
    padding: 20px; }
    .account .order-list .order-card-footer .total-container {
      min-width: 150px; }

.account .item-card {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: 0.3s; }
  .account .item-card .item-img {
    width: 40px;
    height: 40px;
    border: 1px solid #f3f3f3;
    margin-right: 10px; }
    .account .item-card .item-img img {
      width: 30px;
      height: 30px;
      object-fit: contain;
      object-position: center; }
  .account .item-card .remove-btn {
    transition: 0.3s; }
    .account .item-card .remove-btn:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
  .account .item-card.deleting {
    pointer-events: none;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    opacity: 0.8; }
  .account .item-card.ph-col-12 {
    height: 60px; }

.account .user-info {
  padding-bottom: 50px; }
  .account .user-info .react-datepicker-wrapper {
    width: 100%; }
  .account .user-info .gender {
    border: none;
    width: 50px;
    height: 50px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    max-width: 200px;
    overflow: hidden;
    transition: 0.5s;
    border-radius: 100%; }
    .account .user-info .gender > .text-center {
      border-radius: 100%;
      width: 50px;
      height: 50px;
      color: #fff;
      background-color: #ccc;
      transition: 0.5s; }
    .account .user-info .gender:hover[value=Male] > .text-center, .account .user-info .gender.active[value=Male] > .text-center {
      background-color: #1dbed5; }
    .account .user-info .gender:hover[value=Female] > .text-center, .account .user-info .gender.active[value=Female] > .text-center {
      background-color: #f36fa9; }
    .account .user-info .gender:hover[value=LGBTQA] > .text-center, .account .user-info .gender.active[value=LGBTQA] > .text-center {
      background-color: #F19F19; }
    .account .user-info .gender.gender-hide:not(.active) {
      max-width: 0;
      opacity: 0; }
      .account .user-info .gender.gender-hide:not(.active) > .text-center {
        -webkit-transform: scale(0);
                transform: scale(0); }
  .account .user-info .password-form button,
  .account .user-info .shipping-form button {
    min-width: 200px; }
  .account .user-info .profile-form button {
    min-width: 200px; }
  .account .user-info .profile-form .profile-img-container {
    padding: 20px; }
    .account .user-info .profile-form .profile-img-container .profile-img {
      width: 200px;
      height: 200px;
      position: relative;
      margin: auto; }
      .account .user-info .profile-form .profile-img-container .profile-img img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
        background-color: #ccc; }
      .account .user-info .profile-form .profile-img-container .profile-img .img-placeholder {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: 5px solid #477625;
        color: #477625;
        border-radius: 100%;
        font-size: 70px; }
  .account .user-info .wishlist-container {
    grid-gap: 30px; }

.checkout-page {
  padding-top: 50px;
  margin-bottom: 30px; }
  .checkout-page > .container {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 500px);
    grid-gap: 30px;
    max-width: 1400px; }
  .checkout-page .shipping-zone-list {
    margin-top: 30px; }
  .checkout-page .custom-breadcrumbs {
    display: -webkit-flex;
    display: flex;
    grid-column: 1/3; }
    .checkout-page .custom-breadcrumbs .text {
      font-size: 1.75rem;
      font-family: "librefranklin-light";
      display: -webkit-flex;
      display: flex; }
      .checkout-page .custom-breadcrumbs .text::after {
        content: "\F105";
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
        padding: 0 15px; }
      .checkout-page .custom-breadcrumbs .text:last-child::after {
        content: ''; }
  .checkout-page .header {
    font-size: 1.75rem;
    grid-column: 1/3;
    font-family: "librefranklin-medium"; }
  .checkout-page .cards-container {
    grid-gap: 15px; }
    .checkout-page .cards-container .card-container {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr;
      border: solid 1px #f3f3f3;
      overflow: hidden;
      font-size: 0.875rem; }
      .checkout-page .cards-container .card-container > div {
        border-bottom: solid 1px #f3f3f3; }
      .checkout-page .cards-container .card-container h1 {
        font-size: 1.25rem;
        font-family: "librefranklin-light";
        margin: 0;
        padding: 10px;
        border-bottom: solid 1px #f3f3f3; }
      .checkout-page .cards-container .card-container .loading-container .ph-item {
        height: 16px;
        background-color: #f3f3f3; }
      .checkout-page .cards-container .card-container .list-header {
        background-color: #ddd;
        font-family: "librefranklin-bold"; }
      .checkout-page .cards-container .card-container .address {
        padding: 10px 0;
        text-transform: capitalize; }
      .checkout-page .cards-container .card-container .gray-bg {
        background-color: #f8f8f8; }
        .checkout-page .cards-container .card-container .gray-bg .list-header {
          background-color: #f8f8f8; }
      .checkout-page .cards-container .card-container .column-3 {
        grid-column: 1/4; }
        .checkout-page .cards-container .card-container .column-3.with-action {
          display: grid;
          grid-template-columns: 1fr auto; }
          .checkout-page .cards-container .card-container .column-3.with-action > .action {
            color: #477625;
            font-size: 0.75rem;
            cursor: pointer; }
      .checkout-page .cards-container .card-container .column-2 {
        grid-column: 1/3; }
      .checkout-page .cards-container .card-container .total {
        font-size: 1rem;
        font-family: "librefranklin-bold";
        border: 0;
        margin-top: auto; }
      .checkout-page .cards-container .card-container .btn {
        border-radius: 0; }
  .checkout-page .coupon-container .form-group input {
    height: 100%; }
  .checkout-page .coupon-container .btn {
    -webkit-align-self: start;
            align-self: start;
    height: 38px; }
  .checkout-page .payment-method .collapse-header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    border: solid 1px #f3f3f3;
    padding: 10px;
    grid-gap: 20px;
    font-size: 1.25rem;
    margin-bottom: 15px;
    cursor: pointer;
    transition: 0.3s; }
    .checkout-page .payment-method .collapse-header img {
      width: 30px; }
    .checkout-page .payment-method .collapse-header i {
      font-size: 1.875rem; }
      .checkout-page .payment-method .collapse-header i.fa-check-circle {
        opacity: 0;
        transition: 0.6s;
        color: #477625; }
    .checkout-page .payment-method .collapse-header > * {
      -webkit-align-self: center;
              align-self: center; }
    .checkout-page .payment-method .collapse-header.active .fa-check-circle {
      opacity: 1; }
    .checkout-page .payment-method .collapse-header:hover {
      -webkit-transform: scale(1.01);
              transform: scale(1.01);
      box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.2); }
  .checkout-page .payment-method .collapse-content {
    border: solid 1px #f3f3f3;
    margin-bottom: 15px;
    padding: 20px; }
    .checkout-page .payment-method .collapse-content .important {
      color: #F47920; }
  .checkout-page.my-cart .container {
    grid-template-columns: 1fr; }
  .checkout-page .zoid-outlet {
    width: 100% !important; }
  .checkout-page .location-card {
    border-radius: 10px;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.1);
    border: 1px solid #eee;
    background: #fff;
    transition: 0.2s; }
    .checkout-page .location-card.inactive {
      background: #f5f5f5; }
    .checkout-page .location-card:hover {
      -webkit-transform: scale(1.02);
              transform: scale(1.02); }
  @media (max-width: 1080px) {
    .checkout-page > .container {
      grid-gap: 30px; } }
  @media (max-width: 767px) {
    .checkout-page > .container {
      grid-template-columns: 1fr; }
      .checkout-page > .container .custom-breadcrumbs,
      .checkout-page > .container .header {
        grid-column: unset; }
      .checkout-page > .container .custom-breadcrumbs {
        grid-template-columns: auto auto auto; }
        .checkout-page > .container .custom-breadcrumbs .text {
          font-size: 16px; }
          .checkout-page > .container .custom-breadcrumbs .text::after {
            padding: 0 10px; } }
  @media (max-width: 360px) {
    .checkout-page > .container .custom-breadcrumbs .text {
      font-size: 12px; } }

.order-status-page {
  padding-top: 120px;
  min-height: 500px; }
  .order-status-page h1 {
    font-family: "librefranklin-medium"; }
  @media (max-width: 991px) {
    .order-status-page {
      padding-top: 140px; } }
  .order-status-page .order-image-list {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: row;
            flex-direction: row;
    max-width: 460px;
    margin: auto;
    padding-top: 15px;
    margin-bottom: 75px; }
    .order-status-page .order-image-list li {
      max-width: 80px;
      height: 80px;
      -webkit-flex: 1 1 80px;
              flex: 1 1 80px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      margin-right: 15px;
      margin-bottom: 15px; }

@media (max-width: 991px) {
  .t-and-c .page-container > .flex > div {
    margin: 0;
    padding: 0; }
    .t-and-c .page-container > .flex > div.tc-page-links {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 40px; }
      .t-and-c .page-container > .flex > div.tc-page-links > div {
        margin: 0;
        text-align: center; }
        .t-and-c .page-container > .flex > div.tc-page-links > div:last-child {
          border-left: 2px solid #ccc; } }

@media (max-width: 767px) {
  .t-and-c .page-container > .flex > div.tc-page-links {
    grid-template-columns: 1fr;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto; }
    .t-and-c .page-container > .flex > div.tc-page-links > div {
      padding: 10px;
      border: 1px solid #c2c2c2;
      margin-bottom: 20px; } }

.my-cart-page-container .custom-table {
  display: grid;
  grid-template-columns: auto 1fr 2fr auto 1.5fr auto;
  border: solid 1px #f3f3f3;
  border-bottom: 0; }
  .my-cart-page-container .custom-table > div {
    padding: 15px;
    border-bottom: solid 1px #f3f3f3; }
  .my-cart-page-container .custom-table .table-header {
    background-color: #f3f3f3;
    font-family: "librefranklin-bold"; }
  .my-cart-page-container .custom-table .image .product-image {
    max-width: 50px; }
  .my-cart-page-container .custom-table .edit-qty-container {
    display: grid;
    grid-template-columns: 40px 70px 40px; }
    .my-cart-page-container .custom-table .edit-qty-container .btn {
      padding: 5px 0;
      border-radius: 0; }
      .my-cart-page-container .custom-table .edit-qty-container .btn i {
        transition: 0.3s; }
      .my-cart-page-container .custom-table .edit-qty-container .btn:hover i {
        -webkit-transform: scale(1.4);
                transform: scale(1.4);
        color: inherit; }
    .my-cart-page-container .custom-table .edit-qty-container input {
      text-align: center; }

.my-cart-page-container .mobile-controls {
  display: none; }

@media (max-width: 767px) {
  .my-cart-page-container .custom-table {
    grid-template-columns: 1fr 2fr auto 1fr auto; }
    .my-cart-page-container .custom-table .table-header,
    .my-cart-page-container .custom-table .sku,
    .my-cart-page-container .custom-table .product-description {
      display: none; } }

@media (max-width: 600px) {
  .my-cart-page-container .custom-table {
    grid-template-columns: auto 2fr; }
    .my-cart-page-container .custom-table .item-price {
      display: none; }
    .my-cart-page-container .custom-table .edit-qty-container {
      grid-template-columns: 30px 40px 30px; }
      .my-cart-page-container .custom-table .edit-qty-container .btn {
        padding: 5px 0; }
  .my-cart-page-container .desktop-controls {
    display: none; }
  .my-cart-page-container .mobile-controls {
    display: block; }
    .my-cart-page-container .mobile-controls.flex {
      display: -webkit-flex;
      display: flex; }
  .my-cart-page-container .button-container {
    display: block; }
    .my-cart-page-container .button-container .btn {
      display: block;
      margin: 10px auto;
      width: 100%; } }

@media (max-width: 380px) {
  .my-cart-page-container .mobile-controls.flex {
    display: block; }
    .my-cart-page-container .mobile-controls.flex .total {
      text-align: left !important;
      margin-top: 20px; } }

.product-review-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px 60px; }
  .product-review-container .btn {
    max-width: 200px;
    margin: auto; }
  .product-review-container .user-image-container .user-image {
    width: 75px;
    height: 75px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%; }
  .product-review-container .product-review-content .product-review-form .rating-buttons {
    padding-bottom: 20px; }
  .product-review-container .product-review-content .product-review-form .form-group {
    margin-bottom: 30px; }
  .product-review-container h1 {
    font-size: 1.5rem;
    padding-top: 20px;
    margin: 0;
    font-family: "librefranklin-regular";
    grid-column: 1/3;
    color: #477625; }
  .product-review-container .product-review-list {
    grid-column: 1/3; }
    .product-review-container .product-review-list li {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 15px 60px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: solid 1px #f3f3f3; }
      .product-review-container .product-review-list li:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0; }
      .product-review-container .product-review-list li .review-header {
        display: grid;
        grid-template-columns: 1fr 1fr; }
        .product-review-container .product-review-list li .review-header .name {
          font-family: "librefranklin-lightitalic"; }
        .product-review-container .product-review-list li .review-header .date {
          font-size: 0.625rem;
          color: #f3f3f3;
          text-align: right; }
      .product-review-container .product-review-list li .review-description {
        font-size: 0.8125rem;
        padding-top: 15px; }
  @media (max-width: 600px) {
    .product-review-container {
      grid-template-columns: 1fr; }
      .product-review-container h1, .product-review-container .product-review-list {
        grid-column: unset; }
      .product-review-container .product-review-list li {
        grid-template-columns: 1fr; } }

.shipping-details-form-container {
  display: grid;
  grid-gap: 15px; }
  .shipping-details-form-container .form-group {
    display: grid;
    grid-template-columns: 150px 1fr; }
    .shipping-details-form-container .form-group > label {
      margin: 0; }
    .shipping-details-form-container .form-group .invalid-feedback {
      grid-column-start: 2; }
  @media (max-width: 460px) {
    .shipping-details-form-container .form-group {
      grid-template-columns: 1fr; }
      .shipping-details-form-container .form-group > label {
        margin-bottom: 15px; } }

.shipping-zone-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: solid 1px #f0f0f0;
  border-bottom: 0; }
  .shipping-zone-list > div {
    padding: 10px;
    border-bottom: solid 1px #f0f0f0; }
  .shipping-zone-list .list-label {
    background-color: #f0f0f0;
    font-family: "librefranklin-bold"; }
  .shipping-zone-list .zone-name {
    grid-column: 1/4;
    font-family: "librefranklin-medium"; }
  .shipping-zone-list .zone-type {
    padding-left: 15px; }

