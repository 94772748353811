.elements {
    &.free-shipping-container {
        display: flex;
        color: $green;
        > i {
            font-size: 26px;
            align-self: center;
        }
        .text {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            padding: 5px 10px;
            .main {
                font-family: $montBold;
                font-size: 14px;
                line-height: 14px;
            }
        }
    }
}