.t-and-c {
    
    @media (max-width: 991px) {

        .page-container {

            > .flex {

                > div {
                    margin: 0;
                    padding: 0;

                    &.tc-page-links {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        margin-bottom: 40px;

                        > div {
                            margin: 0;
                            text-align: center;

                            &:last-child {
                                border-left: 2px solid #ccc;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {

        .page-container {

            > .flex {

                > div {

                    &.tc-page-links {
                        grid-template-columns: 1fr;
                        max-width: 300px;
                        margin-left: auto;
                        margin-right: auto;
                        
                        > div {
                            padding: 10px;
                            border: 1px solid $medGray;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}