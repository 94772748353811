.shipping-details-form-container {
    display: grid;
    grid-gap: 15px;
    .form-group {
        display: grid;
        grid-template-columns: 150px 1fr;
        > label {
            margin: 0;
        }
        .invalid-feedback {
            grid-column-start: 2;
        }
    }
    @media (max-width: 460px) {
        .form-group {
            grid-template-columns: 1fr;
            > label {
                margin-bottom: 15px;
            }
        }
    }
}