.checkout-page {
    padding-top: 50px;
    margin-bottom: 30px;

    > .container {
        display: grid;
        grid-template-columns: 1fr minmax(auto, 500px);
        grid-gap: 30px;
        max-width: 1400px;
    }

    .shipping-zone-list {
        margin-top: 30px;
    }

    .custom-breadcrumbs {
        display: flex;
        grid-column: 1/3;

        .text {
            font-size: 1.75rem;
            font-family: $montLight;
            display: flex;
            
            &::after {
                content: "\F105";
                font-family: 'Font Awesome 5 Pro';
                font-weight: 400;
                padding: 0 15px;
            }

            &:last-child {
                &::after {
                    content: '';
                }
            }
        }
    }
    .header {
        font-size: 1.75rem;
        grid-column: 1/3;
        font-family: $montMedium;
    }

    .cards-container {
        // display: grid;
        grid-gap: 15px;
        .card-container {
            display: grid;
            grid-template-columns: 2fr 1fr 2fr;
            border: solid 1px $lightGray;
            overflow: hidden;
            font-size: 0.875rem;

            > div {
                border-bottom: solid 1px $lightGray;
            }

            h1 {
                font-size: 1.25rem;
                font-family: $montLight;
                margin: 0;
                padding: 10px;
                border-bottom: solid 1px $lightGray;
            }
            .loading-container {
                .ph-item {
                    height: 16px;
                    background-color: $lightGray;
                }
            }
            .list-header {
                background-color: #ddd;
                font-family: $montBold;
            }
            .address {
                padding: 10px 0;
                text-transform: capitalize;
            }
            .gray-bg {
                background-color: #f8f8f8;
                .list-header {
                    background-color: #f8f8f8;
                }
            }
            .column-3 {
                grid-column: 1/4;
                &.with-action {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    > .action {
                        color: $green;
                        font-size: 0.75rem;
                        cursor: pointer;
                    }
                }
            }
            .column-2 {
                grid-column: 1/3;
            }
            .total {
                font-size: 1rem;
                font-family: $montBold;
                border: 0;
                margin-top: auto;
            }
            .btn {
                border-radius: 0;
                // margin-top: auto;
            }
        }
    }
    .coupon-container {
        .form-group {
            input {
                height: 100%;
            }
        }
        
        .btn {
            align-self: start;
            height: 38px;
        }
    }
    .payment-method {
        .collapse-header {
            display: grid;
            grid-template-columns: auto 1fr auto;
            border: solid 1px $lightGray;
            padding: 10px;
            grid-gap: 20px;
            font-size: 1.25rem;
            margin-bottom: 15px;
            cursor: pointer;
            transition: $transitionFast;
            img {
                width: 30px;
            }
            i {
                font-size: 1.875rem;
                &.fa-check-circle {
                    opacity: 0;
                    transition: $transition;
                    color: $green;
                }
            }
            > * {
                align-self: center;
            }
            &.active {
                // margin: 0;
                .fa-check-circle {
                    opacity: 1;
                }
            }
            &:hover {
                transform: scale(1.01);
                box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.2);
            }
        }
        .collapse-content {
            border: solid 1px $lightGray;
            margin-bottom: 15px;
            padding: 20px;
            .important {
                color: $orange;
            }
        }
    }

    &.my-cart {

        .container {
            grid-template-columns: 1fr;
        }
    }

    .zoid-outlet {
        width: 100% !important;
    }

    .location-card {
        border-radius: 10px;
        box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.1);
        border: 1px solid #eee;
        background: #fff;
        transition: 0.2s;

        &.inactive {
            background: #f5f5f5;
        }

        &:hover {
            transform: scale(1.02);
        }
    }

    @media (max-width: 1080px) {
        > .container {
            grid-gap: 30px;
        }
    }

    @media (max-width: 767px) {
        > .container {
            grid-template-columns: 1fr;

            .custom-breadcrumbs,
            .header {
                grid-column: unset;
            }

            .custom-breadcrumbs {
                grid-template-columns: auto auto auto;
                
                .text {
                    font-size: 16px;

                    &::after {
                        padding: 0 10px;
                    }
                }
            }
        }
    }

    @media (max-width: 360px) {
        > .container {

            .custom-breadcrumbs {
                
                .text {
                    font-size: 12px;
                }
            }
        }
    }
}