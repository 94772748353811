
@font-face {
	font-family: 'Lato-Bold';
	src: url('../fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato-Regular';
	src: url('../fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Merriweather-Italic';
	src: url('../fonts/Merriweather-Italic.otf') format('OpenType');
}

@font-face {
	font-family: 'librefranklin-bold';
	src: url('../fonts/librefranklin-bold.ttf') format('truetype');
}

@font-face {
	font-family: 'librefranklin-semibold';
	src: url('../fonts/librefranklin-semibold.ttf') format('truetype');
}

@font-face {
	font-family: 'librefranklin-extrabold';
	src: url('../fonts/librefranklin-extrabold.ttf') format('truetype');
}

@font-face {
	font-family: 'librefranklin-regular';
	src: url('../fonts/librefranklin-regular.ttf') format('truetype');
}

@font-face {
	font-family: 'librefranklin-light';
	src: url('../fonts/librefranklin-light.ttf') format('truetype');
}

@font-face {
	font-family: 'librefranklin-lightitalic';
	src: url('../fonts/librefranklin-lightitalic.ttf') format('truetype');
}

@font-face {
	font-family: 'librefranklin-semibolditalic';
	src: url('../fonts/librefranklin-semibolditalic.ttf') format('truetype');
}

@font-face {
	font-family: 'librefranklin-medium';
	src: url('../fonts/librefranklin-medium.ttf') format('truetype');
}

@font-face {
	font-family: 'BebasNeue-Bold';
	src: url('../fonts/BebasNeue-Bold.otf') format('OpenType');
}


.txt {
	// SIZE
	&-oversized {
		font-size: $oversized;
	}
	&-header {
		font-size: $h1;

		&-2 {
			font-size: $h2;
		}
	}
	&-large {
		font-size: $large;
	}
	&-xlarge {
		font-size: $xlarge;
	}
	&-xxlarge {
		font-size: $xxlarge;
	}
	&-medium {
		font-size: $medium;
	}
	&-small {
		font-size: $small;
	}


	// COLOR
	&-error {
		color: $red;
	}

	&-success {
		color: $green;
	}

	&-warning {
		color: $orange;
	}

	&-gray {
		color: $gray;

		&-med {
			color: $medGray;
		}

		&-light {
			color: $lightGray;
		}
	}

	&-white {
		color: #fff;
	}

	&-link {
		color: #356077;
	}

	&-dark-link {
		color: #284356;
	}

	// WEIGHT
	&-bold {
		font-weight: bold;

		&-black {
			font-weight: 800;
		}
	}

	&-regular {
		font-weight: normal;
	}

	//SETTINGS
	&-uppercase {
		text-transform: uppercase;
	}

	&-capitalize {
		text-transform: capitalize;
	}

	&-underlined {
		text-decoration: underline;
	}

	&-strike {
		text-decoration: line-through;

		&-element {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 2px;
				background-color: $medGray;
				margin: auto 0;
			}
		}
	}

	&-lineheight-1 {
		line-height: 1;
	}

	@media (max-width: 991px) {
		&-oversized {
			font-size: $h1;
		}
		&-header {
			font-size: $h2;
	
			&-2 {
				font-size: $xxlarge;
			}
		}
		&-large {
			font-size: $medium;
		}
		&-xlarge {
			font-size: $large;
		}
		&-xxlarge {
			font-size: $xlarge;
		}
		&-medium {
			font-size: $small;
		}
	}

	@media (max-width: 767px) {
		&-oversized {
			font-size: $h2;
		}
		&-header {
			font-size: $xxlarge;
	
			&-2 {
				font-size: $xlarge;
			}
		}

		&-large {
			font-size: $medium;
		}
		&-xlarge {
			font-size: $medium;
		}
		&-xxlarge {
			font-size: $large;
		}
	}
}

