.account {
    .account-page-control {
        color: #a3a4a4;
        background: #eee;
        border: none;
        box-shadow: none;
        font-size: 20px;
        padding: 20px;
        font-weight: bold;
        transition: $transitionFast;

        &.active {
            color: #fff;
            background: $green;
        }

        &:not(.active):hover {
            opacity: 0.6;
        }
    }

    .order-list {

        .order-card {
            border: 1px solid #ccc;
            border-radius: 5px;

            &.ph-col-12 {
                height: 150px;
            }

            &-header {
                padding: 20px;

                .order-collapse {
                    transition: $transitionFast;
                }
            }

            &-body {
                padding: 0 20px;
            }

            &-footer {
                padding: 20px;

                .total-container {
                    min-width: 150px;
                }
            }
        }
    }

    .item-card {
        padding: 10px;
        margin: 10px 0;
        border: 1px solid #ccc;
        border-radius: 5px;
        transition: $transitionFast;

        .item-img {
            width: 40px;
            height: 40px;
            border: 1px solid $lightGray;
            margin-right: 10px;

            img {
                width: 30px;
                height: 30px;
                object-fit: contain;
                object-position: center;
            }
        }

        .remove-btn {
            transition: $transitionFast;

            &:hover {
                transform: scale(1.2);
            }
        }

        &.deleting {
            pointer-events: none;
            transform: scale(0.95);
            opacity: 0.8;
        }

        &.ph-col-12 {
            height: 60px;
        }
    }

    .user-info {
        padding-bottom: 50px;
        
        .react-datepicker-wrapper {
            width: 100%;
        }

        .gender {
            border: none;
            width: 50px;
            height: 50px;
            box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
            max-width: 200px;
            overflow: hidden;
            transition: 0.5s;
            border-radius: 100%;

            >.text-center{
                border-radius: 100%;
                width: 50px;
                height: 50px;
                color: #fff;
                background-color: #ccc;
                transition: 0.5s;
            }

            &:hover,
            &.active {

                &[value=Male] {
                    >.text-center {
                        background-color: #1dbed5;
                    }
                }
        
                &[value=Female] {
                    >.text-center {
                        background-color: #f36fa9;
                    }
                }
        
                &[value=LGBTQA] {
                    >.text-center {
                        background-color: #F19F19;
                    }
                }
            }

            &.gender-hide:not(.active) {
                max-width: 0;
                opacity: 0;

                >.text-center {
                    transform: scale(0);
                }
            }
        }

        .password-form,
        .shipping-form {
            button {
                min-width: 200px;
            }
        }
        
        .profile-form {

            button {
                min-width: 200px;
            }

            .profile-img-container {
                padding: 20px;

                .profile-img {
                    width: 200px;
                    height: 200px;
                    position: relative;
                    margin: auto;

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        border-radius: 100%;
                        object-fit: cover;
                        object-position: center;
                        background-color: #ccc;
                    }

                    .img-placeholder {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        border: 5px solid $green;
                        color: $green;
                        border-radius: 100%;
                        font-size: 70px;
                    }
                }
            }
        }

        .wishlist-container {
            grid-gap: 30px;
        }
    }
}