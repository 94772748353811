.fullwidth-product-card {
    background-color: $medGray;
    padding: 25px;
    position: relative;

    .main-image {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: cover;
            object-position: center;
        }
    }

    .transition-overlay-image {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        max-width: 100%;
        overflow: hidden;

        img {
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
            object-position: center;
        }
    }
    
    .product-details {
        background-color: #fff;
        padding: 30px;
        margin-top: 200px;
        max-width: 60%;
        position: relative;
    }

    &.in-transition {
        .transition-overlay-image {
            opacity: 1;
            transition: max-width 0.6s ease-out;
            max-width: 0%;

            &.prev {
                left: initial;
                right: 0;

                img {
                    left: initial;
                    right: 0;
                }
            }
        }
    }

    @media (max-width: 991px) {

        .product-details {
            max-width: 100%;
        }
    }

    @media (max-width: 767px) {
        padding: 15px 5px;

        .product-details {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;
            background: none;
            margin-top: 100px;
            padding: 0;

            .details-container {
                background: #fff;
                padding: 10px;
            }

            .btn-container {
                text-align: center;
            }
        }
    }
}